.widget-accordion-select {
    $this: &;

    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    &__opener {
        padding: 0 25px;
        width: 100%;
    }

    &__button {
        display: flex;
        position: relative;
        width: 100%;
        padding: 32px 32px 20px 0;
        flex-direction: column;
        border-top: 1px solid $lightGray;
        color: $black;
        
        .label {
            font-size: rem(22px);
            line-height: rem(32px);
        }

        .selected {
            @include karbonMedium();

            font-size: rem(15px);
            line-height: rem(18px);
            color: $blue;
        }

        &::after {
            @include nexiIcon();

            content: $icon-caret_down;
            position: absolute;
            top: 40px;
            right: 3px;
            font-size: rem(18px);
        }

        &[aria-expanded=true] {
            
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &:first-child {

        #{$this}__button {
            border: none;
            padding-top: 26px;
        }
    }

    &__collapse {
        height: 0;
        width: 100%;
        overflow: hidden ;
        transition: height 0.2s ease-out;

        &.show {
            display: block;
        }
    }

    &__content {
        //padding: 56px 0 36px 0;
    }

    &__item {
        position: relative;
        //min-height: 64px;
        display: flex;
        align-items: center;

        input {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        label {
            display: block;
            width: 100%;
            height: 100%;
            padding: 17px 50px 17px 25px;
            font-size: rem(24px);
            line-height: rem(32px);
            color: $black;
        }

        &--selected {
            @include karbonMedium();

            background-color: $grayWhite;

            label {
                color: $blue;
            }

            &::after {
                @include nexiIcon();
                
                content: $icon-check;
                position: absolute;
                top: calc(50% - 9px);
                right: 40px;
                border: none;
                pointer-events: none;
                color: $blue;
                font-size: rem(14px);
            }
        }
    }
}