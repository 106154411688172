@keyframes bummer {
    100% {
        transform: scale(1, 1);
    }
}

// MODAL GENERIC
.widget-modal-generic {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 400ms ease-in-out;
    //display: none;

    &.hide {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        visibility: hidden;
        transition: all 400ms ease-in-out;
    }

    &.show {
        z-index: setZ(modal);
        //display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &__backdrop {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        background-color: #000;
        opacity: 0.6;
        animation: fadeOut 1s;
        //transition: opacity 1s ease-in;

        #{$edit_mode} & {
            display: none;
        }
    }

    &__dialog {
        height: 100%;
        position: relative;
        z-index: 10;

        @include respond-above(md) {
            margin: 40px auto;
            height: calc(100% - 80px);
            max-height: calc(100% - 80px);
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            width: 94%;
            max-width: 720px;
        }

        @include respond-above(lg) {
            width: 83.3333333333%;
            max-width: 1152px;

        }

    }

    &__container {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: rem(40px) 0;
        background-color: $white;
        position: relative;
        pointer-events: auto;


        @include respond-above(md) {
            border-radius: rem(12px);
            height: auto;
            max-height: 100%;
            justify-content: center;

        }

        @include respond-above(lg) {
            height: auto;
            max-height: 100%;
            flex: 1;
            border-radius: rem(20px);

            .widget-modal-generic__body.ps.ps--active-y {
                overflow: hidden;
            }
        }


        // Perfect Scrollbar style
        .ps__thumb-y {
            right: 6px;
            width: 4px;
            //background-color: $blue;
        }

        .ps__rail-y {
            right: 5px;
            margin-bottom: 16px;

            &:hover,
            &:focus,
            &.ps--clicking {

                .ps__thumb-y {
                    right: 4px;
                    width: 8px;
                }
            }
        }

        @include ie-only() {
            height: 100%;
        }
    }

    &__header {

        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        padding: 0 rem(24px) rem(24px);

        @include respond-above(lg) {
            padding: 0 rem(40px) rem(24px);
        }
    }

    &__close {
        color: $black;
        padding: rem(5px);

        .icon-close {
            font-size: rem(14px);
            display: block;
        }
    }

    &__body {
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        pointer-events: auto;
        padding: 0 rem(24px);

        @include respond-above(lg) {
            padding: 0 rem(40px);
        }
    }

}
