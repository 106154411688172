%caretUp {
    &::before {
        content: $icon-caret_up;
        transform: rotate(180deg);
    }
}

@mixin table-informative {
    table {
        border-collapse: separate;
        border-spacing: 0;
    }

    @include karbonRegular();
    font-size: rem(18px);
    line-height: rem(22px);
    text-align: left;

    @include respond-above(lg) {
        font-size: rem(20px);
        line-height: rem(24px);
    }

    @include respond-above(xl) {
        font-size: rem(24px);
        line-height: rem(32px);
    }

    thead tr th {
        background-clip: padding-box;
        font-weight: 500;
    }

    tbody .show {
        &:first-child td{
            border-top: 1px solid #D8D8D8;

            &:first-child {
                border-left: 1px solid #D8D8D8;
                border-top-left-radius: rem(4px);
            }

            &:last-child {
                border-right: 1px solid #D8D8D8;
                border-top-right-radius: rem(4px);
            }
        }

        &:not(:first-child):not(:last-child) td{
            &:first-child {
                border-left: 1px solid #D8D8D8;
            }
    
            &:last-child {
                border-right: 1px solid #D8D8D8;
            }
        }
    }



    tbody .show.last-show-element td{
        border-bottom: 1px solid #D8D8D8;

        &:first-child {
            border-left: 1px solid #D8D8D8;
            border-bottom-left-radius: rem(4px);
        }

        &:last-child {
            border-right: 1px solid #D8D8D8;
            border-bottom-right-radius: rem(4px);
        }
    }

    tbody tr:nth-child(odd) td {
        background: #F5F7F9;
        background-clip: padding-box;
     }
     
    tbody tr:nth-child(even) td {
        background: #FFFFFF;
        background-clip: padding-box;
    }
    
    th, td {
        padding: rem(16px) rem(24px);

        @include respond-above(lg) {
            padding: rem(24px);
        }
    }

    td:first-child, th:first-child {
        position: sticky;
        left: 0;
        filter: none;
        z-index: 1;
        background-clip: padding-box;

        @include respond-above(lg) {
            z-index: 0;
            position: relative;
            filter: none;
        }
    }

    th:first-child {
        &:before {
            content: '';
            display: none;
        }
    }

    a {
        @include karbonMedium();
        color: $blue;
        text-decoration: underline;
    }

    .icon-check {
        background: #48A18E;
        border-radius: 100%;
        font-size: rem(12px);
        color: white;
        padding: rem(4px);
    }

    .icon-line {
        background: #000000;
        width: rem(17px);
        height: rem(2px);
        display: block;
        margin: 0 auto;
    }

    .icon-info {
        display: flex;
        margin-left: rem(5px);
    }

    tbody td img{
        width: rem(40px);
        margin-right: rem(10px);
        display: inline-block;
    }
}

@mixin table-comparative {
    table {
        position: relative;
        background-clip: padding-box;    
        border-collapse: separate;
        border-spacing: 0;


        @include respond-above(lg) {
            table-layout: auto;
        }
    }

    thead {
        filter: none;
        vertical-align: bottom;

        h3 {
            @include karbonMedium();
            font-size: rem(18px);
            line-height: rem(24px);

            @include respond-above(lg) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }
    
        h5 {
            @include karbonRegular();
            font-size: rem(18px);
            line-height: rem(24px);
            display: none;
            opacity: 1;
            transition: opacity .5s;

            @include respond-above(lg) {
                display:block;
            }
        }

        &.scrolled {
            z-index: 3;
            position: sticky;
            top: 0;
            filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.1));
            
            img {
                max-height: 0;
            }

            h5 {
                opacity: 0;
            }
        }
    }

    thead tr th {
        position: relative;
        background-clip: padding-box;
        padding: 0 rem(5px) rem(15px);
        width: rem(105px);
        vertical-align: top;
        font-weight: 500;
        transition: all .5s;

        @include respond-above(md) {
            width: rem(220px);
        }

        @include respond-above(xl) {
            width: rem(250px);
        }

        &:first-child {
            display: none;

            @include respond-above(lg) {
                display:table-cell;
            }
        }

        img {
            max-height: rem(80px);
            margin: 0 auto;
            transition: max-height .5s;
        }
    }

    tbody {
        @include karbonRegular();
        font-size: rem(18px);
        line-height: rem(24px);
    }

    .icon-check {
        background: #48A18E;
        border-radius: 100%;
        font-size: rem(12px);
        color: white;
        padding: rem(4px);
    }

    .icon-line {
        background: #000000;
        width: rem(17px);
        height: rem(2px);
        display: block;
        margin: 0 auto;
    }
}

.widget-table {
    $table: &;

    color: #000000;
    margin: rem(30px) 0 rem(50px);
    min-height: rem(600px);
    transition: opacity 2s;

    &__selects {
        display: none;

        @include respond-above(lg) {
            display: flex;
        }
    }

    & .elem-custom-select-filter-table {
        margin-right: (32px);
    }
    
    &__container {
        @include container();
        @include content-container();

        opacity: 1;

        &--hidden {
            opacity: 0;
        }
    }

    &__content {
        @include respond-above(lg) {
            margin-top: rem(32px);
        }
    }

    table {
        width: 100%;
    }
    
    thead {
        @include karbonMedium();
        color: $black;

        .icon-info::before {
            color: $blue;
            font-size: rem(18px);
        }
    }    

    &--informative {
        @include table-informative();
        
        #{$table}__selects {
            margin-top: rem(16px);
        }
        
        #{$table}__table-container {
            padding-top: rem(60px);
            overflow-x: scroll;
            -webkit-transition: all .5s ease-in-out 0s;
            transition: all .5s ease-in-out 0s;

            @include respond-above(lg) {
                padding-top: 0;
                overflow: visible;
            }

            &.scrolled {
                td:first-child, th:first-child {
                    filter: drop-shadow(10px 5px 10px rgba(0, 0, 0, 0.1));
                }
            }
        }

        #{$table}__ghost-row {
            & td:only-child {
                filter: none;
            }

            
            &-full-desktop {
                border-left: 0;
                border-top-left-radius: 0;

                @include respond-above(lg) {
                    border-left: 1px solid #D8D8D8;
                    border-top-left-radius: rem(4px);
                }
            }

            & .cell-ghost-mobile {
                display: table-cell;

                @include respond-above(lg) {
                    display: none;
                }
            }
        }



        #{$table}__showMoreContainer {
            display: flex;
            justify-content: center;
            padding: rem(19.5px) rem(15px);
        }

        #{$table}__showMore {
            @include karbonMedium();
            color: $blue;
            font-size: rem(16px);
            line-height: rem(17px);
            cursor: pointer;
        }

        #{$table}__tooltipContainer {
            position: relative;
            display: inline-flex;
            align-items: center;
        }

        #{$table}__tooltipContainer #{$table}__tooltipText {
            @include karbonRegular();
            font-size: rem(14px);
            line-height: rem(18px);
            position: absolute;
            width: rem(165px);
            height: auto;
            background-color: #F0F0F0;
            color: #000000;
            padding: rem(10px);
            bottom: rem(40px);
            right: rem(-74.5px);
            z-index: 1;
            border-radius: rem(8px);
            word-wrap: break-word;
            opacity: 0;
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
            transform: scale(.6) translateY(50%);

            &::before {
                position: absolute;
                opacity: 0;
                border-style: solid;
                border-width: rem(16px) rem(14px) 0 rem(14px);
                border-color: #F0F0F0 transparent transparent transparent;
                top: 100%;
                left: 42%;
                content: "";
                transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
                transform: scale(.6) translateY(0); 
            }
        }

        #{$table}__tooltipContainer:hover #{$table}__tooltipText {
            transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
            opacity: 1;
            transform: scale(1) translateY(0);
            
            &::before {
                opacity: 1;
                transform: scale(1) translateY(0);
                z-index: 100;
                transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
            }
        }     
    }

    &--comparative {
        @include table-comparative();

        #{$table}__filters {
            display: none;
        }
        
        #{$table}__button-heading-container {
            bottom: rem(15px);
            position: absolute;
            left: 0;
            right: 0;

            margin-top: rem(8px);
        }
        

        #{$table}__table-container {
            height: rem(624px);
            overflow: scroll;

            @include respond-above(xl) {
                overflow-x: hidden;
                height: rem(1000px);
            }
        }

        #{$table}__row-controls {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        #{$table}__buyCta.btn-primary {
            width: auto;
            font-size: rem(14px);
            line-height: rem(16px);
        }

        #{$table}__showMoreContainer {
            display: none;
        }

        #{$table}__collapse > td {
            background: #FFFFFF;
            border: 1px solid #D8D8D8;
        }
        
        #{$table}__collapse:last-child > td {
            border-bottom-left-radius: rem(4px);
            border-bottom-right-radius: rem(4px);
            border-top: 0;
        }

        #{$table}__collapse:first-child > td {
            border-top-left-radius: rem(4px);
            border-top-right-radius: rem(4px);
        }

        #{$table}__collapse:not(:first-child):not(:last-child) > td {
            border-top: 0;
        }

        #{$table}__collapse #{$table}__inner-table-wrapper {
            max-height: 0px;
            overflow: hidden;
            transition: max-height .5s;
        }

        #{$table}__collapse.active {
            #{$table}__icon {
                @extend %caretUp;
            }
        }
          
        #{$table}__inner-table {
            width: 100%;
            border-top: 1px solid #D8D8D8;

            & tr:nth-child(odd) td {
                background: #F5F7F9;
            }
             
            & tr:nth-child(even) td {
                background: #FFFFFF;
            }

            &.scrolled td:first-child {
                filter: drop-shadow(4px 17px 10px rgba(0, 0, 0, 0.1));
            }
        }

        #{$table}__inner-table td {
            @include karbonRegular();
            font-size: rem(16px);
            line-height: rem(24px);
            padding: rem(40px) rem(16px) rem(8px) rem(16px);
            position: relative;
            background-clip: padding-box;
    
            @include respond-above(lg) {
                font-size: rem(18px);
                line-height: rem(24px);
                padding: rem(16px) rem(24px);
            }

            &:not(:first-child) {
                text-align: center;
                width: rem(105px);

                @include respond-above(md) {
                    width: rem(220px);
                }

                @include respond-above(xl) {
                    width: rem(250px);
                }
            }

            &:first-child {
                display: none;
    
                @include respond-above(lg) {
                    display:table-cell;
                    width: rem(220px);
                }

                @include respond-above(xl) {
                    width: rem(250px);
                }
            }
        }

        #{$table}__collapse-title {
            @include karbonMedium();
            font-size: rem(18px);
            line-height: rem(24px);
            padding: rem(8px) rem(16px);
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;

            &-section-description {
                display: inline-flex;
                gap: rem(4px);

                img {
                    margin-right: rem(4px);
                }
            }
    
            @include respond-above(lg) {
                font-size: rem(24px);
                line-height: rem(32px);
                padding: rem(16px) rem(24px);
            }

            #{$table}__icon {
                cursor: pointer;
                font-size: rem(32px);
            }
        }

        #{$table}__absolute-mobile-category {
            white-space: nowrap;
            z-index: 2;
            position: absolute;
            top: rem(10px);
            left: rem(16px);

            @include respond-above(lg) {
                display: none;
            }
        }

        &#{$table}__more-items {

            #{$table}__table-container {
                overflow-x: clip;
            }

            table {
                table-layout: fixed;
            }

            thead {
                display: inline-block;
                width: 100%;

                &.sync-scroll-container {
                    position: relative;
                    background-clip: padding-box;
                    padding-top: 0;
                    transition: padding-top 0.5s;
        
                    @include respond-above(xl) {
                        padding-bottom: rem(100px);
                    }
        
                    tr {
                        display: inline-block;
                    }

                    &.scrolled {
                        position: sticky;
                    }
                }

                & > tr {
                    overflow-x: scroll;
                    transition: all .5s;

                    @include respond-above(xl) {
                        overflow-x: hidden;
                    }
                }

                & th {
                    min-width: rem(105px);
            
                    @include respond-above(md) {
                        min-width: rem(220px);
                    }
            
                    @include respond-above(xl) {
                        min-width: rem(250px);
                    }
                }
            }

            #{$table}__inner-table {
                overflow-x: scroll;
                display: block;

                @include respond-above(xl) {
                    overflow-x: hidden;
                }

                & td {
                        &:not(:first-child) {
                        text-align: center;
                        min-width: rem(105px);
        
                        @include respond-above(md) {
                            min-width: rem(220px);
                        }
        
                        @include respond-above(xl) {
                            min-width: rem(250px);
                        }
                    }
        
                    &:first-child {
                        display: none;
            
                        @include respond-above(lg) {
                            display:table-cell;
                            min-width: rem(220px);
                        }
        
                        @include respond-above(xl) {
                            min-width: rem(250px);
                        }
                    }
                }
            }

            th:first-child, #{$table}__inner-table td:first-child {
                position: sticky;
                left: 0;
                z-index: 1;
            }
        }
    }

    &__arrowContainer {
        display: none;
        gap: rem(20px);
        padding: rem(20px) rem(4px) rem(15px);

        @include respond-above(xl) {
            display: inline-flex;
        }
    }

    &__arrow {
        text-align: center;
        background-color: #FFFFFF;
        border-radius: 50%;
        width: rem(56px);
        height: rem(56px);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue;
        font-size: rem(23px);
        transition: transform 1.5s;
        box-shadow: 0px 4px 8px 2px #00000014;
    }

    &__arrowDisabled {
        cursor: not-allowed;
        opacity: 0.1;
        pointer-events: none;

    }

    &__ghost-row {
        td {
            position: relative;

            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                filter: opacity(0.7);
                background: white;
            }
        }
    }

    .text-center {
        text-align: center;
    }

    .hide {
        display: none;
    }

    &__searchBar {
        position: relative;
        display: none;

        #{$table}__search::-webkit-search-decoration,
        #{$table}__search::-webkit-search-cancel-button,
        #{$table}__search::-webkit-search-results-button,
        #{$table}__search::-webkit-search-results-decoration {
            display: none;
        }

        @include respond-above(lg) {
            display: block;
        }

        #{$table}__search {
            @include karbonRegular();
            background: $white;
            padding: rem(20px) rem(12px);
            width: 100%;
            display: block;
            border: 1px solid $gray;
            border-radius: $card-border-radius-small;
            appearance: none;
            
            @include t-paragraph-2();
    
            &::placeholder {
                color: $darkGray;
            }
            &::-ms-input-placeholder {
                color: $darkGray;
            }
    
            &:focus {
                outline: none;
                border-color: $black;
            }
    
            @include respond-above(lg) {
                display: block;
                padding: rem(16px) rem(20px);
                font-size: rem(18px);
                line-height: rem(24px);
            }
    
            .panel-blue & {
                color: $black;
            }
        }
    
        #{$table}__searchIcon {
            position: absolute;
            font-size: rem(24px);
            right: 12px;
            top: 20px;
            pointer-events: none;
            color: $lighterGray !important;
    
            @include respond-above(lg) {
                display: block;
                right: 20px;
                top: 25%;
                font-size: rem(24px);
            }
    
            .elem-searchbar-full-width.searchbar__active & {
                cursor: pointer;
                color: $blue !important;
                pointer-events: auto;
            }
        }
    }

    b {
        @include karbonMedium();
        // font-weight: 500;
    }

    & *::-webkit-scrollbar {
        display: none;
    }

    & ul { 
        list-style-type: disc; 
        padding: inherit;
     }
    & ol { 
        list-style-type: decimal;
        padding: inherit;
    }
}

.loader-table {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: $blue;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    opacity: 1;
    filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.4));
    transition: opacity 1s ease;
    position: absolute;
    z-index: 99;
    top: 30%;
    left: 50%;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &--hidden {
        opacity: 0;
    }
}

