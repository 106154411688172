.widget-login {
    $this: &;
    position: relative;
    overflow: hidden;
    padding-top: rem(72px);
    margin-top: rem(-72px);

    &__container {
        min-height: calc(100vh - 102px - 72px);

        .no-footer & {
            min-height: calc(100vh - 105px - 72px); //margin-top - height header 
        }
    }

    &__background-image {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
       
        img {
            object-fit: cover;
        }

        .login-companies & {
            display: block;
            height: 299px;

            .media-picture, img {
                height: inherit;
            }
        }

        @include respond-above(md) {
            display: block;

            .login-companies & {
                height: 100%;
            }
        }

        @include respond-above(md) {
            .media-picture {
                @include imageCover;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
		height: 100%;
		background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 64.86%, rgba(0, 0, 0, 0.6) 100%);
    }

    &__wrapper {
        
        width: 100%;
        // @include respond-above(lg) {
        //     @include percentage-container();
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     margin-top: rem(30px);
        //     margin-bottom: rem(35px);
        // }
        @include respond-above(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 rem(25px);
            margin-top: rem(30px);
            margin-bottom: rem(35px);

            .no-footer & {
                margin-top: rem(105px);
            }
        }

        @include respond-above(lg) {
            max-width: 1440px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }

    &__left, &__right {
        @include respond-above(md) {
            width: 50%;
        }
    }

    &__left {
        display: none;

        .login-companies & {
            height: 224px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem(15px);
        }

        @include respond-above(md) {
            display: flex;

            .login-companies & {
                align-items: flex-start;
                height: 100%;
                padding: 0;
            }
        }
    }

    &__right {
        @include respond-above(md) {
            max-width: 470px;
            display: flex;
            flex-direction: column;
            align-items:flex-end;
        }
    }

    &__row {
        @include make-row();

        @include respond-above(md) {
            width: 100%;
        }
        
        @include ie-only(){
            width: 100%;
        }
    }

    &__left-col, &__right-col {
        @include make-col-ready();
    }

    &__left-col {
        @include make-col(12);

        @include respond-above(md) {
            @include make-col(10);
        }

        @include respond-above(lg) {
            @include make-col(9);
        }
    }

    &__right-col {
        @include make-col(12);
    }

    &__title {
        text-align: center;
        padding-bottom: rem(10px);
        padding-top: rem(20px);

        @include respond-above(md) {
            padding-top: 0;
            padding-bottom: rem(24px);
            text-align: left;
        }

        + #{$this}__image-store {
            margin-bottom: rem(72px);

            a {
                margin-top: 0;
            }
        }
    }

    &__subtitle {
        padding-bottom: rem(28px);

        @include respond-below(md) {
            display: none;
        }
    }

    &__roles {
        @include make-row();

        @include respond-below(md) {
            display: none;
        }
    }

    &__icon-container {
        @include make-col-ready();
        @include make-col(2);
        padding-top: rem(5px);
    }

    &__role-description {
        @include make-col-ready();
        @include make-col(10);
    }

    &__first-paragraph {
        text-align: center;

        @include respond-above(md) {
            text-align: left;
        }
    }

    &__second-paragraph {
        display: none;
        @include respond-above(md) {
            display: block;
        }
    }

    &__image-store {
        display: none;
        
        @include respond-above(md) {
            display: flex;
            flex-wrap: wrap;

            a {
                display: inline-block;
                margin-top: rem(18px);
                margin-right: rem(18px);

                &:last-child {
                    margin-right: 0;
                }
            }

            img {
                max-height: 40px;
            }
        }
    }

    &__first-box, 
    &__second-box {
        @include respond-above(md) {
            border-radius: 12px;
            box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
        }
    }

    &__first-box {
        padding:  rem(20px) rem(30px);
        text-align: center;
        background-color: $loginDarkBlue;

        @include respond-above(md) {
            text-align: left;
            padding: rem(20px);
            margin-bottom: rem(10px);
        }
    }

    &__first-box-cta,
    &__second-box-cta {
        .btn-text-link {
            @include karbonSemibold();
            
            display: inline-block;

            @include mouse-only {
                &:hover {
                    color: $azure;
                }
            }
        }
    }

    &__first-box-cta {
        text-align: center;

        @include respond-above(md) {
            text-align: left;
        }

        .btn-text-link {
            letter-spacing: 0;

            @include respond-above(md) {
                font-size: rem(20px);
                line-height: rem(20px);
            }
        }
    }

    &__second-box {
        padding: rem(15px);
        background-color: $white;
        color: $black;

        @include respond-above(md) {
            min-height: 690px;
            padding: rem(30px) rem(20px);

            &.adaptive-height {
                min-height: 0;
            }
        }
        
        @include respond-above(lg) {
            margin-bottom: 0;
            padding: rem(40px);
        }
    }

    &__second-box-title {
        padding-bottom: rem(15px);

        @include respond-above(lg) {
            margin-bottom: rem(15px);
        }
    }

    &__second-box-cta {
        .btn-text-link {
            letter-spacing: 2px;
            color: $blue;
        }
    }
}