.widget-tile {
    $this-tile: &;

    .base-main__item > & {
        @include container();
        @include content-container();
    }

    .layout-panel &:has(.shadow-img) {
       z-index: 1;
       position: relative;
    }

    &__container {
        position: relative;
        overflow: hidden;

       &.round-border {
               border-radius: 8px;
           }
       &.shadow-img {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
       }
    }

    &__picture {
        @include imageCover;

        position:absolute;
        z-index: 0;

        @include respond-below(md) {
            img {
                width: 100%;
            }
        }

        @include respond-above(lg) {
            img {
                max-width: 1140px;
            }
        }

    }

    &__container.shadow-img > &__picture {
        z-index: -1;
    }

    &__content {
        @include make-col(12);
        padding: 24px;
        position: relative;
        min-height: 327px;
        display: flex;
        flex-direction: column;
        color: $white;
        z-index: 2;

        @include respond-above(lg) {
            @include make-col(7);
            padding: 108px 0 108px 96px;
            min-height: 456px;
            justify-content: center;
        }
    }

    &__title {
        @include t-h3-title();
        margin-bottom: 16px;
        
        @include respond-above(lg) {
            margin-bottom: 25px;
        }

        @include mouse-only() {
            a:hover {
                text-decoration: underline;
            }
        }
    }

    &__text {
       @include t-paragraph-1();
    }

    &__cta {
        margin-top: 87px;
        display: flex;
        justify-content: center;
       
        
        @include respond-above(lg) {
            margin-top: 30px;
            justify-content: flex-start;
        }

        @include respond-below(lg) {
            a {
                max-width: 270px;
            }
        }
    }

    & + #{$this-tile} {
        margin-top: 24px;

        @include respond-above(lg) {
            margin-top: 48px;
        }
    }
}