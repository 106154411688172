
.elem-iframe {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;

    &--rounded {
        display: block;
        border-radius: 8px;
        transform: translateZ(0);
        overflow: hidden;
    }

    &.margin-top {
        margin-top: 24px;
    }
}