.elem-quick-links {
    padding: 40px 0 72px 0;

    @include respond-above(lg) {
        padding: 64px 0 56px 0;
    }

    &__container {
        @include respond-above(lg) {
            @include container();
            @include content-container();
        }
    }

    &__title {
        @include karbonRegular();
        
        font-size: rem(32px);
        line-height: rem(40px);
        letter-spacing: -1px;
        margin-bottom: rem(32px);
        padding: 0 rem(25px);

        @include respond-above(lg) {
            font-size: rem(40px);
            line-height: rem(48px);
            letter-spacing: -1.5px;
            padding: 0;
        }
    }

    &__items {
        padding-right: 19%;
        margin-left: rem(25px);

        @include respond-above(lg) {
            padding: 0;
            margin-left: rem(-16px);
            margin-right: rem(-16px);
        }
    }

    &__item {
        &:before {
            content: '';
            border-top: 1px solid $white;
            width: 100%;
            display: block;
            margin-bottom: rem(8px);

            .panel-grey & { 
                border-color: $black;
            }

            .panel-white & {
                border-color: $lighterGray;
            }
        }

        @include respond-above(lg) {
            @include make-col(4);
            padding-left: rem(16px);
            padding-right: rem(16px);
            padding-bottom: rem(16px);

            &:before {
                margin-bottom: rem(16px);
            }
        }
    }

    &__item-title {
        @include  t-h5-title();
        letter-spacing: -0.5px;

        a {
            display: block;
            padding-right: 26px;
            position:relative;
            transition: all .3s ease;

            span {
                position:relative;

                &:after {
                    content: "";
                    position: absolute;
                    width: 0;
                    right: 0;
                    bottom: 0;
                    height: 2px;
                    display: block;
                    background: $black;
                    transition: width .3s ease;
                }
                
                .panel-blue &:after {
                    background: $white;
                }

            }

            @include mouse-only() {

                &:hover {
                    // font-family: KarbonMedium;
                    text-decoration: none;

                    .icon-arrow_right {
                        transform: translateX(8px);
                    }

                    span:after {
                        width: 100%;
                        left: 0;
                    }
                }
            }

            &:hover {
                @include karbonMedium();
            }
        }
        
        .icon-arrow_right {
            position:absolute;
            right: 4px;
            bottom: 5px;
            transition: transform .3s cubic-bezier(.09,.72,.76,1.01);
        }

        @include respond-above(lg) {
            font-size: rem(32px);
            line-height: rem(40px);
            letter-spacing: -1px;

            .icon-arrow_right {
                right: 12px;
                bottom: 10px;
            }
        }
    }

    &__item-text {
        font-size: rem(12px);
        line-height: rem(22px);
        margin-top: rem(4px);

        @include respond-above(lg) {
            font-size: rem(14px);
            line-height: rem(24px);
            margin-top: rem(8px);
        }
    }
}