.widget-modal-comparison {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 400ms ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        visibility: hidden;
        transition: all 400ms ease-in-out;
    }

    &.show {
        z-index: setZ(modal);
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &__dialog {
        height: 100%;
        position: relative;
        z-index: 10;
    }

    &__container {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding-bottom: rem(20px);
        background-color: $white;
        position: relative;
        pointer-events: auto;

        @include ie-only() {
            height: 100%;
        }
    }

    &__header {
        padding: rem(87px);
        padding-bottom: rem(63px);
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        pointer-events: auto;
    }
    
    &__close {
        color: $black;

        .icon-close {
            font-size: rem(19px);
            display: block;
        }
    }

    &__body {
        position: relative;
        pointer-events: auto;
        overflow-y: auto;
    }

    &__title {
        color: $black;
        margin-bottom: rem(88px);
        text-align: center;
    }

    &__table {
        @include container();
        @include content-container();
        display: block;
        position: relative;
    }
    
    &__tableWrapper {
        margin-bottom: rem(189px);
    }

    &__columnTitle, &__columnSubtitle, &__footer {
        display: flex;
    }

    &__columnTitle &__item, &__columnSubtitle &__item, &__footer &__item {
        span {
            display: inline-block;
        }
    }

    &__columnTitle {
        padding-bottom: rem(24px);
    }

    &__columnSubtitle {
        padding-bottom: rem(24px);

        .elem-text span {
            margin-bottom: 0;
        }

        .widget-modal-comparison__item:first-child {
            justify-content: flex-start;
        }
    }

    &__section ~ &__section {
        padding-top: rem(80px);
    }
    
    &__footer {
        padding-top: rem(58px);
    }

    &__list,
    &__sectionTitle {
        display: flex;
        justify-content: space-between;
        -webkit-box-pack: justify;
    }

    &__list {   
        &:nth-of-type(even) {
            background-color: $lightColdGray;
            border-radius: rem(6px);
            padding-top: rem(14px);
            padding-bottom: rem(14px);
        }

        &:nth-of-type(odd) {
            background-color: $white;
            padding-top: rem(22px);
            padding-bottom: rem(22px);
        }
    }

    &__item {
        @include t-paragraph-1();
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: -0.5px;
        flex-basis: calc(100% / 3);
        flex-shrink: 0;
        text-align: center;

        b {
            @include karbonMedium();
            // font-weight: 400;
        }

        &:first-child {
            -webkit-box-flex: 1;
            flex-grow: 1;
            text-align: left;
            padding-left: rem(24px);
        }
    }

    &__columnTitle &__item, &__columnSubtitle &__item, &__footer &__item {
        display: block;
    }

    &__sectionTitle {
        border-bottom: 1px solid $gray;
    }

    &__sectionTitle &__item {
        @include karbonMedium();
        @include t-text-link();
        text-transform: uppercase;
        display: block;
        padding-bottom: rem(20px);
    }

    &__list &__item {
        @include t-paragraph-1();

        &:first-child {
            @include t-paragraph-2();
            display: block;
            align-self: center;
        }
    }

    &__check-container {
        display: flex;
        justify-content: center;        
        align-items: center;       
    }

    &__check-container.check {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: $blue;
    }

    &__check.icon-check {
        font-size: rem(11px);
        color: $white;
        padding-top: rem(1px);
    }

    &__check.icon-minus {
        font-size: rem(15px);
    }

    &__check.icon-minus:before {
        color: $darkGray;
    }
    
}
