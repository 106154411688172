.widget-products-slider {
    width: 100%;
    overflow: hidden;
    padding: rem(32px) 0;

    @include respond-above(lg) {
        padding: rem(128px) 0;
    }

    &__title {
        @include container();
        @include content-container();

        padding: 0 rem(25px);
        margin-bottom: rem(10px);

        @include respond-above(lg) {
            padding: 0;
        }
    }

    &__container {
        @include container();
        @include content-container();

        @include respond-below(lg) {
            padding-right: rem(65px) !important;
        }

        @include respond-above(lg) {
            overflow: visible;
        }

        &.align-v-slider-mob {
            @include respond-below(md) {
                padding-right: rem(25px) !important;
            }
        }
    }

    .align-v-slider-mob .swiper-wrapper {
         @include respond-below(md) {
            flex-direction: column;
         }
    }

     .align-v-slider-mob &__slide {
         @include respond-below(md) {
            padding-bottom: rem(24px);
         }
     }

    &__slide {
        margin-right: rem(12px);
        height: auto;
    }

    &__slide-container {
        height: 100%;
        border: $card-border;
        border-radius: 13.91px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        position: relative;

        @include respond-above(lg) {
            max-width: 371px;
        }

    }

    &__slide-badge {
        display: flex;
        padding: rem(8px) rem(12px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: rem(28px);

        border-radius: rem(20px) 0 0 rem(20px);
        border-top: 1px solid var(--white, #FFF);
        border-bottom: 1px solid var(--white, #FFF);
        border-left: 1px solid var(--white, #FFF);

        width: fit-content;
        position: relative;
        margin-left: auto;
        right: 0;

         @include respond-above(lg) {
            height: rem(34px);
        }

        &-text {
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.24px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 500;
            color: $white;

            @include respond-above(lg) {
                font-size: 18px;
                line-height: 18px;
                font-weight: 600;
                letter-spacing: 0.36px;
            }
        }
    }

    &__slide-content {
        padding: rem(24px);
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        height: 100%;

        @include respond-above(lg) {
            padding-bottom: rem(33px);
        }
    }

    &__slide-title-text-price {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    
    &__slide-title-text {
        min-height: 139px;
        @include respond-above(lg) {
            min-height: 248px;
        }
    }

    &__slide-title {
        min-height: 67px;

        p {
            margin-bottom: rem(3px) !important;

            @include respond-above(lg) {
                margin-bottom: rem(24px) !important;
            }
        }
        span {
            display: inline-block !important;
            margin-bottom: 0 !important;
        }

        @include respond-above(lg) {
            min-height: 120px;
        }
    }

    &__slide-price {
        p {
            margin-bottom: rem(24px) !important;
        }
        span {
            display: inline-block !important;
            margin-bottom: 0 !important;
        }
    }

    &__slide-first-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //height: 100%;
        //min-height: 230px;

        @include respond-above(lg) {
            //min-height: 321px;
        }
    }

    &__slide-second-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @include sf-only {
            flex: 1;
        }
    }

    &__slide-features {
        border-top: 1px solid #979797;
        margin-top: rem(24px);
        padding-top: rem(24px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

         &.hide-horizontal-line {
            border-top: 0;
            margin: 0;
            padding-top: rem(24px);
         }

        @include respond-above(lg) {
            margin-top: rem(32px);
            padding-top: rem(32px);
        }

        ul {
            margin: 0 !important;
        }

        li {
            display: flex;
        }

        li::before {
            margin-top: 9px;
            content: "•";
            background-color: $blue;
            border-radius: 50%;
            margin-right: rem(18px);
            font-size: 60px;
            height: 9px;
            width: 9px;
            color: transparent;

            @include respond-above(lg) {
                margin-right: rem(29px);
                height: 12px;
                width: 12px;
            }
        }


        .text-white & li::before {
            background-color: $white;
        }
        .text-black & li::before {
            background-color: $black;
        }

    }

    &__slide-cta-container {
        a {
            width: 100%;

            &.elem-text--link-arrow {
                width: auto;
            }
        }

    }

    &__slide-cta-secondary {
        border-top: 1px solid #979797;
        padding-top: rem(24px);

        @include respond-above(lg) {
            padding-top: rem(32px);
        }
        &.hide-horizontal-line {
            border-top: 0px;
        }
    }
   
    //SWIPER CLASSES    
    .swiper-slide {
        width: auto;
    }

    .swiper-navigation {
        @include respond-below(md) {
            display: none;
        }
    }

    .swiper-buttons {
        display: flex;
        justify-content: flex-end;
    }


    .swiper-button-next,
    .swiper-button-prev {
        @extend %swiper-buttons;
        box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        margin-top: 0;
        margin-bottom: rem(39px);
        width: 56px;
        height: 56px;

        [class*="icon"] {
            font-size: rem(22px);

            &:before {
                width: 56px;
                height: 56px;
            }
        }

        @include mouse-only() {
           

            &:hover {
                background-color: $white;

                [class*="icon"] {
                    color: $blue;
                }
            }
        }
    }

    .swiper-button-prev {
        margin-right: rem(18px);
    }

    .swiper-slide:not(.swiper-slide-visible) {
        opacity: 0.5;
    }

}

.lp-dob {
    .swiper-buttons {
        justify-content: center;
    }

    .widget-products-slider__slide-features {
        li::before {
            height: rem(6px);
            width: rem(6px);

            @include respond-above(lg) {
                margin-top: rem(12px);
            }
        }
    }
}