.elem-login-card {

    $this-card: &;
    color: $white;
    border-radius: 6px;
    position: relative;
    height: 100%;

    &.login-card-private {
        background-color: $loginPrivate;
    }

    &.login-card-business {
        background-color: $loginBusiness;
    }

    &.login-card-companies {
        background-color: $loginCompanies;
    }

    .layout-prelogin__content & {
        max-height: 400px;
        // display: flex;

        &__link {
            padding: rem(20px);

            @include respond-above(md) {
                padding: rem(26px);
            }

            @include respond-above(lg) {
                transition: all 0.3s ease-out;
                padding: 40px 30px 0;
            }
        }

        @include respond-above(lg) {
            &:after {
                content: '';
                display: block;
                padding-bottom: 100%;
                pointer-events: none;
            }

            &:before {
                content: "";
                position: absolute;
                border-radius: 6px;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transform-origin: 50% 50%;
                transition: all 0.3s ease-out;
                z-index: 0;
                background-color: inherit;
                box-shadow: 0 10px 13px 0 rgba(0,0,0,0.1);
            }

            &:hover {
                &:before {
                    height: 100%;
                    max-height: 446px;
                    -ms-transform: scale(1.08,1.08);
                    -webkit-transform: scale(1.08,1.08);
                    transform: scale(1.08,1.08);
                }

                #{$this-card}__link {
                    padding: 5px 30px 10px;
                }
            }
        }

        @include ie-only() {
            display: block;
            max-height: 400px;
        }
    }

    &__content {
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            text-align: center;
        }

        .layout-prelogin__content & {

            @include respond-above(lg) {
                height: inherit;
                justify-content: space-around;
            }
        }

        .layout-prelogin__footer-content & {
            background-color: var(--shadowWhite);
            box-shadow: 0 10px 13px 0 rgba(0,0,0,0.1);
            padding: rem(20px);
            min-height: $preloginFooterCardHeightMobile;
            justify-content: center;
            text-align: left;

            @include respond-above(md) {
                padding: rem(11px) rem(20px) rem(12px) rem(20px);
                min-height: $preloginFooterCardHeighDesktop;
                height: inherit;
            }
        }
    }

    &__link {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            position: relative;
        }
    }

    &__arrow {
        width: 29px;
        position: absolute;
        top: 15px;
        right: 24px;
       
        em {
            font-size: rem(20px);
        }

        @include respond-above(lg) {
            display: none;
        }
    }

    &__pretitle {
        @include karbonMedium();
        
        transition: margin 0.3s ease-out;
        // font-size: rem(17px);
        // line-height: rem(20px);
        // margin-bottom: rem(17px);
        padding-right: rem(34px);

        p, span,
        [class^="elem-title__"] {
            margin-bottom: 0;
        }

        @include respond-above(lg) {
            // font-family: KarbonRegular;
            @include karbonRegular();
            padding-right: 0;
            // font-size: rem(28px);
            // line-height: 1;
        }
    }

    &__title {
        // @include karbonSemibold();
        // font-size: rem(20px);
        // line-height: rem(22px);
        margin-bottom: rem(5px);

        @include respond-above(md) {
            margin-bottom: rem(10px);
        }

        @include respond-above(lg) {
            margin-bottom: rem(15px);

            // font-size: rem(30px);
            // line-height: 1;
        }

        .layout-prelogin__footer & {
            font-size: rem(18px);
            line-height: rem(19px);
        //     margin-bottom: rem(15px);
        }
    }

    &__text {
        p, span {
            margin-bottom: 0;
        }

        .layout-prelogin__content & {
            @include respond-above(lg) {
                margin-bottom: rem(24px);
            }
        }
        
    }

    &__cta {
        display: none;
        opacity: 0;
        height: 0;
        margin: 0 auto;
        pointer-events: none;
        cursor: default;
        transition: all 0.3s ease-out;

        @include respond-above(lg) {
            display: block;
        }

        #{$this-card}:hover & {
            opacity: 1;
            height: 42px;
        }

        > div {
            width: 180px;
        }

    }

    #{$wcmmode} & {
        > div {
            height: inherit;
        }
    }
}