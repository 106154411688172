// MODAL
.media-video {

    &__container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $black;
        
        .transparent & {
            background: none;
        }

        .white & {
            background: $white;
        }

        .is-mobile-hidden {
            @include respond-below(lg) {
                display: none;
            }
        }
    }

    &__play-button {
        position: absolute;
        opacity: 1;
        display: none;
        transition: opacity .3s ease-out, z-index 0s ease-out .3s;
        z-index: 10;

        img {
            width: 48px;
            height: 48px;
            
            @include respond-above(lg) {
                width: 80px;
                height: 80px;
            }
        }

        .video-playing & {
            opacity: 0;
            pointer-events: none;
            z-index: -1;
        }

        &.visible {
            display: block;
        }

        @include ie-only() {
            left: calc(50% - 40px);
            top: calc(50% - 40px);
        }
    }

    &__placeholder-image {
        width: 100%;
        height: auto;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &[data-src=''],
        &:not([data-src]) {
            pointer-events: none;
        }
    }

    @include ie-only() {
        flex-shrink: 0;
    }
}