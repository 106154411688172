.widget-image-original-size {
     display: flex;

    &.label-left-img {
        align-items: center;
        gap: 8px;
    }

    &.label-below-img {
      display: grid;
      row-gap: 8px;
        .label-body1 {
            text-align: center;
            order: 2;
        }
    }

    &.img-align-left  {
      justify-content: flex-start;
    }

    &.img-align-center  {
       justify-content: center;
    }

    &.img-align-right {
      justify-content: flex-end;
    }

    &.mobile-centered  {
      @include respond-below(sm) {
           justify-content: center;
      }
    }

    .label-body1 {
        @include t-paragraph-1();
    }

    &__img {
        width: 100%;
    }

     &__fixed-ratio {
        aspect-ratio: var(--fixed-width) / var(--fixed-height);
     }

}







