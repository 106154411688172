//
//  THEMES
//––––––––––––––––––––––––––––––––––––––––––––––––––

//Default color theme
@mixin default-theme {

    // Primary
    --azure: #00b8de;
    --blue: #2d32aa;
    --red: #f9423a;
    --green: #48d597;
    
    // Secondary
    --darkBlue: #191e80;
    --black: #000000;
    --darkGray: #9a9b9c;
    --gray: #b2b4b3;
    --lightGray: #c9cac8;
    --lighterGray: #e0e1dd;
    --lightColdGray: #F5F7F9;
    --grayWhite: #f0f0f0;
    --dirtyWhite: #fafafa;
    --white: #ffffff;
    --nexiBlack: #1F1F20;
    --goldPrestige: #8D7029;
    --darkSilver: #727474;
    --shadowWhite: rgba(255,255,255,0.1);
    --shadowWhiteHover: rgba(255,255,255,0.15);

    // Commons
    --shadow: rgba(32,32,32,0.15);
    --blackGradient: linear-gradient(0deg, rgba(0, 0, 0, 0.81) 0%, rgba(0, 0, 0, 0.2) 100%);

    // Header
    --headerBg: transparent;
    --headerBgHover: var(--white);
    --headerBgAlt: transparent;
    --headerBgAltHover: var(--white);
    --headerOverlay: rgba(0, 0, 0, 0.7);

    // Buttons
    --buttonPrimaryBg: var(--blue);
    --buttonPrimaryBgHover: var(--darkBlue);
    --buttonPrimaryBgDisabled: var(--lightGray);
    --buttonPrimaryTxt: var(--white);
    --buttonPrimaryBorder: 1px solid var(--blue);
    --buttonPrimaryBgAlt: var(--white);
    --buttonPrimaryBgAltHover: var(--dirtyWhite);
    --buttonPrimaryBgAltDisabled: var(--lightGray);
    --buttonPrimaryTxtAlt: var(--blue);
    --buttonPrimaryTxtAltHover: var(--white);
    --buttonPrimaryBorderAlt: 1px solid var(--white);
    --buttonSecondaryBg: transparent;
    --buttonSecondaryBgHover: var(--shadowWhiteHover);
    --buttonSecondaryBgDisabled: var(--lightGray);
    --buttonSecondaryBorder: 1px solid var(--white);
    --buttonSecondaryTxt: var(--white);
    --buttonSecondaryTxtBlue: var(--blue);
    --buttonSecondaryBgAlt: transparent;
    --buttonSecondaryBgAltHover: rgba(45,50,170,0.05);
    --buttonSecondaryBgAltDisabled: var(--lightGray);
    --buttonSecondaryBorderAlt: 1px solid var(--blue);
    --buttonSecondaryTxtAlt: var(--blue);
    --buttonBigShadow: 0 2px 10px 0 rgba(207,207,207,0.5);

    // Tabs
    --tabGroupBGDefault: #F6F7F9;
    --tabBorder: 1px solid #d3d3d3;
    --tabBorderActive: var(--blue);

    //Circuit modal
    --borderRadiusActive: 4px solid #2d32aa;

    // Card
    --cardSharePanelBg: rgba(0,0,0,0.3);
    --cardSharePanelBgAlt: rgba(0,0,0,0.5);

    //Pre Login
    --preloginFooterBg: #15195F;
    --preloginFooterCardHeightMobile: 95px;
    --preloginFooterCardHeighDesktop: 115px;

    --loginPrivate: #10C1E9;
    --loginBusiness: #6781FC;
    --loginCompanies: #33CCAA;
    --loginDarkBlue: #1C207D;

    // Form
    --inputTextError: #BB2828;
    --inputBorderError: #CB051C;

    // Chatbot
    --chatbotBg: #2C32AA;
    --chatbotBubbleBg: #28C8B6;
}

//
//  COLORS VARS
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Primary
$azure: var(--azure);
$blue: var(--blue);
$red: var(--red);
$green: var(--green);

// Secondary
$darkBlue: var(--darkBlue);
$black: var(--black);
$darkGray: var(--darkGray);
$gray: var(--gray);
$lightGray: var(--lightGray);
$lighterGray: var(--lighterGray);
$grayWhite: var(--grayWhite);
$lightColdGray: var(--lightColdGray);
$dirtyWhite: var(--dirtyWhite);
$white: var(--white);
$nexiBlack: var(--nexiBlack);
$goldPrestige: var(--goldPrestige);
$darkSilver: var(--darkSilver);


// Commons
$shadow: var(--shadow);
$hoverBlue: $darkBlue;
$hoverWhite: $dirtyWhite;

// Header
$headerBg: var(--headerBg);
$headerBgActive: var(--headerBgHover);
$headerBgAlt: var(--headerBgAlt);
$headerBgAltActive: var(--headerBgAltHover);
$headerOverlay: var(--headerOverlay);

// Buttons
$buttonPrimaryBg: var(--buttonPrimaryBg);
$buttonPrimaryBgHover: var(--buttonPrimaryBgHover);
$buttonPrimaryBgDisabled: var(--buttonPrimaryBgDisabled);
$buttonPrimaryTxt: var(--buttonPrimaryTxt);
$buttonPrimaryBorder: var(--buttonPrimaryBorder);
$buttonPrimaryBgAlt: var(--buttonPrimaryBgAlt);
$buttonPrimaryBgAltHover: var(--buttonPrimaryBgAltHover);
$buttonPrimaryBgAltDisabled: var(--buttonPrimaryBgAltDisabled);
$buttonPrimaryTxtAlt: var(--buttonPrimaryTxtAlt);
$buttonPrimaryTxtAltHover: var(--buttonPrimaryTxtAltHover);
$buttonPrimaryBorderAlt: var(--buttonPrimaryBorderAlt);
$buttonSecondaryBg: var(--buttonSecondaryBg);
$buttonSecondaryBgHover: var(--buttonSecondaryBgHover);
$buttonSecondaryBgDisabled: var(--buttonSecondaryBgDisabled);
$buttonSecondaryBorder: var(--buttonSecondaryBorder);
$buttonSecondaryTxt: var(--buttonSecondaryTxt);
$buttonSecondaryBgAlt: var(--buttonSecondaryBgAlt);
$buttonSecondaryBgAltHover: var(--buttonSecondaryBgAltHover);
$buttonSecondaryBgAltDisabled: var(--buttonSecondaryBgAltDisabled);
$buttonSecondaryBorderAlt: var(--buttonSecondaryBorderAlt);
$buttonSecondaryTxtAlt: var(--buttonSecondaryTxtAlt);
$buttonBigShadow: var(--buttonBigShadow);

//Card
$card100GradientBg: var(--blackGradient);
$cardSharePanelBg: var(--cardSharePanelBg);
$cardSharePanelBgAlt: var(--cardSharePanelBgAlt);

//Pre Login
$preloginFooterBg: var(--preloginFooterBg);
$preloginFooterCardHeightMobile: var(--preloginFooterCardHeightMobile);
$preloginFooterCardHeighDesktop: var(--preloginFooterCardHeighDesktop);

$loginPrivate: var(--loginPrivate);
$loginBusiness: var(--loginBusiness);
$loginCompanies: var(--loginCompanies);
$loginDarkBlue: var(--loginDarkBlue);

// Form
$inputTextError: var(--inputTextError);
$inputBorderError: var(--inputBorderError);

// Chatbot
$chatbotBg: var(--chatbotBg);
$chatbotBubbleBg: var(--chatbotBubbleBg);
