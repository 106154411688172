$width100:      '.card__width--100';
$text-white:    '.text-white';
$text-black:    '.text-black';



.elem-image-card {
    @include make-col-ready();
    @include make-col(12);

    $this-card: &;
    width: 100%;
    margin-bottom: rem(31px);

    @include respond-above(lg) {
        @include make-col(4);
        margin-bottom: 0;
    }

    &__container {
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        background-color: white;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 268px;
        padding: rem(24px);

        @include respond-above(lg) {
            min-height: 292px;
            padding: rem(32px) rem(24px);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        min-width: 0;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }

        @include respond-above(lg) {
            //height: $card-image-desktop-big-height;
        }

        picture {
            @include imageCover();
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__pretitle {
        @include karbonMedium();

        font-size: rem(10px);
        line-height: rem(10px);
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $darkGray;

        #{$text-white} & {
            color: $white;
        }

        + #{$this-card}__title {
            margin-top: rem(8px);
        }

        @include respond-above(lg) {
            font-size: rem(14px);
            line-height: rem(18px);
            margin-top: -5px;
            
            + #{$this-card}__title {
                margin-top: rem(8px);
            }
        }
    }

    &__title {
        margin-bottom: rem(12px);

        @include respond-above(lg) {
            margin-bottom: rem(16px);
        }
    }

    &__text-link {
        margin-top: auto;
    }

    &__bottom-text {

        p, span {
            margin-bottom: 0;
        }
    }

    &__link {
        @include t-text-link();
        color: $blue;
        margin-top: rem(30px);

        #{$text-white} & {
            color: $white;
        }

        & > a {
            position: relative;
        }

        & > a > span {
            padding-right: 24px;

            &:after {
                @include nexiIcon();
                
                content: $icon-arrow_right;
                position: absolute;
                right: 0;
                top: 1px;
                font-size: rem(12px);
            }
        }
    }
}
