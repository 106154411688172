.elem-lottie-animation {
    width: 100%;
    height: 100%;
    display: block;

    .lottie {
        width: inherit;
        height: inherit;
    }

    &__desktop, &__tablet {
        display: none;
    }

    &__mobile {
        display: block;
    }

    @include respond-above(md) {
        &__desktop, &__mobile {
            display: none;
        }

        &__tablet {
            display: block;
        }
    }

    @include respond-above(lg) {
        &__tablet, &__mobile {
            display: none;
        }

        &__desktop {
            display: block;
        }
    }

    &__fixed-ratio {
        aspect-ratio: var(--fixed-width) / var(--fixed-height);
    }
}