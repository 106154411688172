
// General
$text-base-size: 16px;

// Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xlm: 1440px,
    xxl: 1920px
);

// Container
$content-container-max-width: 1140px;
$content-container-padding: rem(25px);
$percentage-container: (
    lg: 90,
    xl: 80
);

// Bootstrap grid system config */
$container-max-widths: (
    lg: $content-container-max-width
);

$grid-columns:      12;
$grid-gutter-width: rem(16px);
//$grid-row-columns:  6;

// z-index
$z-layers: (
        bottomless-pit: -9999,
        default: 1,
        backToTop: 3999,
        dropdown: 3000,
        overlay: 4000,
        sticky-menu: 4500,
        chatbot: 4900,
        header: 5000,
        backdrop: 5001,
        modal: 5002,
) !default;

// Commons
$ui-blur: blur(20px);
$bg-shadow: 0 0 10px $shadow;
$bg-alt-shadow: 0 5px 10px $shadow;

// Header
$header-height: 72px;
$header-bg: $headerBg;
$header-bg-active: $headerBgActive;
$header-bg-alt: $headerBgAlt;
$header-bg-alt-active: $headerBgAltActive;
$header-mobile-padding: 0 rem(25px);
$header-mobile-tools-bg: $blue;
$header-mobile-tools-height: rem(70px);
$nav-item-spacing: rem(20px);
$nav-item-mobile-padding: rem(20px);
$nav-item-desktop-padding: rem(10px);
$nav-item-active: 2px solid #fff;
$nav-item-border: #c0c0c0;
$preview-height: 228px;

// Sticky Menu
$sticky-height: 60px;
$sticky-height-mobile: 84px;

// Sticky Bar DOB
$sticky-dob-top-height: 84px;
$sticky-dob-top-height-mobile: 80px;
$sticky-dob-bottom-height-mobile: 80px;

// Footer
$footer-height: 56px;
$footer-bg: #0f0f0f;
$footer-nav-bg: #1f1f1f;
$footer-mobile-padding: 0 rem(40px);

// Hero
// $hero-height-small: 251px;
$hero-height-medium-mobile: 280px;
$hero-height-medium-desktop: 348px;
$hero-height-large: 75vh;
$hero-height-full-footer: calc(100vh - var(--footer-height));
$hero-height-full:  100vh;
// $hero-height-highlight: 560px;

// Select
$custom-select-max-width: 356px;
$custom-select-list-max-height: 620px;

// Card
$card-image-mobile-small-height: 136px;
$card-image-desktop-small-height: 187px;
$card-image-mobile-big-height: 217px;
$card-image-desktop-big-height: 244px;
$card-image-horizontal-small-height: 190px;
$card-image-horizontal-big-height: 343px;
$card-shadow: 0 2px 10px 0 rgba(207,207,207,0.5);
$card-border-radius-small: 8px;
$card-border-radius-big: 16px;
$card-border: 1px solid #D9DADB;

// // Slideshow
$slideshow-mobile-container-height: 392px;  // 536 - (72*2)
$slideshow-desktop-container-height: 540px; // 720 - (90*2)

// // Calendar Events
// $calendar-events-list-max-height: 425px;

// Chatbot component sizing
$help-minimized-width: 60px;
$help-minimized-height: 60px;
$help-expanded-width: 270px;
$help-expanded-height: 140px;

@mixin cssVariables {
    // --header-height: #{$header-height};
    // --header-flyout-link-anim-delay: 0.07s;
    --footer-height: #{$footer-height * 2};
}

