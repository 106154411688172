/////////////////////////////////////////////
// AEM AUTHOR VH UNITS WORKAROUND
/////////////////////////////////////////////

// USAGE:
// add class ".author-vh-HEIGHTNUMBER" on the component you want to resize
// example: .author-vh-75

$h: 0;

@while $h <= 100 {

    .author-vh-#{$h} {
        height: auto !important;
        min-height: $h + 0% !important;
        max-height: $h + 0% !important;
    }

    $h: $h + 5;
}

.author-full-width {
    width: 100%;

    .widget-hero--country & {
        z-index: 10;
    }
}

