.elem-label-card{
    .layout-cards & {
        @include make-col-ready();
        @include make-col(12);
        margin-bottom: rem(32px);
    }

    @include respond-above(lg) {
        .layout-cards & {
            @include make-col(4);
            margin-bottom: 0;
        }
    }

    &__image {
        width: calc(100% - 29px);
        position: relative;

        @include respond-above(lg) {
            width: calc(100% - 35px);
        }

        .elem-image--border {
            border-radius: rem(7px);
        }
    }

    &__box {
        width: 213px;
        border-radius: 10.65px;
        background-color: #FFFFFF;
        box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        position: absolute;
        right: -28px;
        padding: rem(14px);
        display: flex;
        align-items: center;
        min-height: 70px;

        @include respond-above(lg) {
            padding: rem(16px);
            width: 272px;
            border-radius: 12px;
            right: -35px;
            min-height: 80px;
        }

        .bottom & {
            bottom: 16px;

            @include respond-above(lg) {
                bottom: 24px;
            }
        }

        .top & {
            top: 16px;

            @include respond-above(lg) {
                top: 24px;
            }
        }
    }

    &__icon {
        margin-right: rem(10px);

        @include respond-above(lg) {
            margin-right: rem(12px);
        }
    }

    &__border-icon {
        width: 42px;
        height: 42px;
        border: 1px solid #D8D8D8;
        border-radius: 10.65px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-above(lg) {
            width: 48px;
            height: 48px;
            border-radius: 12px;
        }
    }

    &__label {
        @include t-paragraph-1();
        @include karbonMedium();
        color: $black;
        line-height: rem(20px);

        @include respond-above(lg) {
            line-height: rem(22px);
        }
    }
}