.widget-logos-grid {
    $this-logos: &;

    background: $white;
    padding: rem(32px) 0;

    .widget-tab & {
        padding: 0;
    }

    &__container {
        @include container();
        @include content-container();

        .widget-tab & {
            padding: 0 !important;
        }
    }

    &__row {
        @include make-row();

        @include respond-below(lg) {
            margin-right: rem(-5px);
            margin-left: rem(-5px);
        }
    }

    /*&__item-container {
        min-height: 350px;

        @include respond-above(md) {
            min-height: 600px;
        }
    }*/

    &__item {
        @include make-col-ready();
        @include make-col(6);

        margin-bottom: rem(32px);
        transition: height 400ms ease-in-out,
                    opacity 400ms ease-in-out,
                    margin-bottom 400ms ease-in-out ;
        visibility: hidden;
        opacity: 0;
        height: 0;
        //margin-bottom: 0;

        @include respond-below(lg) {
            padding-right: rem(5px);
            padding-left: rem(5px);

        }

        @include respond-above(lg) {
            @include make-col(3);

            margin-bottom: rem(62px);

        }

        @include respond-below(md) {
            display: none;
            &:nth-child(-n + 6) {
                display: revert;
                opacity: 1;
                height: auto;
                visibility: visible;
            }
        }

        @include respond-above(md) {
            display: none;
            &:nth-child(-n + 12) {
                display: revert;
                opacity: 1;
                height: auto;
                visibility: visible;
            }
        }

        &.show {
            display: revert;
            opacity: 1;
            height: auto;
            visibility: visible;
        }

        &.hidden {
            display: none;
        }
    }

    &__item-link {
        display: block;
        width: 100%;
        
        @include mouse-only(){
            &:hover {
                #{$this-logos}__item-image {
                    border:1px solid transparent;
                    box-shadow: $card-shadow;
                }
            }
        }
    }

    &__item-image {
        border-radius: rem(5px);
        overflow: hidden;
        height: 77px;
        border: 1px solid $lighterGray;
        padding: 24px;
        transition: all border .2s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            display: block;
            height: 100%;
            width: auto;
            min-width: auto;
            max-width: 100%;
            min-height: 100%;
            margin: 0 auto;

            @include ie-only() {
                width: 100%;
            }
        }

        @include respond-above(lg) {
            border-radius: rem(8px);
            height: 133px;
        }
    }
    
    &__item-text {
        @include karbonMedium();
        @include t-label-big();

        margin-top: rem(7px);
        text-align: center;
        color: $blue;

        @include respond-above(lg) {
            margin-top: rem(12px);
        }
    }

    &__cta-container {
        justify-content: center;
        margin-top: rem(32px);
        padding: 0 rem(5px);

        @include respond-above(lg) {
            margin-top: rem(23px);

            .elem-cta-load-more {
                min-width: 200px;
            }
           
        }
    }

    .elem-cta-load-more {
        &.cta-hidden {
            display: none;
        }
        
        .hide {
            display: none;
        }
    }

    &.show-all {
        .elem-cta-load-more {
            .hide {
                display: block;
            }
            .show {
                display: none;
            }
        }
    }
}