.layout-news-footer {
    background-color: $white;

    &.p-b-def {
        padding-bottom: rem(40px);

        @include respond-above(lg) {
            padding-bottom: rem(104px);
        }
    }

    &__container {
        @include container();
        @include content-container();

        &:before {
            content: '';
            display: block;
            width: 100%;
            border-top: 1px solid $lighterGray;
        }

        @include respond-above(lg) {
            max-width: calc( 947px + 10vw );
        }

        @include respond-above(xxl) {
            max-width: calc( 947px + 20vw );
        }
    }

    &__row {
        padding: 0 rem(10px);

        @include respond-above(lg) {
            @include make-row();
            
            padding: 0;
        }
    }

    &__column {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include respond-above(lg) {
            @include make-col-ready();
            @include make-col(6);
        }
    }

    .widget-share {
        display: none;

        @include respond-above(lg) {
            display: block;
        }
    }

    .elem-tags {
        padding-top: 24px;
        margin-right:-18px;
        margin-left: -18px;

        @include respond-above(lg) {
            margin-right:-8px;
            margin-left: -8px;
        }
    }

    .layout-cards {
        padding-top: rem(32px);
        padding-right: 0 !important;
        padding-left: 0 !important;
        
        @include respond-above(lg) {
            padding-top: 0;

            &__container {
                justify-content: flex-end;
            }
        }
    }

    .elem-card {
        width: 100%;
        flex: 1 1 auto;
        max-width: none;
        
        @include respond-above(md) {
            max-width: 50%;
        }
        
        @include respond-above(lg) {
            max-width: calc(305px + 1rem);
        }

        &__content {
            background-color: #f6f7f9;
        }
    }
}