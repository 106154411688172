$width100: '.card__width--100';
$width50: '.card__width--50';
$width25: '.card__width--25';
$medium: '.card__medium';
$small: '.card__small';
$extrasmall: '.card__extrasmall';
$rowSpacingSmall:   '.row__spacing--small';

.elem-contact-card {
    @include make-col-ready();
    @include make-col(12);
    width: 100%;
    margin-bottom: rem(24px);
    text-align: center;

    @include respond-above(md) {
        #{$medium} & {
            @include make-col(6);         
        }

        #{$small} & {
            @include make-col(4);
        }

        #{$extrasmall} & {
            @include make-col(3);
        }

        #{$rowSpacingSmall} & {
            margin-bottom: rem(16px);
        }
    }

    @include respond-above(lg) {
        @include make-col(4);
        margin-bottom: 0;

        #{$width100} & {
            @include make-col(12);
        }

        #{$width50} & {
            @include make-col(6);
        }

        #{$width25} & {
            @include make-col(3);
        }
    }

    &__container {
        height: 100%;
        padding: rem(24px);
        border-radius: $card-border-radius-small;
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            padding: rem(32px);
        }

        &.card__link:hover {
            @include mouse-only() {
                box-shadow: $card-shadow;
            }
        }

        &.card__one--column {
            .elem-contact-card__second-column {
                display: none;
            }

            .elem-contact-card__first-column {
                padding-left: rem(8px);
                width: 100%;
            }
        }
    }

    &__title {
        @include karbonMedium();
        @include t-h6-title();

        @include respond-above(md) {
            min-height: 52px;
        }
    }

    &__description {
        @include t-paragraph-2();
        padding-top: rem(16px);

        @include respond-above(md) {
            min-height: 62px;
        }

        @include respond-above(lg) {
            min-height: 69px;
            padding-top: rem(20px);
        }
    }

    &__body {
        display: flex;
        padding-top: rem(16px);
        height: 100%;

        @include respond-above(lg) {
            padding-top: rem(32px);
        }        
    }

    &__first-column, &__second-column {
        width: 50%;
    }

    &__first-column {
        padding-right: rem(8px);
    }

    &__second-column {
        padding-left: rem(8px);
        border-left: 1px solid $lightGray;
    }

    &__first-column-title, &__second-column-title {
        @include t-paragraph-1();
    }

    &__first-column-numbers, &__second-column-numbers {
        justify-content: center;
        padding-top: rem(16px);
        
        .elem-text p {
            margin-bottom: rem(15px);

            @include respond-above(lg) {
                margin-bottom: rem(10px);
            }
        }

        @include respond-above(lg) {
            padding-top: rem(24px);
        }
    }

    .elem-text--link-arrow,
    .elem-text--external-link,
    .elem-text--link-email,
    .elem-text--link-phone {
        @include cut-words();
    }

    .panel-blue & .elem-contact-card__container.panel-grey .elem-text--link-phone,
    .panel-blue & .elem-contact-card__container.panel-light-grey .elem-text--link-phone,
    .panel-blue & .elem-contact-card__container.panel-white .elem-text--link-phone,
    .panel-blue & .elem-contact-card__container.panel-grey .elem-text--external-link,
    .panel-blue & .elem-contact-card__container.panel-light-grey .elem-text--external-link,
    .panel-blue & .elem-contact-card__container.panel-white .elem-text--external-link,
    .panel-blue & .elem-contact-card__container.panel-grey .elem-text--link-email,
    .panel-blue & .elem-contact-card__container.panel-light-grey .elem-text--link-email,
    .panel-blue & .elem-contact-card__container.panel-white .elem-text--link-email,
    .panel-blue & .elem-contact-card__container.panel-grey .elem-text--link-arrow,
    .panel-blue & .elem-contact-card__container.panel-light-grey .elem-text--link-arrow,
    .panel-blue & .elem-contact-card__container.panel-white .elem-text--link-arrow,
    .panel-blue & .elem-contact-card__container.panel-grey .elem-text,
    .panel-blue & .elem-contact-card__container.panel-light-grey .elem-text,
    .panel-blue & .elem-contact-card__container.panel-white .elem-text   {
        color: $blue;
    }
} 