%swiper-buttons {
    position: relative;
    width: 25px;
    height: 25px;
    left: auto;
    right: auto;
    border-radius: 50%;
    background-color: $white;
    
    &:after {
        display: none;
    }

    [class*="icon"] {
        font-size: rem(11px);
        color: $blue;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            top: 0;
            left: 0;
        }

        @include respond-above(lg) {
            font-size: rem(16px);

            &:before {
                width: 40px;
                height: 40px;
            }
        }
    }

    @include respond-above(lg) {
        width: 40px;
        height: 40px;
    }
}

.widget-slideshow {
    position: relative;
    
    &.enable-nav-shadow {

        .swiper-button-next,
        .swiper-button-prev {
            box-shadow: 0 4px 8px 2px rgba(0,0,0,.08);
        }

        .swiper-pagination-bullet:after {
            box-shadow: 0 0 8px 2px rgb(0 0 0 / 34%);
        }
    }

    &.centered-nav {

        .swiper-pagination {
            position: static;
            display: flex;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 100%;
            bottom: auto;
        }

        .swiper-navigation {
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 60px;
            z-index: 100;
    
            @include respond-above(lg) {
                bottom: 36px;
            }
        }

        .swiper-buttons {
            display: none;
            position: absolute;
            top: 13px;
            left: 0;
            width: 100%;
            padding: 0 !important;
            max-width: none;
    
            @include respond-above(lg) {
                display: block;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
        }

        .swiper-button-prev {
            left: 0;
        }
    
        .swiper-button-next {
            right: 0;
        }
    }

    &__container {
        min-height: $slideshow-mobile-container-height;

        @include respond-above(lg) {
            min-height: $slideshow-desktop-container-height;
        }

        #{$wcmmode} & {
            min-height: $slideshow-desktop-container-height !important;
        }

        &--fixedHeight {
            min-height: unset;
            overflow: hidden;

            .widget-hero.widget-hero--home {
                min-height: unset;
            }

            .widget-hero__bg-picture {
                height: auto;
        
                @include respond-above(lg) {
                    height: 100%;
                }
            }

            .widget-hero.widget-hero--full {
                .widget-hero__bg-picture {
                    height: 100%;
                }
            }
        }
    }

    .swiper-pagination {
        bottom: 20px;

        @include respond-above(lg) {
            bottom: 25px;
        }
    }

    .swiper-pagination-bullet {
        display: inline-block;
        padding: rem(4px);
        margin: 4px;
        width: rem(16px);
        height: rem(16px);
        background: none;
        opacity: 0.5;

        &-active {
            opacity: 1;
        }

        &::after {
            content: '';
            display: block;
            width: rem(8px);
            height: rem(8px);
            background-color: $white;
            border-radius: 50%;
        }
    }

    .swiper-navigation {
        position: absolute;
        width: 100%;
        bottom: 24px;

        @include respond-above(lg) {
            bottom: 32px;
        }
    }

    .swiper-buttons {
        @include container();
        @include content-container();

        display: flex;
        justify-content: space-between;
    }

    .swiper-button-next,
    .swiper-button-prev {
        @extend %swiper-buttons;

        @include mouse-only() {

            &:hover {
                background-color: $white;

                [class*="icon"] {
                    color: $blue;
                }
            }
        }
    }

    .swiper-slide {
        //min-height: 100%;
    }

    &__slide {
        padding: rem(72px) 0;
        height: auto;

        @include respond-above(lg) {
            padding: rem(90px) 0;
        }
    }

    &__slide-bg-picture {
        @include imageCover;
        
        position: absolute;
        width: calc(100vw + 2px);   // Workaround for the side white pixel
        max-width: calc(100vw + 2px);   // Workaround for the side white pixel
        margin-left: -1px;   // Workaround for the side white pixel
        top: 0;
        left: 0;
        
        &.media-picture--lazy::after {
            display: none;  // lazy loading animation disabled
        }

        #{$edit_mode} & {
            img {
                position: relative !important;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) !important;
                width: auto !important;
            }
        }
    }

    &__slide-container {
        @include container();
        @include content-container();

        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 200;
        min-height: $slideshow-mobile-container-height;

        @include respond-above(lg) {
            min-height: $slideshow-desktop-container-height;
        }
    }

    &__slide-content {
        display: flex;
        flex-direction: column;
        padding-top: rem(23px);

        @include respond-above(lg) {
            @include make-col(7);

            padding-top: 0;
            min-height: inherit;
            justify-content: center;
        }
    }

    &__slide-cta-container {
        margin-top: rem(36px);

        @include respond-above(lg) {
            margin-top: rem(53px);
        }
    }

    &__slide-cta {
        width: auto;
    }

    .base-main__item:first-child & {
        margin-top:-72px;
    }

    #{$wcmmode} & {
        &:first-child {
            padding-top: rem(48px);
        }

        .swiper {
            position: static !important;
            display: block;
            overflow-y: auto !important;
            height: 100% !important;
        }

        .swiper-wrapper {
            position: static !important;
            display: block;
            height: 100% !important;
            counter-reset: slide;

            .hero.cq-Editable-dom {
                position: relative;
                margin-bottom: 10px;
                border: dashed 2px orangered;


                &::before {
                    position: absolute;
                    content: 'Carousel Slide ' counter(slide);
                    top: -2px;
                    left: -2px;
                    padding: 0 10px;
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    background-color: orangered;
                    border: solid 2px orangered;
                    z-index: 999;
                    counter-increment: slide;
                }
            }
        }

        .swiper-slide {
            transform: none !important;
            width: 100% !important;
            opacity: 1 !important;
        }

        .swiper-lazy-preloader {
            display: none;
        }

        .swiper-pagination {
            display: none;
        }

        .swiper-navigation {
            display: none;
        }
    }
}