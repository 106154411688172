.widget-features-cards{
    $modal: '.widget-modal';

    &__container {
        @include container();
        @include content-container();
    }

    &__card-container {
        @include make-row();
        padding-top: rem(32px);

        @include respond-above(md) {
            padding-top: rem(60px);
        }

        @include respond-above(lg) {
            padding-top: rem(64px);
            padding-bottom: rem(40px);
        }
    
    }

    &__first-column, &__second-column, &__third-column{
        @include make-col-ready();
        @include make-col(12);

        padding-bottom: rem(64px);

        @include respond-above(lg) {
            padding-bottom: rem(88px);
        }
    }

    &__first-column {
        @include respond-above(md) {
            @include make-col(6);
        }

        @include respond-above(lg) {
            @include make-col(4);
        }
    }

    &__first-column-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__title {
        padding-top: rem(28px);

        @include respond-above(lg) {
            padding-top: rem(18px);
        }
    }

    &__subtitle {
        padding-top: rem(12px);

        @include respond-above(md) {
            padding-right: rem(24px);
        }

        @include respond-above(lg) {
            padding-right: 0;
            padding-top: rem(23px);
            
            .elem-text p, .elem-text span {
                margin-bottom: 0;
            }
        }
    }

    &__second-column {
        display: flex;
        justify-content: center;

        @include respond-below(lg) {
            display: none;
        }

        @include respond-above(lg) {
            @include make-col(1);
        }
    }

    &__separation {
        @include respond-above(lg) {
            background-color: $lightGray;
            width: 1px;
        }
    }

    &__third-column {
        @include respond-below(lg) {
            display: none;
        }

        @include respond-above(lg) {
            @include make-col(7);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__description {
        padding-top: rem(24px);
        
        .elem-text ul li {
            margin-bottom: rem(20px);
            letter-spacing: -0.5px;
        }
    }

    .elem-text ul {
        margin-left: 0;
        list-style: none !important;
    }

    .elem-text li {
        position: relative;
        margin-left: rem(31px);
    }

    .elem-text li::before {
        position: absolute;
        margin-top: 9px;
        content: "•";
        background-color: $blue;
        border-radius: 50%;
        left: -31px;
        height: 12px;
        width: 12px;
        color: transparent;
    }
    
    &__modal-link {
        @include respond-above(md) {
            margin-top: auto;
        }

        @include respond-above(lg) {
            display: none;
        }
    }

    //MODAL
    #{$modal} {
        @include respond-above(lg) {
            display: none;
        }
    }

    #{$modal}__header #{$modal}__header-title {
        @include karbonMedium();
    }

    #{$modal}__title {
        margin-bottom: rem(18px);
    }

    #{$modal}__label {
        margin-bottom: 0;
    }

    #{$modal}__label .elem-label {
        padding-bottom: rem(16px);
    }

    #{$modal}__text {    
        li {
            margin-bottom: rem(24px);
        }
    }
}

