.widget-sitemap {
    background-color: $white;
    height: auto;
    padding-bottom:80px;

    @include respond-above(lg) {
        padding: 80px 0;
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__section-name {
        @include karbonMedium();

        width: 100%;
        display: flex;
        flex-direction: row;
        padding-top: 37px;
        border-bottom: 1px solid $lightGray;
        letter-spacing: 2px;
        font-size: rem(20px);
        line-height: rem(21px);
        padding-bottom: 8px;
        text-transform: uppercase;

        @include respond-above(lg) {
            font-size: rem(24px);
            line-height: rem(26px);
            letter-spacing: 1.5px;
            margin-bottom: 17px;
            padding-top: 0;
        }

        p {
            color: $darkGray;
        }

        a {
            @include karbonRegular();
            color: $blue;
            
            &:hover {
                @include karbonMedium();
            }
        }
    }

    &__row {
        @include make-col(12);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include respond-above(lg) {
            flex-direction: row;
        }
    }

    &__column {
        @include make-col(12);

        @include respond-above(lg) {
            @include make-col(3);
        }
    }

    &__page-name {
        p {
            color:$blue;
            font-size: 20px;
            line-height: 21px;
            margin-bottom:16px;
            margin-top:24px;

            a {
                display: block;
                padding-right: 16px;
                position:relative;

                @include mouse-only() {
                    &:hover{
                        @include karbonMedium();
                        color: $blue;
                        text-decoration: underline;
                    }
                }
            }

            @include respond-above(lg) {
                font-size: rem(24px);
                line-height: 28px;
                margin: 0;
            }
        }

        &__secondary {
            @extend .widget-sitemap__page-name;
            p {
                color: $darkGray;
            }

            @include respond-below(lg) {
                margin-top: 32px;
            }
        }
    }

    &__items {
        margin-top:16px;
        

        @include respond-above(lg) {
            margin-top: 27px;
            margin-bottom: 80px;
        }

        li {
            color:$blue;
            font-size: rem(18px);
            letter-spacing: -0.5px;
            line-height: 24px;
            margin-bottom: 12px;

            a {
                display: block;
                padding-right: 16px;
                position:relative;

                @include mouse-only() {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}