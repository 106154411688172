$width100: '.card__width--100';
$width50: '.card__width--50';
$width25: '.card__width--25';
$medium: '.card__medium';
$small: '.card__small';
$extrasmall: '.card__extrasmall';
$rowSpacingSmall:   '.row__spacing--small';

.elem-comparison-card {
    @include make-col-ready();
    @include make-col(12);
    height: auto;
    margin-bottom: rem(32px);

    @include respond-above(lg) {
        @include make-col(4);
        margin-bottom: 0;

        #{$medium} & {
            @include make-col(6);
        }

        #{$small} & {
            @include make-col(4);
        }

        #{$extrasmall} & {
            @include make-col(3);
        }

        #{$rowSpacingSmall} & {
            margin-bottom: rem(16px);
        }

        #{$width100} & {
            @include make-col(12);
        }

        #{$width50} & {
            @include make-col(6);
        }

        #{$width25} & {
            @include make-col(3);
        }
    }

    &__container {
        border-radius: 18px;
        height: 100%;
    }

    &__content {
        padding: rem(23px);
        padding-bottom: rem(32px);
        display: flex;
        flex-direction: column;
        height: 100%;

        @include respond-above(lg) {
            padding: rem(32px);
            padding-bottom: rem(65px);
        }
    }

    &__title {
        padding-bottom: rem(10px);

        @include respond-above(lg) {
            min-height: 144px;
            padding-bottom: rem(32px);
        }
    }

    &__subtitle {

        @include respond-above(lg) {
            min-height: 142px;
        }
    }

    &__features {
        @include respond-above(lg) {
            min-height: 536px;
        }

        .elem-text {
            padding: 0;
        }

        .elem-text > p {
            margin-bottom: rem(24px);

            @include respond-above(lg) {
                margin-bottom: rem(32px);
            }
        }

        .elem-text > span.elem-text--body1 {
            margin-bottom: 0 !important;
        }

        .elem-text > ul {
            letter-spacing: -0.5px;

            @include respond-below(lg){
                margin-bottom: 0;
                margin-left: rem(17px);
            }

            @include respond-above(lg) {
                margin-bottom: rem(8px);
                // margin-left: rem(20px);
            }
        }

        .elem-text > ul li {
            padding-bottom: rem(16px);

            @include respond-above(lg) {
                padding-bottom: rem(24px);
                margin-bottom: 0;
            }
        }

        .elem-accordion {
            padding-bottom: 0;

            @include respond-above(lg) {
                pointer-events: none;
            }
        }

        
        .elem-accordion__container {
            padding: 0 !important;
        }
   
        .elem-accordion__body {
            max-width: none;

            @include respond-above(lg){
                opacity: 1;
                max-height: none;
            }
        }

        .elem-accordion__body .elem-text {
            @include respond-above(lg) {
                margin-top: rem(16px);
            }
        }

        .elem-accordion__header {
            padding-top: rem(24px);
            border-color: #979797;

            @include respond-above(lg){
                border: none;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .elem-accordion__icon {
            @include respond-above(lg) {
                display: none;
            }
        }
    }

    &__container.panel-blue &__features .elem-accordion__header {
        border-color: $white;
    }

    &__container.panel-white &__features {
        .elem-text > ul li::marker {
            color: $blue;
        }
    }

    &__separation {
        border-top: 1px solid #979797;
        margin-bottom: rem(24px);

        @include respond-above(lg) {
            margin-bottom: rem(32px);
            margin-top: auto;
        }
    }

    &__container.panel-blue &__separation {
        border-color: $white;
    }

    &__price {
        p {
            margin-bottom: rem(16px) !important;

            @include respond-above(lg) {
                margin-bottom: rem(32px) !important;
            }
        }

        span {
            display: inline-block !important;
            margin-bottom: 0 !important;
        }
    }

    &__container.panel-white &__label .elem-label {
        color: $black;
    }

    &__label {
        .elem-label {
            padding-bottom: rem(8px);

            @include respond-above(lg) {
                padding-bottom: rem(16px);
            }
        }
    }
    
    &__cta-container {
        // margin-top: auto;
        padding-top: rem(8px);

        @include respond-above(lg) {
            padding-top: 0;
        }

        a {
            width: 100%;
        }

        button {
            width: 100%;
        }
    }
}