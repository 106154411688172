.elem-label {
    padding-bottom: rem(13px);
    color: $darkGray;

    @include respond-above(lg) {
        padding-bottom: rem(7px);
    }

    &.small {
        @include t-label-small();
    }

    &.big {
        @include t-label-big();
    }

    .panel-blue & {
        color: $white;
    }
}