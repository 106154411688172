@mixin selectArrow() {
    &::before {
        @include nexiIcon();
        
        content: $icon-caret_up;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -45%) rotate(180deg);
        pointer-events: none;
        color: #000000;

        @include respond-above(lg) {
            //right: 30px;
            font-size: 22px;
        }
    }
}

.elem-custom-select-filter-table {
    width: rem(250px);
    position: relative;

    &__label {
        font-size: rem(18px);
        line-height: rem(24px);
    }

    &__box {
        position: relative;
        @include karbonRegular();
        @include selectArrow();
        min-width: rem(250px);
        display: inline-block;
        cursor: pointer;
        color: #5D5E5E;
        background: #FFFFFF;
        border: 1px solid black;
        border-radius: rem(8px);
        padding: rem(12px) rem(8px);
        margin: rem(1px);
        font-size: rem(18px);
        line-height: rem(24px);

        &.selected {
            border: 2px solid #2D32AA;
            margin: 0;

            &::before {
                color: black;
                transform: translate(0, -45%);
            }
        }
    }

    &__select-options {
        position: absolute;
        z-index: 2;
        width: 100%;
        background-color: #FFFFFF;
        border: 1px solid white;
        border-radius: 8px;
        box-shadow: 0px 10px 22px 0px #2D4D6C26;
        max-height: 0px;
        opacity: 0;
        -webkit-transition: all .5s ease-in-out 0s;
        transition: all .5s ease-in-out 0s;
        overflow: hidden;

        &.show {
            max-height: rem(196px);
            opacity: 1;
        }

        &-scroll-wrapper {
            position: relative;
            max-height: rem(196px);
        }
    }

    &__option {
        @include karbonRegular();
        font-size: rem(18px);
        line-height: rem(24px);
        display: flex;
        align-items: center;
        padding: rem(9px) rem(12px);
        width: 100%;
        cursor: pointer;

        input {
            transform: scale(1.2);
            margin: 0 rem(8px) 0 rem(5px);
            accent-color: #2D32AA;
            color: #2D32AA;
            border: rem(2px) solid #2D32AA;
            height: rem(16px);
            width: rem(16px);
            border-radius: rem(3px);
        }

        input:not(:checked) {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
        }
    }
}