.elem-list-icons {
    padding-top: rem(32px);
    padding-bottom: rem(20px);
    background-color: #F6F7F9;

    @include respond-above(lg) {
        padding-top: rem(64px);
        padding-bottom: rem(64px);
    }
    &__container {
        @include container();
        @include content-container();
    }
    &__content {
        @include make_row();
        padding-top: rem(64px);
    }
    &__col {
        @include make-col-ready();
        @include make-col(6);
        padding-bottom: rem(44px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-above(lg) {
            @include make-col(3);
            padding-left: rem(8px);
            padding-right: rem(8px);
            padding-bottom: 0;
        }
    }
    &__icon {
        img {
            width: auto;
        }
    }
    &__label {
        @include karbonMedium();
        margin-top: rem(9px);
        @include t-h6-title();

        @include respond-above(lg) {
            margin-top: rem(24px);
        }
    }
}

/* classes for columns alignment */

.align-right {
    .elem-list-icons__col {
        align-items: flex-end;
        text-align: right;
    }
}

.align-left {
    .elem-list-icons__col {
        align-items: flex-start;
        text-align: left;
    }
}

.align-center {
    .elem-list-icons__col {
        align-items: center;
        text-align: center;
    }
}
