%hidden-content {
    position: absolute;
    top: 100px;
    opacity: 0;
}

%visible-content {
    position: relative;
    top: 0;
    opacity: 1;
}

.help-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0;
    z-index: setZ(chatbot);
    pointer-events: none;
}

.help-minimized {
    @include karbonSemibold();
    
    width: $help-minimized-width;
    height: $help-minimized-height;
    display: flex;
    justify-content: space-between;
    text-align: center;
    overflow: hidden;
    background-color: $black;
    border-radius: 50%;
    color: $white;
    font-size: 16px;
    letter-spacing: 1px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    // box-shadow: 0 0 15px 0 rgba($white, 0.45);
    box-shadow: 0 0 15px 0 rgb(255 255 255 / 45%);
    margin-right: rem(25px);
    margin-bottom: rem(25px);
    transition: margin 350ms ease-out;

    &:hover {
        // box-shadow: 0 0 10px 0 rgba($black, 0.6);
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 60%);
    }

    .icon img {
        height: 30px;
        width: 30px;
    }

    @include respond-above(lg) {
        margin-right: rem(20px);
        margin-bottom: rem(20px);
    }

    @include respond-below(lg) {
        //margin-bottom: 145px;
        margin-bottom: 64px;

        /*.sticky-menu--mobile & {
            //margin-bottom: 100px;
            margin-bottom: 163px;
        }*/
    }
}

.help-expanded {
    padding: 13px 15px 0px 15px;
    width: 100vw;
    height: auto;
    box-shadow: 0 0 15px 0 #fff;
    text-align: left;
    align-items: unset;
    background: $chatbotBg;
    border-radius: 0;
    margin-right: 0;
    margin-bottom: 0;

    @include respond-above(md) {
        width: $help-expanded-width;
        border-radius: 5px;
    }

    .help-label {
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: all 0.1s ease-out;
    }
    
    .help-content {
        @extend %visible-content;
    }
    
    .help-bubble,
    .help-bubble.active {
        display: none;
    }

    @include respond-below(lg) {
        .sticky-menu--mobile & {
            margin-bottom: 0;
        }
    }
}

.help-bubble {
    display: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $chatbotBubbleBg;
    position: absolute;
    top: -2px;
    left:-2px;
  
    &.active {
        display: block;
    }
}

.help-inner {
    font-size: rem(18px);
    line-height: rem(18px);
    width: 100%;
    pointer-events: all;
  
    &:not(.active) * {
        pointer-events: none;
    }
  
    &.active {
        display: flex;
        justify-content: center;
  
        * {
            pointer-events: all;
        }
    }
}

.help-content {
    @extend %hidden-content;

    justify-content: space-between;
    flex-direction: column;

    .is-opening & {
        width: 100vw;
        opacity: 0;
        
        @include respond-above(md) {
            width: $help-expanded-width;
        }
    }
}

.help-label {
    @extend %visible-content;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.help-header,
.help-chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.help-header {
    @include karbonMedium();

    margin-bottom: rem(27px);
}

.help-close {
    height: 19px;
    
    i {
      line-height: 19px;
        font-size: 16px;
    }
}

.help-select-container {
    display: none;
    margin-bottom: rem(9px);

    &.active {
      display: block;
    }

}

.help-list-items {
    margin: 0;
    line-height: 38px;
    padding-left: 0;
    height: 120px;
    letter-spacing: 2px;
  
    a {
        @include karbonSemibold();
        color: $white;
        text-transform: uppercase;
        font-size: 16px;
    }
}

.help-chat {
    @include karbonSemibold();

    margin-top: rem(4.5px);
    margin-left: rem(-15px);
    margin-right: rem(-15px);
    background: $black;
    font-size: 16px;
    letter-spacing: 2px;

    .help-open-chat {
        @include karbonSemibold();

        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        text-align: inherit;
        outline: none;
        border-radius: 0;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        padding: 15px !important;
    }

    .chat-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: 30px;
    }

    .icon img {
        display: block;
    }
}

.help-chat__badge {
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: $chatbotBubbleBg;
    color: $white;
    margin-right: auto;
    margin-left: 20px;
    justify-content: center;
    font-size: 18px;

    &.active {
        display: block;
    }
}

// chat widget
.cx-widget {
    right: 20px !important;

    &.cx-theme-nexi {
        position: fixed;
        z-index: setZ(modal);
    }
}

.cx-loading-icon.cx-icon,
.cx-loading-icon.cx-icon:before {
  @include karbonRegular();
}

#cx_chat_end_question {
    font-size: rem(18px);
    margin-bottom: rem(18px);
}

.widget-chatbot {

    @include respond-below(lg) {
        .base-sticky-menu--hidden ~ & .help-minimized {
            //margin-bottom: 163px;
        }
    }

    .custom-select {
        position: relative;
        width: 100%;
    
        select {
            position: absolute;
            visibility: hidden;
            opacity: 0;
        }
    
        &--open {
    
            .custom-select__opener {
                color: $azure;
                background-color: $white;
                border-bottom: 1px solid $white;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                box-shadow: $bg-shadow;
                transition: background-color .2s ease-out,
                            border-radius 0s ease-out;
    
                &::before {
                    color: $black;
                    transform: translate(0, -45%);
                }
    
                @include mouse-only() {
                    &:hover {
                        background-color: $white;
                    }
                }
            }
    
            .custom-select__panel {
                // max-height: calc(35vh + 50px);
                max-height: 138px;
                box-shadow: $bg-alt-shadow;
                // padding: rem(15px);
            }
    
            .custom-select__options {
                // max-height: 35vh;
                max-height: 123px;
                overflow: auto;
    
                @include respond-above(lg) {
                    //max-height: $custom-select-list-max-height;
                }
            }
        }
    
        &__opener {
            @include karbonRegular();
    
            display: block;
            border: 1px solid $white;
            background-color: $white;
            border-radius: 2px;
            padding: 0;
            padding-left: 15px;
            padding-right: 30px;
            font-size: rem(18px);
            line-height: rem(50px);
            letter-spacing: 1px;
            color: $black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
    
            &::before {
                @include nexiIcon();
                
                content: $icon-caret_up;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translate(0, -45%) rotate(-180deg);
                transition: all 0.25s ease-out;
                pointer-events: none;
        
                @include respond-above(lg) {
                    
                    font-size: 28px;
                }
            }

            @include mouse-only() {
                &:hover {
                    background-color: $white;
                }
            }
        }
    
        &__panel {
            position: absolute;
            z-index: 100;
            left: 0;
            color: $black;
            //top: 100%;
            width: 100%;
            overflow: hidden;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
            background-color: $white;
            max-height: 0;
            transition: max-height .4s ease-out;
        }
    
        &__options {
            position: relative;
            width: 100%;
            z-index: 1;
            margin-bottom: rem(15px);
            padding: rem(15px) rem(15px) 0 rem(15px);
            
            // Perfect Scrollbar style
            .ps__thumb-y {
                background-color: $white;
                right: 12px;
                width: 6px;
    
                .list-filter & {
                    right: 5px;
                }
            }
    
            .ps__rail-y {
                width: 40px;
                margin: 16px 0;
    
                .list-filter & {
                    width: 16px;
                }
    
                &:hover,
                &:focus,
                &.ps--clicking {
    
                    .ps__thumb-y {
                        background-color: $white;
                        right: 11px;
                        width: 8px;
    
                        .list-filter & {
                            right: 4px;
                        }
                    }
                }
            }
        }
    
        &__option {
            @include karbonRegular();
    
            font-size: rem(18px);
            padding: rem(10px) 0;
            letter-spacing: 0;
    
            &--selected {
                @include karbonSemibold();
                color: $azure;
                background-color: $white;
            }
    
            @include mouse-only() {
                &:hover {
                    // font-family: KarbonSemibold;
                    background-color: $white;
                }
            }

            &:hover {
                @include karbonMedium();
            }
        }
    }
}