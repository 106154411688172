.elem-accordion-faq {
    $this: &;

    &__wrapper {
        @include container();
        @include content-container();
    }

    .layout-panel &__wrapper {
        padding: 0 0 !important;
        max-width: none;
    }

    &__row {
        @include make-row();
    }

    &__item {
        @include make-col-ready();
        @include make-col(12);
    }

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #C0C0C0;
        padding-top: rem(20px);
        padding-bottom: rem(24px);
        padding-right: rem(20px);
        cursor: pointer;

        @include respond-above(lg) {
            padding-bottom: rem(32px);
        }
    }

    &__header-checkbox {

        &:checked ~ #{$this}__body {
            @extend %visible;
        }

        &:checked + #{$this}__header #{$this}__icon {
            @extend %caretUp;
        }
    }

    &__title {
        @include karbonMedium();
        @include t-paragraph-1();
    }

    &__icon {
        position: absolute;
        right: 0;
        color: $blue;
        font-size: rem(20px);

        #{$edit_mode} & {
            @extend %caretUp;
        }
    }

    &__body {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        transition: max-height 300ms ease-out, opacity 400ms ease-out;

        .elem-accordion-faq__text {
            @include t-paragraph-1();
            color: $darkGray;
            width: 100%;
            max-width: 88%;
            padding-bottom: rem(32px);
            padding-top: rem(8px);

            ul {
                margin-bottom: rem(24px);
            }

            @include respond-above(lg) {
                padding-top: 0;
                max-width: 91%;
                //margin-top: rem(-8px);
                padding-bottom: rem(40px);
            }

            .panel-blue & {
                color: $white;
            }

            .panel-grey &, .panel-light-grey & {
                color: $black;
            } 
        }

        .elem-image {
            padding-bottom: rem(42px);
        }

        #{$edit_mode} & {
            @extend %visible;
        }
    }

    input[type=checkbox] {
        display: none;
        position: absolute;
    }

    &__result-text {
        display: none;
        margin-bottom: 24px;
        @include t-paragraph-1();

        &.result-text--show {
            display: block;
            
            @include respond-above(md) {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;

                &.result-text--no-result {
                    display: block;
                }
            }
        }

        &.result-text--no-result {
            margin-bottom: 0;

            .elem-accordion-faq__no-result {
                display: inline-block;
            }
        }
    }
    
    &__no-result {
        display: none;
    }
    
    &__result-label, &__result-label-text {
        @include karbonMedium();
    }
    
    &__result-delete {
        @include t-text-link();
        //cursor: pointer;
        color: $blue;
        margin-top: rem(8px);
        letter-spacing: 1px;
        display: block;
        @include respond-above(md) {
            margin-top: 0;
            margin-left: rem(12px);
            display: inline-block;
        }

        .panel-blue & {
            color: $white;
        }
    }

    &__label-frequent-ask {
        @include t-paragraph-1();

        margin-bottom: 24px;

        &.frequent-ask--hidden {
            display: none;
        }
    }

    &__buttons-container {
        display: flex;
        justify-content: center;
    }

    &__label-more, &__label-less {
        @include karbonMedium();
        text-transform: uppercase;
        color: $blue;
        letter-spacing: 1px;
        padding-top: rem(16px);

        @include respond-above(lg) {
            padding-top: rem(32px);
        }
    }

    &__label-more {
        display: none;
        .more-view & {
            display: block;
        }
        .less-view & {
            display: none;
        }
    }

    &__label-less {
        display: none;
        .more-view & {
            display: none;
        }
        .less-view & {
            display: block;
        }
    }
}

.lp-dob {
    .elem-accordion-faq {
        &__wrapper {
            @include respond-above(xl) {
                padding-left: 5vw !important;
                padding-right: 5vw !important;
                max-width: calc( #{$content-container-max-width} + 20vw);
            }
        }
    }
}
