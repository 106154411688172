@use 'sass:math';

.widget-hero {
    $this: &;

    &__bg-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        pointer-events: none;
        overflow: hidden;

        &.tv-ratio {

            iframe {
                
                @media (min-aspect-ratio: 4/3) {
                    height: #{math.div(100, math.div(3,4))}vw;
                }
                
                @media (max-aspect-ratio: 4/3) {
                    width: #{math.div(100, math.div(3,4))}vh;
                }
            }
        }
    }

    &__bg-video-container {
        position: relative;
        overflow: hidden;
        width: 100vw;
        // height: 100vh;
        height: inherit;

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100vw;
            height: 100vh;
            transform: translate(-50%, -50%);
            //height: #{(100 * (9/16))}vw;
            //height: #{(100 * (3/4))}vw;

            @media (min-aspect-ratio: 16/9) {
                height: #{math.div(100, math.div(9,16))}vw;
            }
            
            @media (max-aspect-ratio: 16/9) {
                width: #{math.div(100, math.div(9,16))}vh;
            }
        }

        @include respond-below(lg) {
            &.is-mobile-hidden {
                display: none;

                #{$this}-product & {
                    display: block;
                }
            }
        }
    }

    &__thumb-video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .media-picture {
            @include imageCover;
        }

        img {
            #{$edit_mode} & {
                position: relative !important;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) !important;
                width: auto !important;
            }
        }
    }

    &__row {
        @include make-row();
        
        flex-direction: column;
        padding-top: rem(72px);
        padding-bottom: rem(30px);
        flex-grow: 1;

        @include respond-above(lg) {
            flex-direction: row-reverse;
            padding-top: rem(218px);
        }

        @include ie-only {
            height: 100%;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        padding: 0 8px;

        @include respond-above(lg) {
            min-height: inherit;
        }
    }

    &__picture,
    &__video {
        margin: rem(24px) 0;

        .media-picture__img {
            max-width: 100%;
            height: auto;
            width: auto;
            min-width: auto;
            margin: 0 auto;

            @include respond-between(md, lg) {
                max-width: 720px;
            }

            @media (max-width: 1023px) and (orientation: landscape) {
                width: 100%;
            }
        }

        @media (max-width: 1023px) and (orientation: landscape) {
            @include make-col(4);

            position: absolute;
            right: 25px;
            z-index: 1;
        }
        
        @include respond-above(lg) {
            @include make-col(6);
            
            margin: 0;
        }
    }

    &__content {

        #{$this}-product & {
            padding-top: 0;

            @media (max-width: 1023px) and (orientation: landscape) {
                @include make-col(8);
            }
        }

        @include ie-only {
            #{$this}-product & {
                min-height: auto;
            }
        }
        
    }

}