$width50: '.column__width--50';
$width-offer: '.column__width--offer';
$reverse-mobile: '.reverse-mobile';

.widget-app-payments {
    $this: &;
    position: relative;

    &__container {
        @include container();
        @include content-container();

        @include respond-below(lg) {
            #{$width-offer} & {
                padding-left: rem(20px) !important;
                padding-right: rem(20px) !important;
            }
        }
    }

    &__column {
        padding-bottom: rem(32px);

        @include respond-below(lg) {
            display: flex;
            flex-direction: column;
            
            #{$reverse-mobile} & {
                padding-bottom: 0;
                padding-top: rem(32px);
            }
        }

        @include respond-above(lg) {
            @include make-col(5);

            padding-bottom: rem(64px);
            
            #{$width50} & {
                @include make-col(6);
                padding-right: rem(34px);
            }

            #{$width-offer} & {
                @include make-col(6);
                padding-right: rem(40px);
            }
        }

        #{$width-offer} & {
            padding-bottom: rem(40px);
        }
    }

    &__icon {
        padding: rem(32px) 0;
        
        img {
            width: auto;
            min-width:auto;
            height: rem(29px);
        }

        #{$width-offer} & {
            padding-top: rem(40px);
            padding-bottom: rem(16px);
        }

        @include respond-above(lg) {
            padding-bottom: rem(40px);
            padding-top: rem(60px);

            ~ #{$this}__title {
                padding-top: 0;
            }

            #{$width-offer} & {
                padding-top: rem(40px);
                padding-bottom: rem(32px);
            }
        }

        &:has(#{$this}__secondary-icon) {
            display: flex;

            #{$this}__primary-icon {
                float: left;
            }

            #{$this}__secondary-icon {
                float: left;
                border-left: solid rem(1px);
                border-color: rgba(0, 0, 0, 0.30);
                padding-left: rem(10px);
                margin-left: rem(10px);
            }
        }
    }

    &__bg-picture {
        margin: 0 rem(-25px) rem(32px) rem(-25px);

        @include respond-below(lg) {
            #{$reverse-mobile} & {
                order: 1;
                margin-top: rem(40px);
                margin-bottom: 0;
            }

            &:has(.widget-hero__bg-video-container.is-mobile-hidden) {
                margin: 0;
            }
        }

        @include respond-above(lg) {
            @include make-col(8);
            margin: 0;
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            height: 100%;
            overflow: hidden;

            picture {
                height: 100%;
                width: auto;
            }

            img {
                width: auto;
                height: 100%;
                min-width: auto;
                margin-left: auto;
            }

            #{$width50} & {
                @include make-col(6);
                width: 100%;
                margin: 0;

                img {
                    height: auto;
                    min-width: 100%;
                }
            }
        }

        video {
            width: 100%;
            height: 100%;

            &.video__fixed-ratio {
                aspect-ratio: var(--fixed-width) / var(--fixed-height);
            }
        }

        iframe {
            width: 100%;
            height: 100%;
        }

        .widget-hero__bg-video {
            position: relative;

            &.tv-ratio {
                aspect-ratio: 4 / 3;
            }

            .widget-hero__bg-video-container {
                aspect-ratio: 16 / 9;
                width: 100%;
            }
        }
    }

    &__pre-title {
        margin-bottom: rem(8px);

        h1, h2, h3, h4, h5, h6, span, p {
            @include t-label-big();
            font-size: rem(14px);
            line-height: rem(18px);

            @include respond-above(lg) {
                font-size: rem(14px);
                line-height: rem(18px);
            }
        }
    }

    &__title,
    &__description {
        z-index: 10;
        position: relative;
    }

    &__title {
        @include respond-above(lg) {
            padding-top: 88px;
        }
    }

    &__description {

        @include respond-above(lg) {
            #{$width50} & {
                padding-right: rem(39px);
            }
        }
    }

    &__cta {
        @include respond-below(lg) {
            &.is-mobile-hidden {
                display: none;
            }
        }
    }

    &__badge {
        display: flex;
        padding: rem(8px) rem(12px);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: rem(20px) 0 0 rem(20px);
        position: absolute;
        right: rem(0px);
        z-index: 1;
        top: rem(356px);

        @include respond-below(lg) {
            #{$reverse-mobile} & {
                top: revert;
                bottom: rem(100px);
            }
        }

        @include respond-above(md) {
            top: revert;
            bottom: rem(328px);
        }

        @include respond-above(lg) {
            bottom: rem(160px);
        }

        &-text {
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 2px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;

            @include respond-above(md) {
                font-size: 16px;
                line-height: 16px;
            }
        }
    }
}

.lp-dob {
    .widget-app-payments {
        &__container {
            @include respond-above(xl) {
                padding-left: 5vw !important;
                padding-right: 5vw !important;
                max-width: calc( #{$content-container-max-width} + 20vw);
            }
        }
    }
}
