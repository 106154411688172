.elem-tags {
    display: flex;
    flex-wrap: wrap;

    &__item {
        @include karbonMedium();

        background: transparent;
        padding: rem(8px) rem(20px);
        font-size: rem(16px);
        line-height: rem(16px);
        margin: rem(8px);
        letter-spacing: 2px;
        cursor: default;

        .elem-card & {
            font-size: rem(14px);
            padding: rem(0px) rem(13px) rem(0px) rem(0px);
            margin: rem(4px);
            letter-spacing: normal;
        }

        .skeleton-card & {
            width: 86px;
            height: 32px;
            background-color: $grayWhite;
            border: none;
        }

        @include respond-below(lg) {

            .card__width--100 .elem-card__image ~ .elem-card__details & {
                color: $white;
                background: transparent;
                //border: 1px solid $white;
            }
        }
    }
}