$logos: '[data-modal-type="logos"]';
$filters: '[data-modal-type="filters"]';
$lang-switcher: '[data-modal-type="lang-switcher"]';
$video : '[data-modal-type="video"]';

@keyframes bummer {
    100% {
        transform: scale(1, 1);
    }
}

// MODAL
.widget-modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 400ms ease-in-out;
    //display: none;

    &.hide {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        visibility: hidden;
        transition: all 400ms ease-in-out;
    }

    &.show {
        z-index: setZ(modal);
        //display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &__backdrop {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        background-color: #000;
        opacity: 0.6;
        animation: fadeOut 1s;
        //transition: opacity 1s ease-in;

        #{$edit_mode} & {
            display: none;
        }
    }

    &__dialog {
        height: 100%;
        position: relative;
        z-index: 10;

        #{$filters} & {
            background: $white;
        }

        @include respond-above(lg) {
            margin: 85px auto;
            height: calc(100% - 170px);
            max-height: calc(100% - 170px);
            display: flex;
            align-items: center;
            pointer-events: none;
            width: 83.3333333333%;
            max-width: 996px;

            #{$logos} & {
                max-width: 939px;
            }

            #{$lang-switcher} & {
                width: 83.3333333333%;
                max-width: 996px;
            }
        }
    }

    &__container {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding-bottom: rem(20px);
        background-color: $white;
        position: relative;
        pointer-events: auto;

        #{$lang-switcher} & {
            background-color: $white;
            position: relative;
        }

        #{$video} & {
            background-color: $black;
            position: relative;
        }

        @include respond-above(lg) {
            height: auto;
            max-height: 100%;
            flex: 1;
            border-radius: rem(8px);

            #{$logos} & {
                padding-bottom: rem(47px);
            }

            #{$lang-switcher} & {
                border-radius: rem(16px);
            }
        }

        // Perfect Scrollbar style
        .ps__thumb-y {
            right: 6px;
            width: 4px;
            //background-color: $blue;
        }

        .ps__rail-y {
            right: 5px;
            margin-bottom: 16px;

            &:hover,
            &:focus,
            &.ps--clicking {
                
                .ps__thumb-y {
                    right: 4px;
                    width: 8px;
                }
            }
        }

        @include ie-only() {
            height: 100%;

            #{$logos} & {
                height: auto;
            }
        }
    }

    &__header {
        padding: rem(24px) rem(24px) rem(28px) rem(24px);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        pointer-events: auto;

        #{$filters} & {
            justify-content: flex-end;
            box-shadow: $bg-shadow;
        }

        #{$lang-switcher} & {
            padding-top: rem(23px);
            padding-right: rem(23px);
            justify-content: flex-end;
        }

        @include respond-above(lg) {
            padding: rem(20px) rem(32px);
            margin: 0 rem(32px);
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid $lighterGray;

            #{$lang-switcher} & {
                padding: rem(24px) rem(36px) rem(20px);
                margin: 0;
                border-bottom: none;
            }
        }

        &-title {
            @include t-label-big();
            color: $black;

            #{$filters} & {
                width: 100%;
                padding-left: rem(24px);
                text-align: center;
                font-size: rem(18px);
                line-height: rem(24px);
            }

            #{$lang-switcher} & {
                position: absolute;
                width: calc(100% - 60px);
                top: rem(60px);
                left: 0;
                z-index: 1;
                margin: 0 30px;
                text-align: center;
                text-transform: none;
                letter-spacing: 0;
                font-size: rem(24px);
                line-height: rem(28px);
                background-color: $white;

                @include respond-above(lg) {
                    font-size: rem(32px);
                    line-height: rem(40px);
                }
            }
        }
    }
    
    &__close {
        color: $black;
        padding: 5px;

        #{$video} & {
            color: $white;
        }

        .icon-close {
            font-size: rem(14px);
            display: block;
        }
    }

    &__body {
        padding-left: rem(24px);
        padding-right: rem(24px);
        overflow-y: auto;
        position: relative;
        padding-bottom: rem(12px);
        pointer-events: auto;

        #{$filters} & {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            height: 100%;
        }

        #{$lang-switcher} & {
            padding-top: 30px;
            padding-bottom: 28px;
        }

        #{$video} & {
            color: $white;
        }

        @include respond-above(md) {
            #{$lang-switcher} & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        @include respond-above(lg) {
            padding-top: rem(32px);
            padding-left: rem(32px);
            padding-right: rem(32px);
            padding-bottom: 0;
        }
    }

    &__title {
        color: $black;
        margin-bottom: rem(16px);

        @include respond-above(lg) {
            margin-bottom: rem(24px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            flex-direction: row;
        }
    }

    &__image {
        width: 100%;
        height: 238px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 24px;

        picture {
            height: 100%;
            width: 100%;

            img {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: auto;
                width: auto;
            }
        }

        @include respond-above(lg) {
            margin-bottom: 32px;
            width: 369px;
            height: 267px;
        }
    }

    &__logo {
        width: 126px;
        height: 84px;
        margin-bottom: rem(16px);
        border: 1px solid $lighterGray;

        img {
            height: 100%;
            width:auto;
            max-width: 100%;
            margin: 0 auto;
            padding: 12px;

            @include ie-only() {
                width: 100%;
            }
        }

        @include respond-above(lg) {
            max-width: 138px;
            flex: 0 0 138px;
            height: 92px;
            margin-top: rem(7px);
            margin-right: rem(32px);
        }
    }

    &__video {
        width: 100%;
        display: flex;
        height: 70vh;

        iframe {
            width: 100%;
        }
    }

    &__text {
        @include t-paragraph-1();
        color: $black;
    }

    &__label {
        @include karbonMedium();
        font-size: rem(14px);
        line-height: rem(18px);
        color: $black;
        letter-spacing: 2px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 6px;
    }

    &__cta {
        + .widget-modal__cta {
            margin-top: 16px;
        }

        @include respond-above(lg) {
            + .widget-modal__cta {
                margin-left: 56px;
                margin-top: 0;
            }
        }
    }

    &__table {
        display: none;

        @include respond-above(md) {
            display: block;
            position: relative;
        }
    }

    &__list,
    &__listTop {
        display: flex;
        justify-content: space-between;
        -webkit-box-pack: justify;
    }

    &__listTop {
        padding-bottom: rem(15px);
    }

    &__itemTop {
        @include karbonMedium();
        @include t-label-small();
        color: $darkGray;
        text-transform: uppercase;
        text-align: right;
        display: block;
        margin-bottom: 1rem;
        border-bottom: 1px solid $lighterGray;
        padding-bottom: 10px;

        &:last-child {
            padding-left: 0.3125rem;
        }

        @include respond-above(md) {
            flex-basis: 25%;
            flex-shrink: 0;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;

            &:first-child {
                width: 50%;
                -webkit-box-flex: 1;
                flex-grow: 1;
                text-align: left;
            }
        }
    }

    &__tableWrapper {
        border-bottom: 1px solid $lighterGray;
        border-top: 1px solid $lighterGray;
        margin-bottom: rem(32px);
        padding-top: rem(24px);
    }

    &__item {
        @include t-paragraph-2();
        letter-spacing: 0;
        flex-basis: 25%;
        flex-shrink: 0;
        margin-bottom: rem(24px);
        text-align: right;

        b {
            @include karbonMedium();
            // font-weight: 400;
        }

        &:first-child {
            width: 50%;
            -webkit-box-flex: 1;
            flex-grow: 1;
            text-align: left;
        }

        @include respond-above(md) {
            letter-spacing: -0.5px;
        }
    }

    &__tableMobile {
        display: block;
        padding-top: rem(20px);

        @include respond-above(md) {
            display: none;
        }
    }

    &__listTopMobile {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: rem(24px);
        border-bottom: 1px solid $lighterGray;
        padding-bottom: rem(8px);
    }

    &__listTopMobile > &__itemTop {
        max-width: 50%;
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;

        &:first-of-type {
            text-align: left;
        }
    }

    &__tableWrapperMobile {
        margin-bottom: rem(24px);
        &:last-child {
            border-bottom: 1px solid $lighterGray;
        }
    }

    &__filters {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__filter-buttons {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 60px 0 70px 0;
    }

    &__reset-filters {
        @include karbonMedium();

        font-size: rem(12px);
        line-height: rem(14px);
        letter-spacing: 2px;
        color: $blue;
        text-decoration: underline;
        text-transform: uppercase;
        margin-bottom: 24px;

        &.hidden {
            display: none;
        }
    }

    &__apply-filters {
        max-width: 270px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: rem(30px);
        padding-bottom: rem(50px);

        .elem-cta {
            width: 100%;
            margin-bottom: rem(20px);
        }

        @include respond-above(md) {

            .elem-cta {
                width: auto;
            }
            
            &.two-columns {
                max-width: 590px;
                margin-right: rem(-16px);
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;

                .elem-cta {
                    margin-right: rem(16px);
                }
            }
        }
    }
}
