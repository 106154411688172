.widget-products-detail-card {
    width: 100%;
    overflow: hidden;

    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        gap: 40px;
        align-self: stretch;
        border-radius: 12px;
        background-color: #FFFFFF;

        @include respond-above(md){
            flex-direction: row;

        }

       #{$edit_mode} & {
            padding: rem(40px);
       }

    }

    &__first-column {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        flex: 1 0 0;
        border: 1px solid #d9dadb;

         @include respond-above(md) {
            @include make-col(6);
        }


        @include respond-above(lg) {
            max-width: 448px;

        }
    }


    &__image-first-section {
        display: flex;
        height: 300px;
        padding: 16px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        flex: 1 0 0;
        @include respond-above(lg) {
            padding: 16px 40px;
        }

    }

    &__separator-section {
        width: calc(100% - 32px);
        border-top: 1px solid rgba(255, 255, 255, 0.30);
        align-self: center;
        @include respond-above(lg) {
            width: calc(100% - 48px);
        }

    }


    &__second-section {
        display: flex;
        padding: rem(16px);
        flex-direction: column;
        justify-content: space-between;
        height: 100%;


        @include respond-above(lg) {
            padding: rem(24px);
        }

    }

    &__row-second-section {
        display: flex;
        flex-direction: column;
        gap: 16px;


        @include respond-above(lg){
            flex-direction: row;
            gap: 24px;
        }
    }

    &__label {
        margin-bottom: rem(8px) !important;
        span {
            display: inline-block !important;

        }

        .text-white & .elem-label {
            color: $white !important;
        }
        .text-black & .elem-label {
            color: $black !important;
        }

    }


    &__description {
        display: flex;
        flex-direction: column;
        border-radius: 10px;

        @include respond-above(lg){
            flex: 1 1 0;
            min-width: 150px;
        }

    }

    &__second-column {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
    }

    &__row-first-section-right {
        display: flex;
        gap: 40px;
        align-self: stretch;
        margin-bottom: 24px;
    }

    &__description-right {
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;

        @include respond-above(md) {
            flex: unset;
        }

        @include respond-above(lg) {
            min-width: 150px;
        }
    }

    .elem-text {
        p {
            margin-bottom: 0;
        }

        h2 {
            @include respond-below(lg){
                font-size: 10px;
                line-height: 10px;
                letter-spacing: 0.2px;

            }
        }

        .elem-text--body1 {
            margin-bottom: rem(16px);
        }

        .elem-title__h4 {
            margin-bottom: 0 !important;
        }
    }


    &__separator-section-right{
        border-top: 1px solid rgba(0, 0, 0, 0.40);
        align-self: center;
        width: 100%;
        margin-bottom: 24px;

    }

    &__second-section-right {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }


    &__description-last-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
    }

}

