@mixin small-line {
    position: relative;

    &:before {
        content:'';
        width: 4px;
        height: 24px;
        position: absolute;
        // left: 0;
        top:3px;
        border-radius: 4px;

        @include respond-above(lg) {
            top: 8px;
            height: 28px;
        }
    }

    p {
        padding-left: 22px;
    }
}

.elem-text {
    $this-text: &;

    color: $black;

    &.p-t-def  {
        padding-top: 0 !important;
    }
    &.p-b-def  {
        padding-bottom: 0 !important;
    }

    .base-main__item > & {
        @include container();
        @include content-container();
    }

    .base-main__item .base-main__item > & {
        padding: 0 !important;
        max-width: none;
    }

    &--body1,
    &--body2 {
        display: block;
    }

    &--body1 {
        @include t-paragraph-1();

        @include respond-above(lg) {
            margin-bottom: rem(32px);
        }
    }

    &--body2 {
        @include t-paragraph-2();
    }

    &--foot-note {
        @include t-paragraph-3();
    }

    &--quote {
        @include t-h4-title();
    }

    &--delete {
        text-decoration: line-through;
    }

    &--gray {
        color: $darkGray;
    }

    a {
        color: $blue;

        @include mouse-only() {
            &:hover {
                color: $darkBlue;
            }
        }

        .panel-blue & {
            color: $white;
        }

        .panel-blue .elem-card & {
            color: $blue;
        }

    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        a {
            text-decoration: underline;
            display: inline-block;
        }
    }

    .elem-title__h1,
    .elem-title__h2,
    .elem-title__h3,
    .elem-title__h4,
    .elem-title__h5,
    .elem-title__h6 {
        display: block;

        .widget-hero--home & {
            display: inline-block;
        }
    }

    .elem-title__h1 {
        @include t-h1-title();

        margin-bottom: rem(40px);

        @include respond-above(lg) {
            margin-bottom: rem(48px);
        }
    }

    .elem-title__h2 {
        @include t-h2-title();

        margin-bottom: rem(40px);

        @include respond-above(lg) {
            margin-bottom: rem(48px);
        }
    }
    
    .elem-title__h3 {
        @include t-h3-title();

        margin-bottom: rem(32px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }

    .elem-title__h4 {
        @include t-h4-title();

        margin-bottom: rem(24px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }

    .elem-title__h5 {
        @include t-h5-title();

        margin-bottom: rem(24px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }

    .elem-title__h6  {
        @include t-h6-title();
        
        margin-bottom: rem(24px);

        @include respond-above(lg) {
            margin-bottom: rem(40px);
        }
    }


    p {
        margin-bottom: rem(24px);
    }

    b {
        @include karbonMedium();
        
        // font-weight: normal;
    }

    .panel-blue & {
        color: $white;

        hr {
            opacity: 0.5;
        }
    }

    &--link-email,
    &--link-arrow,
    &--external-link,
    &--link-phone {
        @include t-text-link();
        position: relative;
        display: inline-block;
        color: $blue;
        
        .panel-blue & {
            color: $white;
        }

        .panel-white .elem-card &,
        .panel-grey .elem-card &,
        .panel-light-grey .elem-card &,
        .panel-blue .elem-card & {
            color: $blue;
        }
       
    }

    &--link-email {
        padding-left: 24px;

        &:before {
            @include nexiIcon();
            content: $icon-email;
            position: absolute;
            left: 0;
            top: 1px;
            font-size: rem(11px);
        }
    }

    &--link-phone {
        padding-left: rem(19px);

        &:before {
            @include nexiIcon();
            content: $icon-phone;
            position: absolute;
            left: 0;
            top: 1px;
            font-size: rem(11px);
        }

    }

    &--link-arrow {
        padding-right: 24px;

        &:after {
            @include nexiIcon();
            content: $icon-arrow_right;
            position: absolute;
            right: 0px;
            top: 1px;
            font-size: rem(12px);
        }
    }

    &--external-link {
        padding-right: 20px;
        position: relative;
        display: inline-block;
        text-decoration: underline;
        text-transform: none;
        
        &:after {
            @include nexiIcon();
            content: $icon-external-link;
            position: absolute;
            right: 0px;
            top: -2px;
            font-size: rem(16px);
        }
    }

    #{$this-text}--link-arrow,
    #{$this-text}--link-email,
    #{$this-text}--link-phone
     {
        a {
            text-decoration: none;
        }
    }

    .text-black &,
    .text-white &,
    .text-gray &,
    .text-blue &,
    .text-lightBlue &,
    .text-lightGray & {
        color: inherit;

        &--link-arrow,
        &--external-link,
        &--link-email,
        &--link-phone {
            color: inherit;

            &:after {
                color: inherit;
            }
        }

        a {
            color: inherit;
        }
    }

    
    & + .elem-unordered-list {
        margin-top: 40px;
    }

    

    &#{$this-text}__line--white {
        @include small-line();

        &:before {
            background: $white;
        }
    }

    &#{$this-text}__line--blue {
        @include small-line();

        &:before {
            background: $blue;
        }
    }

    &#{$this-text}__line--lightblue {
        @include small-line();

        &:before {
            background: $azure;
        }
    }

    &#{$this-text}__line--gray {
        @include small-line();
        
        &:before {
            background: $gray;
        }
    }

    .mobile-hidden {
        @include respond-below(md) {
            display: none;
        }
    }



    //* LISTS STYLES */
    ul {
        list-style: disc;
        //list-style: none;
        //margin: 0;
        //padding: 0;
        padding-left: 0;
        margin-left: 23px;
    }

    ul ul {
        list-style: circle;
        padding-left: 0;
        margin: 8px 0 0 24px;
        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ul ul ul {
        list-style-type: square;
        padding-left: 0;
        margin: 8px 0 0 24px;
        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ul li {
        font-size: 1.5rem;
        line-height: 2rem;
        @include respond-below(lg) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    ul ol {
        margin-top: 8px;
    }

    ol {
        padding-left: 0;
        margin-left: 23px;
        list-style-type: decimal;
        @include respond-below(lg) {
            margin-left: px;
        }
    }

    ol ol {
        list-style-type: lower-alpha;
        padding-left: 0;
        margin: 8px 0 0 24px;
        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ol ol ol {
        list-style-type: square;
        padding-left: 0;
        margin: 0 0 8px 24px;
        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ol ol ul {
        list-style-type: square;
        padding-left: 0;
        margin: 8px 0 0 24px;
        @include respond-below(lg) {
            margin-left: 16px;
        }
    }

    ol li,
    ul li {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 8px;

        #{$this-text}--body1 {
            margin-bottom: 0;
        } 

        @include respond-below(lg) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    ol,
    ul {
        p {
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 16px 0 16px 0;
            @include respond-below(lg) {
                font-size: 1.125rem;
                line-height: 1.5rem;
            }
        }

        span {
            margin-bottom: 0 !important;
        }
    }
}
