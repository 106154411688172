.base-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: $white;

    &__columns {
        @include karbonMedium();

        position: relative;
        display: flex;
        flex-direction: column;
        gap: rem(32px);
        width: 100%;
        min-height: $footer-height;
        padding: rem(32px) rem(16px);
        background: $footer-nav-bg;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: rem(32px);
            right: rem(32px);
            border-bottom: 1px solid $white;
        }

        .icon-external-link {
            padding-left: rem(8px);
        }

        @include respond-above(lg) {
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            padding: rem(40px) rem(80px) rem(14px) rem(80px);
            padding-right: rem(89px);

            &::before {
                left: rem(80px);
                right: rem(80px);
            }
        }

        .base-footer__columns-title-hidden {
            margin-top: rem(-32px);

            @include respond-above(lg) {
                margin-top: 0;
            }
        }

        &-title {
            @include karbonMedium();
            display: block;
            font-size: rem(18px);
            margin-bottom: rem(5px);
            opacity: .5;

            .base-footer__columns-title-hidden & {
                display: none;
            }

            @include respond-above(lg) {
                margin-bottom: rem(14px);
                .base-footer__columns-title-hidden & {
                    display: block;
                }
            }
        }

        &-links {
            display: flex;
            flex-direction: column;
            gap: rem(23px);
            padding: 0 rem(16px);

            & li {
                font-size: rem(16px);
                line-height: rem(18px);
                padding: rem(9px) 0;

                /*@include respond-below(lg) {
                    font-size: rem(16px);
                }*/

                &:focus,
                &:active {
                    text-decoration: underline;
                }

                @include mouse-only() {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            @include respond-above(lg) {
                flex-direction: row;
                gap: rem(64px);
            }

            @include respond-above(xl) {
                gap: rem(96px);
            }
        }

        &-contacts {
            padding: 0 rem(16px);

            &-title {
                @include karbonMedium();
                display: block;
                font-size: rem(18px);
                margin-bottom: rem(24px);
                opacity: .5;
            }

            &-infos {
                @include karbonRegular();
                font-size: rem(16px);
                margin-bottom: rem(16px);
                line-height: rem(17px);
            }

            &-phone,
            &-email {
                @include karbonMedium();
                display: flex;
                align-items: center;
                gap: rem(8px);
                font-size: rem(16px);
                margin-bottom: rem(16px);
                line-height: rem(18px);

                & .icon-phone {
                    font-size: rem(16px);
                }

                & .icon-email {
                    font-size: rem(12px);
                }
            }
        }
    }

    &__nav {
        width: 100%;
        background: $footer-nav-bg;
    }

    &__nav-items {
        @include karbonRegular();

        display: flex;
        width: 100%;
        min-height: $footer-height;
        padding: rem(24px) rem(16px) rem(32px) rem(16px);
        flex-direction: column;

        @include respond-above(lg) {
            justify-content: left;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 rem(80px);
            padding-right: rem(89px);
        }
    }

    &__nav-item {
        padding: rem(18px) rem(11px) rem(21px) rem(16px);

        @include respond-above(lg) {
            padding: rem(28px) rem(16px) rem(30px) rem(16px);

            &:last-child {
                margin-right: - rem(18px);
            }
        }
    }

    &__nav-link {
        display: flex;
        font-size: rem(14px);
        line-height: rem(15px);
        letter-spacing: 1px;

        &:focus {
            text-decoration: underline;
        }

        /*@include respond-above(lg) {
            font-size: rem(14px);
        }*/

        @include mouse-only() {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__secondary {
        background: $footer-bg;
        display: flex;
        min-height: $footer-height;
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        //padding: rem(32px) rem(20px) rem(70px);
        padding: rem(32px) rem(20px) rem(136px);

        @include respond-above(lg) {
            //padding: rem(15px) rem(72px) rem(15px) rem(80px);
            padding: rem(15px) rem(90px) rem(15px);
            flex-direction: row;
            align-items: center;
        }
    }

    &__right-block {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        @include respond-above(lg) {
            justify-content: space-between;
            width: auto;
        }
    }

    &__legal-text {
        @include karbonRegular();
        padding: rem(2px) rem(8px);
        margin-bottom: rem(32px);
        font-size: rem(16px);
        line-height: rem(20px);

        @include respond-above(lg) {
            margin: 0;
            font-size: rem(14px);
            line-height: rem(16px);
            padding-left: 0;
        }
    }

    &__social-items {
        display: flex;
        width: 100%;
        margin-right: 30px;
        padding-left: rem(5px);

        @include respond-above(lg) {
            width: auto;
        }
    }

    &__social-item {
        margin-right: rem(24px);

        @include respond-above(lg) {
            margin-right: rem(25px);
        }

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;

            em {
                font-size: rem(32px);

                @include respond-above(lg) {
                    font-size: rem(24px);
                }
            }

            @include mouse-only() {

                &:hover {

                    em:before {
                        color: $azure;
                    }

                    .icon-facebook_circle:before {
                        color: #4664A3;
                    }

                    .icon-linkedin_circle:before {
                        color: #007FC7;
                    }

                    .icon-xing_circle:before {
                        color: #b0d402;
                    }

                    .icon-youtube_circle:before {
                        color: #FF0000;
                    }

                    .icon-twitter_circle:before {
                        color: #314351;
                    }

                    .icon-instagram_circle:before {
                        color: #ff0069;
                    }

                }
            }
        }
    }

    &__lang-btn {
        @extend %btn;
        @include karbonMedium();

        font-size: rem(14px);
        text-transform: uppercase;
        color: $white;
        border: 1px solid $white;
        border-radius: 30px;
        min-width: rem(80px);
        margin-top: 3px;
        white-space: nowrap;
        letter-spacing: 1px;
        width: auto;

        .icon-user {
            font-size: rem(16px);
            margin-right: 10px;
        }

        @include respond-above(md) {
            margin-top: 0;
        }

        @include respond-above(lg) {
            font-size: rem(16px);
            padding: 11px 14px;
            //margin-right: rem(28px);
        }

        @include mouse-only() {
            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}