//
//  FONTS
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin karbonBold {
    // font-family: KarbonBold, NexiSansProBold, sans-serif;
    font-family: NexiSans, NexiSansPro, sans-serif;
    font-weight: 700;

    [lang=el] & {
        // font-family: NexiSansProBold, sans-serif;
        font-family: NexiSansPro, sans-serif;
    }
}

@mixin karbonLight {
    // font-family: KarbonLight, NexiSansProLight, sans-serif;
    font-family: NexiSans, NexiSansPro, sans-serif;
    font-weight: 300;

    [lang=el] & {
        // font-family: NexiSansProLight, sans-serif;
        font-family: NexiSansPro, sans-serif;
    }
}

@mixin karbonMedium {
    // font-family: KarbonMedium, NexiSansProMedium, sans-serif;
    font-family: NexiSans, NexiSansPro, sans-serif;
    font-weight: 500;

    [lang=el] & {
        // font-family: NexiSansProMedium, sans-serif;
        font-family: NexiSansPro, sans-serif;
    }
}

@mixin karbonRegular {
    // font-family: KarbonRegular, NexiSansProRegular, sans-serif;
    font-family: NexiSans, NexiSansPro, sans-serif;
    font-weight: 400;

    &[lang=el],
    [lang=el] & {
        // font-family: NexiSansProRegular, sans-serif;
        font-family: NexiSansPro, sans-serif;
    }
}

@mixin karbonSemibold {
    // font-family: KarbonSemibold, NexiSansProSemiBold, sans-serif;
    font-family: NexiSans, NexiSansPro, sans-serif;
    font-weight: 600;

    [lang=el] & {
        // font-family: NexiSansProSemiBold, sans-serif;
        font-family: NexiSansPro, sans-serif;
    }
}

@mixin karbonThin {
    // font-family: KarbonThin, NexiSansProThin, sans-serif;
    font-family: NexiSans, NexiSansPro, sans-serif;
    font-weight: 200;

    [lang=el] & {
        // font-family: NexiSansProThin, sans-serif;
        font-family: NexiSansPro, sans-serif;
    }
}

@mixin nexiIcon {
    font-family: '#{$nexi-iconfont-family}';
}

@mixin nexiCondensedSemibold {
    font-family: NexiCondensedSemibold;
}

@mixin nexiNumber {
    font-family: NexiNumberMono;
}

@mixin nexiNumberSemibold {
    font-family: NexiNumberMonoSemibold;
}

@mixin nexiNumberThin {
    font-family: NexiNumberMonoThin;
}

//
//  STYLES
//––––––––––––––––––––––––––––––––––––––––––––––––––

%font-txt {
    font-size: rem(14px);
    line-height: rem(22px);
}

@mixin t-h1-title {
    font-size: rem(48px);
    line-height: rem(52px);
    letter-spacing: -1px;
    @include respond-above(lg) {
        font-size: rem(88px);
        line-height: rem(94px);
        letter-spacing: -2.5px;
    }
}

@mixin t-h2-title {
    font-size: rem(40px);
    line-height: rem(48px);
    letter-spacing: -1px;
    @include respond-above(lg) {
        font-size: rem(64px);
        line-height: rem(72px);
        letter-spacing: -2.5px;
    }
}

@mixin t-h3-title {
    font-size: rem(32px);
    line-height: rem(40px);
    letter-spacing: -1px;
    @include respond-above(lg) {
        font-size: rem(48px);
        line-height: rem(56px);
        letter-spacing: -1.5px;
    }
}

@mixin t-h4-title {
    font-size: rem(24px);
    line-height: rem(32px);
    letter-spacing: -1px;
    @include respond-above(lg) {
        font-size: rem(40px);
        line-height: rem(48px);
        letter-spacing: -1.5px;
    }
}

@mixin t-h5-title {
    font-size: rem(20px);
    line-height: rem(26px);
    letter-spacing: -0.5px;
    @include respond-above(lg) {
        font-size: rem(32px);
        line-height: rem(40px);
        letter-spacing: -1px;
    }
}

@mixin t-h6-title {
    font-size: rem(20px);
    line-height: rem(26px);
    letter-spacing: -0.5px;

    @include respond-above(lg) {
        font-size: rem(28px);
        line-height: rem(34px);
    }
}

@mixin t-paragraph-1 {
    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: -0.5px;
    @include respond-above(lg) {
        font-size: rem(24px);
        line-height: rem(32px);
    }
}

@mixin t-paragraph-2 {
    font-size: rem(16px);
    line-height: rem(22px);
    @include respond-above(lg) {
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: -0.5px;
    }
}

@mixin t-paragraph-3 {
    font-size: rem(12px);
    line-height: rem(18px);
    @include respond-above(lg) {
        font-size: rem(14px);
        line-height: rem(18px);
    }
}

@mixin t-label-big {
    @include karbonMedium();
    text-transform: uppercase;
    font-size: rem(14px);
    line-height: rem(14px);
    letter-spacing: 2px;
    @include respond-above(lg) {
        font-size: rem(18px);
        line-height: rem(24px);
    }
}

@mixin t-label-small {
    @include karbonMedium();
    text-transform: uppercase;
    font-size: rem(10px);
    line-height: rem(10px);
    letter-spacing: 2px;
    @include respond-above(lg) {
        font-size: rem(14px);
        line-height: rem(18px);
    }
}

@mixin t-text-link {
    @include karbonMedium();

    display: flex;
    text-transform: uppercase;
    font-size: rem(16px);
    line-height: rem(17px);
    letter-spacing: 1px;
    align-items: center;
    text-decoration: none;
}

@mixin t-numbers-2 {
    font-size: rem(72px);
    line-height: rem(77px);
    letter-spacing: -2px;
}