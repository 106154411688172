.layout-cards {
    $this: &;
    width: 100%;

    &__title {
        padding-top: 56px;

        h1,h2,h3,h4,h5,h6,p,span {
            font-size: rem(28px);
            line-height: rem(34px);
            color: $black;

            @include respond-above(lg) {
                font-size: rem(40px);
                line-height: rem(40px);
                letter-spacing: -1px;
            }
        }

        @include respond-above(lg) {
            padding-bottom: 24px;
        }
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__row {
        @include make-row();

        @include respond-above(lg) {
            width: auto;
        }
    }

    .widget-hightlight &,
    &.news-cards,
    &.author-card {

        #{$this}__container {
            display: flex;
            flex-wrap: wrap;
            padding-right: 0 !important;
            padding-left: 0 !important;
            max-width: none;
        }
    }
}

.lp-dob {
    .layout-cards {
        &.card__width--100 {
            .layout-cards__container {
                @include respond-above(xl) {
                    padding-left: 10vw !important;
                    padding-right: 10vw !important;
                    max-width: calc( #{$content-container-max-width} + 20vw);
                }
            }
        }
        .layout-cards__container {
            @include respond-above(xl) {
                padding-left: 5vw !important;
                padding-right: 5vw !important;
                max-width: calc( #{$content-container-max-width} + 20vw);
            }
        }
    }
}
