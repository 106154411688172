.elem-cta {

    &__external-link {
        @include t-paragraph-1();

        display: inline-block;

        span {
            text-decoration: underline;
        }

        [class*="icon"] {
            margin-left: 5px;

            @include respond-above(lg) {
                font-size: rem(20px);
            }
        }

    }

    .is-mobile-hidden {
        @include respond-below(lg) {
            display: none;
        }
    }
}

.is-desktop-hidden.elem-cta {
    @include respond-above(lg) {
        display: none;
    }
}