.elem-container-label-card {
    
    &.reverse {

        .elem-container-label-card__content {
            flex-direction: column-reverse;

            @include respond-above(lg) {
                flex-direction: row-reverse;
            }
        }

        .elem-container-label-card__text {
            padding-top: rem(32px);
            padding-bottom: 0;

            @include respond-above(lg) {
                padding-top: 0;
            }
        }
    }

    &.reverse-mobile {

        .elem-container-label-card__content {
            @include respond-below(lg) {
                flex-direction: column-reverse;
            }
        }

        .elem-container-label-card__text {
            padding-top: rem(32px);
            padding-bottom: 0;

            @include respond-above(lg) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
  

    &.reverse-mobile.reverse {
        .elem-container-label-card__content {
            @include respond-below(lg) {
                flex-direction: column;
            }
        }

        .elem-container-label-card__text {
            padding-top: 0;
            padding-bottom: rem(8px);

            @include respond-above(lg) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }


    &__container {
        @include container();
        @include content-container();
    }

    &__content {
        @include make-row();
    }

    &__card {
        @include make-col-ready();
        @include make-col(12);

        @include respond-above(lg) {
            @include make-col(4);
        }
    }

    &__text {
        @include make-col-ready();
        @include make-col(12);
        
        padding-bottom: rem(8px);

        @include respond-above(lg) {
            @include make-col(8);
            
            padding-right: rem(43px);

            .reverse & {
                padding: 0 rem(43px);
            }
            
        }
    }

    &__content-title {
        @include karbonMedium();
        padding-bottom: rem(24px);

        @include respond-above(lg) {
            padding-bottom: rem(32px);
        }
    }

}