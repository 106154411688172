.widget-share {
    padding-top: rem(34px);

    &__buttons {
        display: flex;

        @include respond-above(lg) {
            margin-right: -160px;
        }
    }

    &__share-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        margin-right: 24px;
        background-color: $black;
        color: $white;

        em {
            pointer-events: none;
            font-size: 14px;

            &.icon-email {
                font-size: 10px;
            }
        }

        &:last-child {
            margin: 0;
        }

        &.hidden {
            display: none;
        }

        @include mouse-only() {
            opacity: .6;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__title {
        @include karbonMedium();

        text-transform: uppercase;
        font-size: rem(10px);
        line-height: rem(10px);
        letter-spacing: 2px;
        color: $darkGray;
        margin-bottom: 16px;
    }

    @include respond-above(lg) {
        padding-top: rem(24px);

        .layout-news-footer__column:last-child & {
            padding-top: rem(32px);
    
            &__title {
                text-align: right;
            }
    
            &__buttons {
                margin: 0;
                justify-content: flex-end;
            }
        }
    }
}