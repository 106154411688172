@use 'sass:math';

$nojs: '.no-js';

@mixin navbarShadow {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

@mixin scrolledBg {
    @include navbarShadow();
    // background: $header-bg-active;
    background-color: $white;

    @supports not (backdrop-filter: #{$ui-blur}) {
        background: $header-bg-alt-active;
    }
}

@mixin underline($spacing: false) {
    text-decoration: underline;
    // span {
    //     box-shadow: 0 2px 0 0 $white;
        
    //     @if ($spacing) {
    //         padding: 4px 0;
    //     } @else {
    //         padding: 0;
    //     }
    // }
    .base-header--white &,
    .base-header--dark.scrolled &,
    .base-header--dark.nav--opened &,
    .base-header--dark .flyout--opened &,
    .base-header--dark .flyout--active &,
    .base-header--dark .base-header__menu-checkbox:checked + .base-header__nav & {
        color: $blue;
    }

}

@mixin buttonArrow() {
    content: $icon-caret_right;
    display: inline-block;
    font-family: '#{$nexi-iconfont-family}';
    font-size: rem(16px);
    vertical-align: middle;
    position: absolute;
    right: -5px;
    width: auto;
    height: auto;
    transition: right .2s ease-out;
}

%firstLevelMenuItem {
    display: flex;
    align-items: center;
}



.base-header {
    $this: &;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: setZ(header);
    transition: top 0.3s ease-out;

    @mixin flyoutOpening() {
        max-height: 100vh;
        animation-name: flyoutDown;
        animation-duration: .3s;
        animation-timing-function: ease-out;
        
        #{$this}__flyout-items {
            opacity: 1;
        }
    }

    @mixin flyoutClosing() {
        animation-name: flyoutUp;
        animation-duration: .4s;
        animation-timing-function: ease-in;
    }

    &.nav--opened {
        bottom: 0;
    }

    &.scrolled-hidden:not(.nav--opened) {
        top: - 184px;
    }

    &.with-stickybar{
        top: 0px;
        @include respond-below(lg) {
    
        top: 0px;
        position: fixed;
            
        }

        &.scrolled-hidden:not(.nav--opened) {
            top: auto;

            .base-header__nav {
                display: none;
            }

            .base-header__bg {
                box-shadow: unset;
            }
        }
    }
    
    &.no-sticky{
        top:0px;

    }

    &.no-sticky.scrolled-hidden:not(.nav--opened) {
        top: - 112px;
        @include respond-above(lg){
        top: - 72px;}
        
    }
    &--sticky-menu {
        @include respond-above(lg) {
            
            &.scrolled-hidden{
                top: - $header-height;
            }
        }
    }
    &.no-sticky.scrolled .sticky-bar{
        top: - 112px;
        position: fixed;
        @include respond-above(lg){
        top: - 72px;}
        
    }

    &__bg {
        // backdrop-filter: $ui-blur;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: calc(100% + 1px);
        //height: -webkit-fill-available;
        z-index: -1;
        //transition: opacity .3s ease-out;
        transition: background-color .3s ease-out;
        pointer-events: none;

        .menu-open & {
            @include navbarShadow();
        }

        #{$this}--white & {
            @include navbarShadow();
            background-color: $white;
        }

        #{$this}--dark & {
            background: none;
        }

        .nav--opened &,
        #{$this}.scrolled &,
        #{$nojs} #{$this}__menu-checkbox:checked ~ & {
            @include scrolledBg();
        }

        @include mouse-only() {

            #{$this}:hover & {
                @include scrolledBg();
            }
        }

        @include respond-above(lg) {
            top: 0;
            height: 100%;
            background: none;
            z-index: 0;

            #{$this}__nav.flyout--active + & {
                background: $white;
                transition: background-color .1s ease-out;
            }

            #{$this}__nav.flyout--closing + & {
                @include scrolledBg();
                transition: background-color .4s ease-out .2s;
            }
        }

        #{$wcmmode} & {
            background: $header-bg-active;
        }
    }

    &__nav {
        $this-nav: &;
        @include percentage-container();

        position: relative;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        height: $header-height;
        //overflow: hidden; // be careful for language menu dropdown
        justify-content: space-between;
        align-items: center;
        padding: $header-mobile-padding;

        &.no-anim {

            #{$this}__flyout--vertical {
                animation-name: none !important;
            }
        }

        // DESKTOP EVENT
        &.flyout--active {

            @include respond-above(lg) {

                ul > li.flyout--opened {

                    #{$this}__flyout--vertical {
                        @include flyoutOpening();
                    }
                }

                ul > li.flyout--closing {
                    
                    #{$this}__flyout--vertical {
                        @include flyoutClosing();
                    }
                }
            }
        }
        
        // MOBILE EVENT
        .nav--opened &,
        #{$nojs} #{$this}__menu-checkbox:checked + & {

            @include respond-below(lg) {
                height: 100vh;
                height: -webkit-fill-available;
                flex-direction: column;
                padding: 0;
                transition: left .3s ease-out;

                #{$this}__menu-btn {
                
                    .close {
                        display: block;
                    }
        
                    .open {
                        display: none;
                    }
                }
    
                #{$this}__top {
                    position: fixed;
                    padding: $header-mobile-padding;
                }
    
                #{$this-nav}-items {
                    //display: flex;
                    width: 100%;
                    //height: 100%;
                    height: calc(100vh - #{$header-mobile-tools-height} - #{$header-height});
                    padding: $header-mobile-padding;
                    padding-top: 32px;
                    padding-bottom: 24px;
                    margin-top: $header-height;
                    margin-bottom: $header-mobile-tools-height;
                    flex-direction: column;
                    //overflow-y: auto;
                    overflow: auto;
                    opacity: 1;
                }
    
                #{$this-nav}-tools {
                    display: flex;
                    justify-content: space-between;
                    position: fixed;
                    bottom: -1px;   // iOS Safari workaround
                    width: 99.99%;  // iOS Safari workaround
                    min-height: $header-mobile-tools-height;
                    height: $header-mobile-tools-height;
                    padding: $header-mobile-padding;
                    background: $header-mobile-tools-bg;
                    opacity: 1;
                }

                #{$this}__login-cta {
                    color: $white;
                    border: 0;
                    padding: 11px 0;
                    font-size: rem(14px);
                    line-height: rem(14px);
                }
            }

            @include respond-below(md, landscape) {
                height: -webkit-fill-available;
                
                #{$this-nav}-items {
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-bottom: math.div($header-mobile-tools-height, 2);
                }

                #{$this-nav}-tools {
                    min-height: math.div($header-mobile-tools-height, 2);
                    height: math.div($header-mobile-tools-height, 2);
                }
            }
        }

        @include respond-above(lg) {
            max-width: 89.88vw;
            position: static;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }

    &__top {
        display: flex;
        min-height: $header-height;
        height: $header-height;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        z-index: 10;

        @include respond-above(lg) {
            min-height: 0;
            height: auto;
            width: auto;
            padding-right: rem(34px);
        }
    }

    &__logo {
        max-height: 29px;
        //padding-top: 2px;

        a {
            display: inline-block;

            @include mouse-only() {
                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }

        img {
            max-width: 100%;
            max-height: 29px;
            height: auto;
        }

        @include respond-above(lg) {
            //@include make-col(3);
            min-width: 95px;
        }

        #{$this}--white &,
        #{$this}--dark .flyout--active &,
        #{$this}--dark.scrolled &,
        #{$this}--dark.nav--opened &,
        #{$nojs} #{$this}--dark #{$this}__menu-checkbox:checked + #{$this}__nav & {
            #{$this}__logo-blue {
                display: block;
            }

            #{$this}__logo-white {
                display: none;
            }
        }

        #{$this}--dark & {
            #{$this}__logo-blue {
                display: none;
            }

            #{$this}__logo-white {
                display: block;
            }
        }

        @include mouse-only() {
            #{$this}--dark:hover & {
                #{$this}__logo-blue {
                    display: block;
                }
    
                #{$this}__logo-white {
                    display: none;
                }
            }
        }

    }

    input[type=checkbox],
    input[type=radio] {
        display: none;
        position: absolute;
    }

    &__menu-btn,
    &__menu-close-btn {
        padding: rem(20px) 0 rem(20px);
        cursor: pointer;

        span[class*=icon-] {
            font-size: rem(14px);
            font-weight: 600;
        }
    }

    &__menu-btn {

        .close {
            display: none;
        }
        
        @include respond-above(lg) {
            display: none;
        }

        #{$this}--white &,
        // #{$this}--dark:hover &,
        #{$this}--dark.scrolled &,
        #{$this}--dark.nav--opened &,
        #{$nojs} #{$this}--dark #{$this}__menu-checkbox:checked + #{$this}__nav & {
            color: $black;
        }

        #{$this}--dark & {
            color: $white;
        }
    }

    &__menu-close-btn {
        padding: rem(28px) 0 rem(28px);

        .text {
            display: none;
        }
        
        @include respond-above(lg) {
            display: flex;
            align-items: center;
            //padding: rem(7px) rem(4px) rem(15px) rem(15px);
            padding: 10px;
            padding-right: 0;

            #{$nojs} & {
                display: none;
            }

            .text {
                @include karbonMedium();

                display: inline-block;
                font-size: rem(16px);
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-right: 12px;
            }
        }
    }

    &__nav-items {
        //display: none;
        display: flex;
        height: 0;
        padding: 0;
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        border: none;
        opacity: 0;
        transition: opacity .3s ease-out;
        //touch-action: none; // iOS Safari scroll workaround

        @include respond-above(lg) {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0;
            opacity: 1;
            overflow: hidden;
        }
    }

    &__nav-item {
        @extend %firstLevelMenuItem;

        //word-wrap: break-word;
        //overflow-wrap: break-word;

        &.has-submenu {

            > #{$this}__nav-button::after {
                @include buttonArrow();

                top: 50%;
                transform: translateY(-50%);
            }

            @include respond-above(lg) {

                > #{$this}__nav-button::after {
                    display: none;
                }
            }

            #{$nojs} & {
                display: flex;

                @include respond-above(lg) {
                    height: 100%;
                }
            }
        }

        &.active {

            > #{$this}__nav-link {
                pointer-events: none;
            }

            > #{$this}__nav-button,
            > #{$this}__nav-link {
                @include underline();
            }
        }
    }

    &__nav-button,
    &__nav-link {
        @include karbonMedium();

        display: flex;
        width: 100%;
        height: rem(72px);
        position: relative;
        align-items: center;
        font-size: rem(20px);
        line-height: rem(20px);
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;

        .second-level &,
        .third-level & {
            height: rem(62px);

            @include respond-above(lg) {
                height: auto;
            }
        }

        #{$this}--dark & {
            color: $white;

            em:before {
                color: $white;
            }
        }

        #{$this}--white &,
        #{$this}--dark:hover &,
        #{$this}--dark.scrolled &,
        #{$this}--dark.nav--opened &,
        #{$this}--dark .flyout--opened &,
        #{$this}--dark .flyout--active &,
        #{$nojs} #{$this}--dark #{$this}__menu-checkbox:checked + #{$this}__nav & {
            color: $black;

            em:before {
                color: $black;
            }
        }

        &:focus,
        &:active {
            outline: none;
        }

        &.active {
            color: $blue;
            span {
                @include underline(true);
            }
        }

        @include mouse-only() {
            &:hover {
                color: $blue;
                span {
                    @include underline(true);
                }
            }
        }

        @include respond-above(lg) {
            position: static;
            width: auto;
            padding: 0 rem(16px);
            font-size: rem(16px);
            line-height: rem(17px);
            letter-spacing: 1px;
            z-index: 1;
        }
    }

    &__nav-link {
        width: auto;
    }

    &__nav-button {

        em[class*=icon-] {
            display: none;
            position: absolute;
            top: calc(50% - 7px);
            right: rem(16px);
            transition: transform 250ms ease-out,
                        top 200ms ease-out;
        }
        
        .has-submenu & {
            
            @include respond-above(lg) {
                position: relative;
                padding-right: rem(35px);

                em[class*=icon-] {
                    display: inline-block;
                }
            }
        }

        .flyout--opened & {

            em[class*=icon-] {
                transform: rotate(180deg);
                top: calc(50% - 8px);
            }
        }
    }

    &__flyout {
        display: none;
        position: absolute;
        top: 0;
        height: 100vh;
        height: -webkit-fill-available;
        transition: left .3s ease-out;

        @include respond-above(lg) {
            display: flex;
            top: 0;
            left: 0;
            height: auto;
            padding: $header-mobile-padding;    // ?????????
            padding-top: 24px;
            padding-bottom: 24px;
            overflow: hidden;
        }

        &--vertical {
            left: 100%;
            width: 100%;

            @include respond-above(lg) {
                @include scrolledBg();
                //backdrop-filter: $ui-blur;

                left: 0;
                //height: 0;
                //max-height: $header-height;
                max-height: 0;
                width: 100%;
                z-index: 0;
                padding: 0;
                //transition: opacity .3s ease-out,
                //            max-height .4s ease-out;

                /*&:hover {
                    @include hoverOpen();
                }*/

                #{$this}__flyout-container > div:not(.base-header__flyout-tools) {
                    width: 100%;
                }

                .flyout--opened {
                    #{$this}__flyout-container > ul {
                        margin-top: $header-height;
                    }
                }
                
                .nav-scrollable & {
                    overflow-y: auto;
                }
            }

            #{$nojs} #{$this}__flyout-checkbox:checked ~ & {
                @include flyoutOpening();
                //z-index: 50;
            }
        }

        &--horizontal {
            left: 100%;
            //height: 100%;
            width: 100%;
            flex-direction: column;

            ul {

                #{$this}__flyout-item {
                    display: inline-block;
                    height: auto;
                    width: 100%;
                }
            }

            @include respond-above(lg) {
                //display: none;
                top: 0;
                //left: 24%;
                height: 100%;
                min-height: calc(#{$preview-height} + 32px);
                width: 100%;
                //max-width: 75%;
                //padding: 0 rem(10px) 0 rem(32px);
                z-index: 0;

                position: relative;
                left: 0;
                display: flex;
                max-width: none;
                padding: 0 0 rem(28px) 0;

                ul {
                    flex-direction: column;
                    flex-wrap: wrap;
                    margin-top: 0;

                    #{$this}__flyout-item {
                        width: auto;
                    }
                }

                #{$this}__flyout-tools {
                    display: none;
                }

                .twin-columns & {
                    position: static;
                    min-height: auto;
                }

                .is-related-menu & {
                    min-height: auto;
                    padding: 0 !important;
                }

                .is-card-menu & {
                    //@include make-row();
                    
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: rem(20px);
                }
            }

            #{$nojs} #{$this}__flyout-checkbox:checked ~ & {
                z-index: 101;
            }
        }

        @include respond-below(lg) {

            #{$nojs} & {
                display: flex;
                opacity: 0;
            }

            #{$nojs} #{$this}__flyout-checkbox:checked ~ & {
                opacity: 1;
                left: 0;
                background-color: white;
            }
        }
    }

    &__flyout-container {
        width: 100%;
        height: 100%;

        @include respond-above(lg) {
            // backdrop-filter: $ui-blur;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 auto;
            //padding: 0 5.56vw;
            padding: 0 4.56vw;
        }

        @include respond-above(xxl) {
            padding: 0 10vw;
        }
    }

    &__flyout-items {
        //@include percentage-container();

        display: flex;
        flex-direction: column;
        //position: relative;
        // height: calc(100% - #{$header-height});
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: $header-height;
        padding: $header-mobile-padding;
        padding-top: 24px;
        padding-bottom: 20px;

        @include respond-above(lg) {
            display: flex;
            position: relative;
            opacity: 0;
            padding: 0 0 rem(27px) 0;
            // height: 100%;
            height: auto;
            width: 100%;
            transition: opacity .3s ease-out .2s;

            &.highlight {

                > li > #{$this}__flyout-link:not(.highlight),
                > li > #{$this}__flyout-button:not(.highlight) {
                    opacity: .4;
                }
            }

            &.second-level {
                display: block;
                min-height: rem(445px);
                column-count: 2;
                column-fill: auto;
                padding-right: 50%;
                padding-top: rem(64px);
                padding-bottom: rem(190px);

                &.column-balance {
                    column-fill: balance;
                }

                &.twin-columns {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    li.has-submenu {

                        &:nth-child(1),
                        &:nth-child(2) {
                            width: 50%;

                            #{$this}__flyout-button {
                                align-items: flex-start;
                                //width: 25%;
                                min-height: rem(60px);
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                &.card-layout {
                    display: flex;
                    padding-right: 0;
                    padding-bottom: 0;

                    .is-related-menu {
                        position: static;
                        padding-bottom: 30px;
                    }
                }
            }

            &.third-level {
                overflow: visible;
                height: auto;

                .twin-columns & {
                    position: static;
                }

                .is-card-menu & {
                    @include make-row();
                    
                    margin-left: 0;
                    width: calc(50% + 8px);
                    flex-direction: row;
                    justify-content: flex-end;
                    padding-bottom: 0;
                }
            }

            .is-related-menu & {
                flex-direction: row;
                padding: 0;

                > li {
                    padding-right: rem(50px);
                }
            }

            @include ie-only() {
                height: auto;
                //min-height: 100%;
            }
        }

        @include respond-below(lg) {

            @media (orientation: landscape) {
                height: calc(100% - #{$header-height} - 15px);
                padding-top: 0;
                padding-bottom: 0;
                //margin-bottom: #{$header-mobile-tools-height / 2};
            }
        }
    }

    &__flyout-bottom-cta {
        order: 10;
        padding: 0 rem(25px);

        &:is(li) {  // applicable only within an UL
            padding: 0;
            padding-top: rem(22px);
        }

        a {
            font-size: rem(16px);
            line-height: rem(17px);
            letter-spacing: 1px;
        }

        @include respond-above(lg) {
            position: absolute;
            left: 0;
            bottom: rem(150px);
            order: inherit;
            //width: 100%;
            padding: rem(31px) 0 0 0;

            .is-card-menu & {
                display: none;
            }
        }
    }

    &__flyout-preview {
        display: none;
        position: absolute;
        right: 0;
        top: rem(64px);

        @include respond-above(lg) {
            @include make-col(6);

            width: 100%;
            //height: 30vh;
            height: 100%;
            max-height: $preview-height;
            opacity: 0;
            transition: opacity .4s ease-out;
            z-index: setZ(bottomless-pit);
            pointer-events: none;

            .flyout--opened & {
                display: flex;
            }
            
            #{$nojs} #{$this}__flyout-item.has-preview:hover &,
            .has-preview.flyout--opened & {
                opacity: 1;
                justify-content: flex-end;
                z-index: setZ(default);
                pointer-events: all;
            }
        }
    }

    &__preview-img {
        display: none;
        width: 50%;
        justify-content: center;
        padding-right: rem(16px);
        //margin-right: rem(16px);

        img {
            width: 100%;
            height: auto;
            max-width: 416px;
            max-height: $preview-height;
            min-height: auto;
            margin-left: auto;
            border-radius: 8px;
        }

        @include respond-above(lg) {
            display: flex;
        }
    }

    &__preview-content {
        display: flex;
        flex-direction: column;
        width: 50%;
        //margin-top: rem(11px);
        //justify-content: space-between;
    }

    &__preview-label {
        @include t-label-big();

        padding-bottom: 8px;
    }

    &__preview-title {
        @include karbonMedium();
        //@include t-h4-title();

        font-size: 40px;
        line-height: 44px;
        letter-spacing: -1.5px;
    }

    &__preview-subtitle {
        @include t-paragraph-1();
        margin-top: 16px;
    }

    &__preview-bottom {
        //margin-top: auto;
        //padding-bottom: rem(48px);
    }

    &__preview-cta,
    &__preview-link {
        width: auto;
        margin-top: rem(20px);

        .elem-text {
            span, p {
                margin-bottom: 0 !important;
            }
        }
    }

    &__flyout-description {
        display: none;

        @include respond-above(lg) {
            //@include make-col-ready();
            @include make-col(3);

            display: block;

            .elem-text {
                span, p {
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    &__flyout-description-text {

        @include respond-above(lg) {
            padding-bottom: rem(10px);
        }
    }

    &__flyout-description-title {
        margin-bottom: rem(24px);
    }

    &__flyout-tools {
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        width: 100%;
        height: $header-height;
        padding: $header-mobile-padding;
        z-index: 100;
        transition: opacity 350ms ease-out 150ms;

        @include respond-above(lg) {
            position: absolute;
            //right: 5.56vw;
            //top: calc(#{$header-height} + 30px);
            top: $header-height;
            left: 0;
            height: rem(64px);
            width: 100%;
            padding: 0;
            padding-right: 4.56vw;
            justify-content: flex-end;
            background-color: $white;
            opacity: 0;

            .flyout--opened & {
                position: fixed;
                opacity: 1;
            }
        }

        @include respond-above(xxl) {
            //right: 10vw;
            padding-right: 10vw;
        }
    }

    &__back-btn {
        @include karbonMedium();

        display: flex;
        align-items: center;
        position: relative;
        padding: 20px;
        padding-left: 23px;
        font-size: rem(16px);
        text-transform: uppercase;
        letter-spacing: 1px;

        &::before {
            @include nexiIcon();
            
            content: $icon-caret_right;
            position: absolute;
            top: 50%;
            left: -5px;
            font-size: rem(16px);
            transform: translate(0, -50%) rotate(180deg);
        }

        @include respond-above(lg) {
            display: none;
        }
    }

    &__flyout-item {
        @extend %firstLevelMenuItem;

        //padding: rem(4px) 0;
        //margin-bottom: rem(20px);

        &.active {
            > #{$this}__flyout-link,
            > #{$this}__flyout-button {
                @include karbonMedium();
                color: $blue;
            }
        }

        &.active:not(.has-submenu) {
            > #{$this}__flyout-link span {
                pointer-events: none;
            }
        }

        @include respond-above(lg) {
            padding: 0;
            margin-bottom: 0;

            .second-level > & {
                //width: 25%;
                align-items: flex-start;

                .twin-columns & {
                    width: 100%;
                }

                &.is-card-menu {
                    width: 100%;
                }

                &.is-related-menu {
                    position: absolute;
                    left: 0;
                    bottom: 30px;
                    width: 100%;
                    flex-direction: column;
                    padding-top: rem(22px);
                    padding-bottom: 0;
                    border-top: 1px solid $nav-item-border;

                    > #{$this}__flyout-button {
                        margin-bottom: rem(15px);
                    }

                    #{$this}__flyout-link {
                        margin-bottom: 0;
                    }
                }
            }

            .third-level & {
                //width: 33.33333333%;
                width: auto;

                /*.is-related-menu & {
                    width: auto;
                }*/
                
                .is-card-menu & {
                    @include make-col-ready();
                    @include make-col(6);

                    max-width: 432px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            .twin-columns & {
                flex-direction: column;
            }

            &.has-badge a,
            &.has-badge button {
                word-break: break-all;
                //word-wrap: break-word;
                //hyphens: auto;
            }
        }
    }

    &__nav-item,
    &__flyout-item {

        &.flyout--opened {
                
            > #{$this}__flyout {
                display: flex;
            }
        }
    }

    &__flyout-badge {
        @include karbonSemibold();

        position: relative;
        top: 1px;
        padding: rem(5px) rem(11px) rem(5px) rem(12px);
        border-radius: rem(15px);
        font-size: rem(14px);
        text-transform: uppercase;
        letter-spacing: 2px;
        text-decoration: none;
        font-style: normal;
        line-height: rem(14px);
        color: $black;

        &.badge--new {
            background-color: #00A38D;
            color: $white;
        }

        @include mouse-only() {
            &:hover {
                text-decoration: none;
                letter-spacing: 2px;
            }
        }

        @include respond-above(lg) {
            padding: rem(4px) rem(9px) rem(4px) rem(10px);
            font-size: rem(12px);
            line-height: rem(12px);
        }

        @include respond-above(xl) {
            padding: rem(5px) rem(11px) rem(5px) rem(12px);
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    &__flyout-link,
    &__flyout-button {
        @include karbonRegular();

        //display: block;
        font-size: rem(20px);
        line-height: rem(24px);
        text-transform: none;
        letter-spacing: normal;
        padding: rem(8px) 0;
        padding-right: rem(16px);
        //height: 100%;
        //width: 100%;
        cursor: pointer;

        @include mouse-only() {
            
            &:hover span {
                padding: 0;
                box-shadow: none;
            }

            #{$this}__flyout-item:not(.active) &:hover {
                transition: text-shadow .2s;

                //@include karbonMedium();
                //letter-spacing: -0.33px; // Workaround for multiline text
                text-shadow: 0.6px 0 0 var(--blue), 0.6px 0 0 var(--blue), 0.6px 0 0 var(--blue);
            }

            .is-related-menu &:hover {
                //letter-spacing: -0.76px !important;
            }

            &:not(span):hover {
                
                span {
                    text-decoration: underline;
                }
            }
        }

        @include respond-above(lg) {
            height: 100%;
            width: 100%;
            //padding: rem(8px) rem(18px) rem(8px) 0;
            padding: 0 rem(18px) 0 0;
            margin-bottom: rem(16px);
            transition: opacity .4s ease-out,
                        text-shadow .2s;

            .has-preview.flyout--opened  & {
                @include underline();

                text-shadow: 0.6px 0 0 var(--blue), 0.6px 0 0 var(--blue), 0.6px 0 0 var(--blue);
                //font-family: KarbonMedium;
                //letter-spacing: -0.33px; // Workaround for multiline text
            }

            .is-related-menu & {
                font-size: rem(18px);
                line-height: rem(18px);
                letter-spacing: -0.5px;
                padding: rem(3px) 0;
            }

            .is-card-menu & {
                margin-bottom: 0;
            }
        }
    }

    &__flyout-link {

        @include respond-above(lg) {
            display: inline-flex;
            width: auto;
        }
    }

    &__flyout-button {

        &::after {
            @include buttonArrow();

            top: 50%;
            transform: translateY(-50%);
            font-size: rem(16px);
        }

        #{$this}__flyout-item:not(.active) &.highlight {
            letter-spacing: -0.33px; // Workaround for multiline text
        }

        &.highlight {
            @include karbonMedium();

            &::after {
                right: 0;
            }
        }

        @include mouse-only() {
            
            &:hover::after {
                right: 0;
            }
        }

        @include respond-above(lg) {
            display: none;
            cursor: default;

            .twin-columns &,
            .is-related-menu & {
                @include karbonMedium();

                display: flex;
                font-size: rem(18px);
                letter-spacing: 2px;
                line-height: rem(24px);
                text-transform: uppercase;
                pointer-events: none;
                //cursor: default;

                &::after {
                    display: none;
                }
            }
        }
    }

    &__flyout-link-card {
        @include respond-above(lg) {
            padding: 0;
            width: 100%;
            text-shadow: none !important;

            &:hover {
                text-decoration: none;

                #{$this}__card-title {
                    // font-family: KarbonRegular;
                    @include karbonRegular();
                    letter-spacing: 0;
                }
            }

            &:not(span):hover {
                span {
                    text-decoration: none;
                    padding-right: rem(24px);
                }
            }

            > span {
                display: none;
            }
        }
    }

    &__card {
        display: none;

        @include respond-above(lg) {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            &.round-border {
                border-radius: $card-border-radius-small;
            }
        }
    }

    &__card-content {
        display: flex;
        flex-direction: column;
        //height: 100%;
        height: 228px;
        padding: rem(24px) rem(24px) rem(32px) rem(24px);
    }

    &__card-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        min-width: 0;

        picture {
            @include imageCover();
        }

        img {
            object-fit: cover;
            max-width: 100%;
        }
    }
    
    &__card-details {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        flex-grow: 1;
    }

    &__card-pretitle {
        @include t-label-small();
        margin-bottom: rem(8px);
    }

    &__card-text-link {
        @include t-text-link();
        margin-top: auto;

        span {
            padding-right: 24px;
            position: relative;
            &:after {
                @include nexiIcon();
                
                content: $icon-arrow_right;
                position: absolute;
                right: 0;
                top: 1px;
                font-size: rem(12px);
            }
        }
    }

    &__flyout-recommentation {
        display: none;

        @include respond-above(lg) {
            display: flex;
        }
    }

    &__nav-tools {
        //display: none;
        display: flex;
        align-items: center;
        height: 0;
        opacity: 0;
        transition: opacity .3s ease-out;
        overflow: hidden;
        letter-spacing: 1px;
        text-transform: uppercase;

        @include respond-above(lg) {
            //display: flex;
            padding-left: rem(20px);
            height: 100%;
            overflow: visible;
            opacity: 1;
            z-index: 1;
        }
    }

    &__login-cta {
        @extend %btn;
        border: 1px solid;

        @include respond-above(lg) {
            text-transform: none;
            letter-spacing: 1px;
            padding: 11px 14px;

            // #{$this}--dark:not(:hover) &,
            #{$this}--dark :not(.flyout--active) & {
                border-color: $white;
                color: $white;

                &:hover {
                    border-color: $blue;
                    color: $blue;
                }
            }

            #{$this}--white &,
            #{$this}--dark.scrolled &,
            #{$this}--dark:hover &,
            #{$this}--dark .flyout--active & {
                border-color: $black;
                color: $black;
            }

        }

        @include mouse-only() {
            &:hover {
                border-color: $blue;
                color: $blue;
            }
        }
        
        .icon-user {
            margin-right: 10px;
        }

    }

    &__login-change {
        @include karbonMedium();

        color: $white;
        display: flex;
        align-items: center;
        font-size: rem(15px);
        line-height: rem(25px);
        letter-spacing: 1.5px;

        .icon-change-portal {
            margin-left: rem(15px);
            line-height: inherit;
        }

        @include mouse-only() {
            &:hover {
                color: $azure;
            }
        }
    }

    @include respond-above(lg) {
        top: 0;
    }

    #{$wcmmode} & {
        position: absolute;
        max-height: $header-height;
    }

    &.base-header.with-stickybar {
         
        #{$this}__flyout-items{
            margin-top: calc(#{$header-height} + 72px);
        }

        #{$this}__flyout-items.third-level{
            margin-top: calc(#{$header-height} - 72px);
        }

        #{$this}__flyout-recommendation{
            margin-top: calc(#{$header-height} + 72px);
        }
        #{$this}__menu-close-btn{
            margin-top: 90px;
        }


        @include respond-below(lg) {
        #{$this}__back-btn{
            margin-top: calc(#{$header-height} + 41px);
            padding-top: 34px;
        }
        #{$this}__menu-close-btn{
            margin-top: calc(#{$header-height} + 40px);
        }
        #{$this}__flyout-items.second-level{
            margin-top: calc(#{$header-height} - 10px);
        }
        #{$this}__flyout-items.third-level{
            margin-top: calc(#{$header-height} - 10px);
        }
        #{$this}__back-btn::before{
            margin-top: 6px;
        }
        #{$this}__nav-items {
            margin-bottom: 180px;
        }
    }
    }
    &.base-header.no-sticky {
         
        #{$this}__flyout-items{
            margin-top: calc(#{$header-height} + 72px);
        }

        #{$this}__flyout-items.third-level{
            margin-top: calc(#{$header-height} - 72px);
        }

        #{$this}__flyout-recommendation{
            margin-top: calc(#{$header-height} + 72px);
        }
        #{$this}__menu-close-btn{
            margin-top: 90px;
        }


        @include respond-below(lg) {
        #{$this}__back-btn{
            margin-top: calc(#{$header-height} + 41px);
            padding-top: 34px;
        }
        #{$this}__menu-close-btn{
            margin-top: calc(#{$header-height} + 40px);
        }
        #{$this}__flyout-items.second-level{
            margin-top: calc(#{$header-height} - 10px);
        }
        #{$this}__flyout-items.third-level{
            margin-top: calc(#{$header-height} - 10px);
        }
        #{$this}__back-btn::before{
            margin-top: 6px;
        }
        #{$this}__nav-items {
            margin-bottom: 180px;
        }
    }
    }
}


@keyframes slideInLeft {
    from {
        transform: translate(-50%,0);
        opacity: 0;
    }
    to {
        transform: translate(0,0);
        opacity: 1;
    }
}

@keyframes flyoutDown {
    0% {
        max-height: 0;
        //padding-top: 0;
    }
    100% {
        //top: $header-height;
        //padding-top: $header-height;
        max-height: 100vh;
    }
}

@keyframes flyoutUp {
    0% {
        max-height: 70vh;
    }
    100% {
        max-height: $header-height;
    }
}