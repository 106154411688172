$width100: '.card__width--100';
$width50: '.card__width--50';
$width25: '.card__width--25';
$medium: '.card__medium';
$small: '.card__small';
$extrasmall: '.card__extrasmall';
$rowSpacingSmall:   '.row__spacing--small';

$border: ".border";

.elem-flip-card {
    @include make-col-ready();
    @include make-col(12);
    width: 100%;
    margin-bottom: rem(24px);
    min-height: rem(240px);

    @include respond-above(md) {

        #{$medium} & {
            @include make-col(6);
        }

        #{$small} & {
            @include make-col(4);
        }

        #{$extrasmall} & {
            @include make-col(3);
        }

        #{$rowSpacingSmall}#{$medium} &, #{$rowSpacingSmall}#{$small} &, #{$rowSpacingSmall}#{$extrasmall} & {
            margin-bottom: rem(22px);
        }
    }

    @include respond-above(lg) {
        min-height: rem(352px);

        @include make-col(4);
        margin-bottom: 0;

        #{$width100} & {
            @include make-col(12);
        }

        #{$width50} & {
            @include make-col(6);
        }

        #{$width25} & {
            @include make-col(3);
        }

        #{$rowSpacingSmall} & {
            margin-bottom: rem(22px);
        }
    }

    #{$wcmmode} & {
        max-width: 100%;
    }

    &__container {
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;

        #{$wcmmode} & {
            flex-direction: row;
        }

        &#{$border} {
            border: $card-border;
        }

        &.flip-y {
            transform: perspective(rem(1000px)) rotateY(180deg);
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: rem(24px);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transform: rotateX(0deg);

        #{$wcmmode} & {
            position: relative;
            border: $card-border;
        }

        &.flip-y {
            transform: rotateY(180deg);
        }

        &.elem-flip-card-back {
            position: relative;
        }
    }

    &__title {
        margin-bottom: rem(12px);
        min-height: 64px;

        @include respond-above(lg) {
            margin-bottom: rem(16px);
            min-height: 96px;
        }
        &-back {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: -0.1px;
          padding-bottom: rem(16px);

             @include respond-above(lg) {
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -0.32px;
             }
        }
    }

    &__description {
        @include t-paragraph-1();
        padding-bottom: rem(40px);

        b {
           @include karbonMedium();
        }
    }

    &__button {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: rem(24px);
        right: rem(24px);
    }
}
