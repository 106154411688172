$animation: cubic-bezier(.09,.72,.76,1.01);

$blue: #2D32AA;
$grey: #F1F1F1;
$grey-disabled: #D7D7E4;
$grey-dark: #D8D8D8;
$light-soft-grey: #ECECEC;
$white-light-grey: #F7F7F9;
$accent-color-promo: #207DB5;

$t-grey-placeholder: #5D5E5E;
$t-green : #48D597;

@mixin overlayInitialState {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

@mixin overlayOpenState {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

@mixin centerElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: -1;
    @include overlayInitialState;

    opacity: 0;
    transition: all 400ms ease-in-out;

    // remove the before
    &:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.6);
        display: block;
        height: 100%;
        width: 100%;
    }

    &.overlay--open {
        @include overlayOpenState;
        display: block;
        z-index: 5001;
        transition: opacity 400ms ease-in-out;
    }
}

.overlay__wrapper {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    color: black;
    display: grid;

    @include respond-above(lg) {
        width: 90%;
        bottom: auto;
        height: auto;
        max-height: 90%;
        border-radius: 20px;
    }

    @include respond-above(xlm) {
        max-width: 1260px;
    }

    // Perfect Scrollbar style
    .ps__thumb-y {
        right: 6px;
        width: 4px;
        position: sticky;
        justify-self: center;
    }

    .ps__rail-y {
        right: 5px;
        padding-top: 64px;
        margin-bottom: 16px;

        &:hover,
        &:focus,
        &.ps--clicking {
            .ps__thumb-y {
                right: 4px;
                width: 8px;
            }
        }
    }
}

.overlay__header {
    position: sticky;
    top: 0;
    left: 0;
    width: auto;
    height: rem(72px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
    padding: rem(24px);
    padding-bottom: rem(28px);
    background: white;
    z-index: 2;

    @include respond-above(lg) {
        height: rem(64px);
        padding: rem(20px) 0;
        margin: 0 rem(32px);
        border-bottom: 1px solid var(--lighterGray);
    }
}

.overlay__icon {
    position: absolute;
    font-size: rem(14px);
    display: block;
    padding: rem(5px);
    pointer-events: all;
    cursor: pointer;
}

.overlay__scrollContent {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;

    @include respond-above(lg) {
        overflow: unset;
    }
}

.overlay__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    opacity: 1;
    padding: 0 rem(25px) rem(58px);
    text-align: center;

    @include respond-above(lg) {
        padding: rem(40px) rem(25px);
        max-width: 80%;
    }

    @include respond-above(xl) {
        max-width: 66%;
    }

    @include respond-above(xlm) {
        max-width: rem(920px);
    }

    &--commission {

        @include respond-above(lg) {
            max-width: 90%;
        }

        @include respond-above(xl) {
            max-width: 88%;
        }

        @include respond-above(xlm) {
            max-width: rem(1056px);
        }
    }

}

.overlay__content--commission .overlay__commissionHead+.overlay__contentWrapper {
    padding-top: 0;
}

.overlay__commission-table {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    margin-top: rem(20px);
    padding-bottom: 16px;

    @include respond-above(lg) {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));

        .overlay__commission-item {
            grid-column: span 2;
        }

        &--size-1,
        &--size-2,
        &--size-3,
        &--size-4 {
            .overlay__commission-item {
                grid-column: span 3;
            }
        }

        &--size-5 {
            .overlay__commission-item {
                grid-column: span 2;
            }

            .overlay__commission-item:nth-child(4),
            .overlay__commission-item:nth-child(5) {
                grid-column: span 3;
            }
        }

    }

    &--cashback {
        grid-template-columns: 1fr;
        margin: 24px 0;
        padding: 0;
    }
}

.overlay__commission-item {
    background: $grey-dark;
    border-radius: rem(6px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(2px);

    .overlay__commission-content {
        background: #fff;
        border-radius: rem(4px);
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        width: 100%;
    }

    .overlay__commission-icon {
        width: rem(72px);
        height: rem(32px);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .overlay__commission-gif {
        height: rem(48px);
        margin: rem(16px);
        width: rem(48px);

        @include respond-below(lg) {
            margin: 0 auto rem(16px);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .overlay__commission-title {
        margin-top: rem(2px);
        text-align: center;
        font-size: rem(20px);

        b {
            @include karbonMedium;
        }
    }

    .overlay__commission-subtitle {
        flex-grow: 1;
    }

    .overlay__commission-txt {
        @include karbonMedium;
        font-size: rem(48px);

        &::first-letter {
            font-size: rem(54px);
        }

        .overlay-crossed {
            @include karbonRegular;
            text-decoration: line-through;

            @include respond-above(md) {
                font-size: rem(40px);
            }
        }

        s {
            @include karbonRegular;
            font-size: rem(40px);
        }

        b {
            @include karbonMedium;
        }
    }

    .overlay__commission-description {
        margin-top: rem(12px);
        text-align: center;
        font-size: rem(18px);

        b {
            @include karbonSemibold;
            font-size: rem(18px);
        }

        s {
            @include karbonRegular;
            font-size: rem(16px);
        }
    }

    &--cashback {
        background: linear-gradient(90deg, #2D32AA 0%, #2BD4D9 100%);
        padding: rem(2px);

        .overlay__commission-txt {
            display: block;
            text-align: center;
            width: 100%;

            .overlay__commission-value {
                display: inline-block;
                font-size: rem(56px);
                text-align: center;

                @include respond-below(lg) {
                    font-size: rem(48px);
                }
            }

            .overlay__commission-label {
                font-size: rem(48px);
                text-align: center;

                @include respond-below(lg) {
                    font-size: rem(32px);
                }
            }
        }

        .overlay__commission-description {
            font-size: 18px;
            margin-top: rem(16px);
            text-align: center;
            width: 100%;
        }
    }
}

.overlay__tiles-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .overlay__tiles-header {
        @include karbonMedium;
        font-size: rem(24px);
        margin-bottom: rem(16px);
    }

    .overlay__tiles-grid {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: rem(16px);

        @include respond-above(lg) {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: rem(16px);
        }
    }

    .overlay__tile-container {
        background-color: #F1F1F1;
        border-radius: rem(6px);
        display: flex;
        flex-direction: column;
        padding: rem(15px);
        width: 100%;
    }

    .overlay__tile-title {
        @include karbonMedium;
        font-size: rem(18px);
        line-height: rem(22px);
        margin-bottom: rem(10px);
        width: 100%;
    }

    .overlay__tile-message {
        @include karbonMedium;
        font-size: rem(16px);
        line-height: rem(20px);
    }
}

.overlay__commissionHead {
    display: flex;
    padding-top: 50px;
    padding-bottom: 20px;
    justify-content: center;
    width: 100%;

    @include respond-above(lg) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .buttons-list {
        background-color: #F1F1F1;
        border-radius: 6px;
        padding: 4px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        width: 100%;

        @include respond-above(lg) {
            display: flex;
            width: auto;
        }
    }

    .commission-button {
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
        height: 40px;
        cursor: pointer;
        transition: 0.3s ease-in-out background-color;
        text-transform: uppercase;
        @include karbonSemibold;

        &.active {
            background-color: #C3C4DC;
        }

        &:hover {
            background-color: #C3C4DC;
            transition: 0.3s ease-in-out background-color;
        }
    }

}

.overlay__commissionWrapper {
    &.hidden {
        display: none;
    }

    transition: 0.3s ease-in-out opacity;

    &[data-commission-acquiring-id]:not([data-commission-acquiring-id=""]) {
        opacity: 0;

        &.loaded {
            opacity: 1;
        }
    }

    #{$edit_mode} & {
        opacity: 1;
    }
}

.overlay__contentWrapper {
    opacity: 1;
    display: block;
    width: 100%;

    .send--success &,
    .send--error & {
        opacity: 0;
        display: none;
        transition: opacity 0.4s $animation 0.6s;
    }

     &:not(:has(.overlay__title)) {
        .widget-tab {
            padding-top: 0 !important;
        }
     }

    & .widget-tab {
        .swiper-wrapper {
            flex-wrap: nowrap;
        }
    }

}

.overlay__title {
    @include t-h4-title;
    @include karbonMedium;
    margin-bottom: rem(40px);

    .overlay__content--commission & {
        margin-bottom: 10px;
    }
}

.overlay__par {
    &+.overlay__par {
        .overlay__infoNewWrapper & {
            margin-top: rem(24px);
        }
    }
}

.overlay__parSubtitle {
    color: black;
    display: block;
    margin-top: rem(10px);
    text-align: center;
    line-height: 1.22;

    @include respond-above(lg) {
        line-height: 1.08;
    }

    &.overlay__parSubtitle--left {
        text-align: left;
        margin-top: rem(48px);

        @include respond-above(md) {
            margin-top: rem(24px);
        }
    }

    &.overlay__parSubtitle--alignCenterMobile {
        text-align: center;
    }

    b,
    strong {
        @include karbonMedium;
    }
}

.overlay__cta {
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;

    &.cta--disabled {
        pointer-events: none;
    }
}


.overlay__txtWrapper {
    text-align: left;

    @include respond-above(lg) {
        padding-bottom: rem(40px);
    }

    &--cashback {
        padding: 0;
    }

    & .widget-tab {
        &:has(.widget-tab__tab-item:only-child) {

             .widget-tab__tab-pane {
                padding-top: 0;
             }

             .widget-tab__tab-group {
                display: none;
             }
        }
    }
}

.overlay__subtitle {
    @include t-h4-title;
    @include karbonMedium;
    margin-bottom: rem(8px);
    margin-top: rem(48px);

    @include respond-above(md) {
        margin-bottom: rem(6px);
    }

    .overlay__commissionWrapper & {
        margin-top: 0;
        font-size: rem(32px);
    }

    &:empty {
        display: none;
    }

    .overlay__infoNewWrapper & {
        margin-top: rem(24px);

        @include respond-above(lg) {
            margin-top: rem(40px);
        }
    }
}

.overlay__label {
    @include t-paragraph-3;
    @include karbonMedium;
    margin-bottom: rem(8px);

    @include respond-above(md) {
        margin-bottom: rem(4px);
    }
}

.overlay__txt,
.overlay__suggestion {
    font-size: rem(18px);

    b,
    strong {
        @include karbonMedium;
    }

    .overlay__txtLink,
    a {
        color: black;
        text-decoration: underline;
    }
}



.overlay__disclaimer {
    margin-top: rem(16px);
    margin-bottom: rem(24px);
    display: block;

    p {
        margin-bottom: rem(15px);
    }

    b,
    strong {
        @include karbonMedium;
        color: black;
    }

    a {
        color: black;
        text-decoration: underline;
    }

    .overlay__commissionWrapper & {
        p {
            margin-bottom: 8px;
        }

        b,
        strong {
            @include karbonMedium;
            margin-bottom: 8px;
        }

        a {
            @include karbonMedium;
            margin-bottom: 8px;
        }
    }
}

.overlay__banner {
    background: $white-light-grey;
    padding: rem(16px) rem(24px);
    color: black;
    border-radius: rem(4px);

    @include respond-above(md) {
        padding: rem(16px) rem(42px);
    }

    +.overlay__banner {
        margin-top: rem(16px)
    }

    .overlay__commissionWrapper & {
        @include respond-above(md) {
            padding: rem(16px) rem(24px);
        }
    }

    &.overlay__banner--white {
        background-color: white-background;
        border: rem(2px) solid $grey-dark;
        color: black;

        a {
            color: black;
        }
    }

    &.overlay__banner--blue {
        background-color: $accent-color-promo;
        color: white;

        a {
            color: white;
        }
    }
}

.overlay__bannerText {
    @include t-paragraph-3;
    position: relative;
    padding-left: rem(40px);

    @include respond-above(md) {
        padding-left: rem(45px);
    }

    a {
        @include karbonMedium;
        color: black;
        text-decoration: underline;
    }

    b {
        @include karbonMedium;
    }

    s {
        padding-right: 4px;
    }

    .overlay__commissionWrapper & {
        font-size: rem(16px);

        b {
            font-size: rem(16px);
        }

        a {
            font-size: rem(16px);
        }
    }
}

.overlay__bannerIcon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(24px);

    &.overlay__bannerIcon--lessTop {
        top: 0;
    }
}

.overlay__bannerBottom {
    border-top: rem(1px) solid $grey-dark;
    margin-top: rem(24px);
    padding-top: rem(24px);
}

.overlay__label {
    font-size: rem(20px);
    line-height: rem(21px);
}

.overlay__txt {
    .overlay__commissionWrapper & {
        font-size: rem(20px);
        line-height: rem(25px);
    }
}

.overlay__plugInIcons {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.overlay__plugInImgWrap {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: rem(20px) 0;

    @include respond-above(md) {
        width: 33.33%;
    }

    @include respond-above(lg) {
        width: 25%;
    }
}

.overlay__plugInImg {
    width: 80%;

    @include respond-above(lg) {
        width: 70%;
    }
}

.overlay__plugInLinkTxt {
    margin-top: rem(25px);
    font-size: rem(16px);

    @include respond-above(lg) {
        margin-top: rem(65px);
        font-size: rem(18px);
    }
}

.overlay__plugInLink {
    @include karbonMedium;
    color: $blue;
    text-decoration: underline;
}

.overlay__commissionsimplify {
    @include respond-above(lg) {
        padding: 0 1rem;
    }

    margin-top: 1.5rem;
    border-bottom: 1px solid $grey-dark;
}

.overlay__commissionsimplifyitems {
    border-top: 1px solid $grey-dark;
    margin-bottom: 1rem;
    padding-top: 1rem;

    .overlay__commission-title {
        margin-top: 2px;
        font-size: rem(20px);
        @include karbonMedium;
    }

    .overlay__commission-subtitle {
        flex-grow: 1;
        @include t-paragraph-3;
        margin-bottom: 1.5rem;

        b {
            @include karbonMedium;
        }
    }
}

.overlay__commissionsimplifytitle {
    text-transform: uppercase;
    color: black;
    padding: .6875rem 0;
    padding-left: .3125rem;
    letter-spacing: .0625rem;
}

.overlay__messageBottom {
    text-align: left;
    width: 100%;

    .overlay__messageBottom-txt {
        width: 100%;

        a {
            color: black;
            @include karbonMedium;
            text-decoration: underline;
        }
    }
}