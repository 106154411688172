.widget-modal-form {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 400ms ease-in-out;

    &.hide {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        visibility: hidden;
        transition: all 400ms ease-in-out;
    }

    &.show {
        z-index: setZ(modal);
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &__dialog {
        height: 100%;
        position: relative;
        z-index: 10;
        background-color: $white;
    }

    &__container {
        //@include container();
        //@include content-container();

        overflow: hidden;
        display: flex;
        flex-direction: column;
        //height: 100vh;
        justify-content: space-between;
        height: 100%;
        //padding-bottom: rem(30px);
        //position: relative;
        pointer-events: auto;
    }

    &__header {
        padding: rem(20px) rem(30px);
        //position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: auto;

        @include respond-above(lg) {
            padding: rem(45px) rem(90px);
            padding-bottom: rem(20px);
            justify-content: space-between;
        }
    }

    &__logo {
        max-height: 21px;

        img {
            max-width: 100%;
            max-height: 21px;
            height: auto;
            width: 100%;

            @include respond-above(lg) {
                max-height: 29px;
            }
        }

        @include respond-above(lg) {
            max-height: 29px;
            min-width: 95px;
        }
    }
    
    &__close {
        @include karbonMedium();

        position: absolute;
        right: 21px;
        font-size: rem(16px);
        line-height: rem(17px);
        letter-spacing: 1px;
        color: $blue;
        text-transform: uppercase;

        @include respond-above(lg) {
            position: static;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //position: relative;
        pointer-events: auto;
        overflow-y: auto;
        flex: 1 1 auto;
        //padding-bottom: rem(50px);

        > div {
            height: 100%;
        }
    }
}
