.widget-hero {
    $this: &;
    position: relative;
    width: 100%;
    z-index: 1;

    &#{$this}--home {
        min-height: $hero-height-large;

        #{$wcmmode} & {
            min-height: 850px !important;
        }
    }

    &#{$this}--news {
        background-color: $white;
        color: $black;
    }

    /*@include respond-above(xs) {
        .widget-slideshow & {    // Same height of Slideshow component when inside it
            min-height: 100% !important;
        }
    }*/

    &__bg-picture {
        @include imageCover;
        
        position: absolute;
        width: calc(100vw + 2px);   // Workaround for the side white pixel
        max-width: calc(100vw + 2px);   // Workaround for the side white pixel
        margin-left: -1px;   // Workaround for the side white pixel

        &.media-picture--lazy::after {
            display: none;  // lazy loading animation disabled
        }

        img {

            #{$edit_mode} & {
                position: relative !important;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) !important;
                width: auto !important;
            }

            @include respond-below(md) {
                width: auto;
                height: 100%;
            }

            @include respond-below(lg,landscape) {
                width: auto;
                height: 100%;
            }
        }

       
        #{$this}--home & {
            @include respond-below(lg) {
                position: relative;

                img {
                    height: auto;
                    width: 100%;
                    top: auto !important;
                    left: auto !important;
                    transform: none !important;

                    #{$edit_mode} & {
                        width: auto !important;
                    }
                }
            }

            @media (max-device-width: 1023px) and (orientation: landscape) {
                position: absolute;

                img {
                    max-height: 85%;
                    object-fit: cover;
                }
            }

            @include respond-above(lg) {
                img {
                    max-height: 85%;
                    object-fit: cover;
                }
            }

            @include ie-only() {
                width: 100%;
                
            }
        }
    }

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.2));
        pointer-events: none;
    }

    &__container {
        @include container();
        @include content-container();

        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 200;

        #{$this}--full & {
            min-height: $hero-height-full;
            justify-content: flex-end;

            #{$wcmmode} & {
                min-height: 850px !important;
            }
        }

        #{$this}--full-footer & {
            min-height: 536px;
            justify-content: flex-start;
        }

        #{$this}--news & {
            min-height: $hero-height-medium-mobile;
        }

        @include respond-above(md) {
            #{$this}--full-footer & {
                min-height: $hero-height-full-footer;
            }
        }

        @include respond-above(lg) {
            #{$this}--full & {
                justify-content: flex-start;
            }

            #{$this}--full-footer & {
                justify-content: center;

                #{$wcmmode} & {
                    min-height: 850px !important;
                }
            }

            #{$this}--home & {
                //min-height: $hero-height-large;

                #{$wcmmode} & {
                    min-height: 850px !important;
                }
            }

            #{$this}--news & {
                min-height: $hero-height-medium-desktop;
            }
        }

        @include respond-above(xs) {
            .widget-slideshow & {    // Same height of Slideshow component when inside it
                min-height: 100% !important;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        z-index: 10;

        #{$this}--news & {
            padding-bottom: rem(41px);
        }

        @include respond-below(lg) {

            #{$this}--full & {
                padding-top: 35vh;
            }

            #{$this}--full-footer & {
                padding-top: rem(150px);
                min-height: inherit;
            }

            #{$this}--home & {
                padding-top: rem(24px);
                padding-bottom: rem(32px);
            }

            .widget-slideshow & {
                padding-bottom: rem(80px);
            }
        }

        @media (max-device-width: 1023px) and (orientation: landscape) {
            #{$this}--home & {
                @include make-col(8);
                padding-top: rem(72px);
            }
        }

        @include respond-above(lg) {
            @include make-col(6);

            #{$this}--full-footer & {
                padding-top: rem(88px);
                padding-bottom: rem(88px);
            }
            
            #{$this}--full & {
                min-height: inherit;
                padding-top: rem(218px);
            }

            #{$this}--home & {
                flex-grow: 1;
                padding-top: rem(218px);
            }

            #{$this}--news & {
                @include make-col(10);

                //padding-top: rem(208px);
                padding-top: rem(32px);
                padding-bottom: rem(48px);
            }

            .widget-slideshow & {
                padding-bottom: rem(88px);
            }
        }

        @include ie-only(){
            min-height: inherit;
        }
    }

    &__pre-title {
        margin-bottom: rem(6px);

        h1, h2, h3, h4, h5, h6, p, span {
            @include t-label-big();
        }

        @include respond-below(lg) {
            display: none;

            #{$this}--home & {
                display: block;
            }
        }

        #{$this}--news & {
            display: block;
            line-height: 14px;

            @include respond-above(lg) {
                line-height: 24px;
                margin-bottom: rem(24px);
            }
        }
    }

    /*&__title {

        > * {
            @include t-h1-title();

            color: $white;
            margin: 0;
            padding: 0;
        }

        &#{$this}--small-text > * {
            @include t-h4-title();

            @include respond-above(lg) {
                @include t-h3-title();
            }
        }

        #{$this}--news & {
            > * {
                color: $black;
            }

            &#{$this}--small-text > * {
                @include t-h3-title();
            }
        }
    }*/

    &__price {
        display: none;

        p, span {
            margin-bottom: 0 !important;
        }

        span + span,
        span > span {
            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }
        }
        
        @include respond-above(lg) {
            display: block;
            margin-top: rem(43px);
            // flex-direction: row;
            // align-items: center;
            // 

            p , span {
                display: inline-block !important;
                // align-items: center;
            }
        }

        @include respond-below(lg) {
            #{$this}--home & {
                display: block;
            }
        }
    }

    &__description {
        margin-top: rem(24px);

        @include respond-above(lg) {
            margin-top: rem(20px);
        }
    }

    &__cta-container {

        @include respond-below(lg) {
            #{$this}--full-footer & {
                margin-top: auto;
            }

            .is-mobile-hidden {
                display: none;
            }
            
        }
        
        @include respond-above(lg) {
            margin-top: rem(35px);

            .is-desktop-hidden {
                display: none;
            }
        }
    }

    &__cta {

        &:first-child {
            margin-top: 20px;
        }

        + #{$this}__cta,
        + .elem-cta-modal {
            margin-top: 16px;
        }

        &.is-mobile-hidden {
            + #{$this}__cta,
            + .elem-cta-modal {
                margin-top: 20px;
            }
        }

        @include respond-above(lg) {
            &:first-child {
                margin-top: 0;
            }

            + #{$this}__cta,
            + .elem-cta-modal {
                margin-top: 0;
                margin-left: 32px;
            }

            &.is-mobile-hidden {
                + #{$this}__cta,
                + .elem-cta-modal {
                    margin-top: 0;
                }
            }

            &.is-desktop-hidden {
                + #{$this}__cta,
                + .elem-cta-modal {
                    margin-left: 0;
                }
            }
        }
    }

    &__info {
        font-size: rem(16px);
        line-height: rem(22px);
        
        @include respond-above(lg) {
            color: $blue;
            font-size: rem(18px);
            line-height: rem(24px);
            letter-spacing: -0.5px;
            display: flex;

            span {
                line-height: rem(32px);
            }
            
            span:first-child {
                padding-right: rem(15px);
            }
        }
    }

    &__author {
        ~ #{$this}__time {
            @include respond-above(lg) {
                border-left: 1px solid #D7D7D7;
                padding-left: rem(15px);
            }
        }
    }

    &__line {
        @include respond-above(lg) {
            display: none;
        }
    }

    &__footer-container {
        display: flex;
        flex-direction: column;
        margin-top: rem(16px);

        // .widget-share__container {
        //     //position: relative;
        // }

        // .widget-share__title {
        //     /*position: absolute;
        //     top: -20px;
        //     width: 100%;*/
        //     //display: none;
        // }

        @include respond-above(lg) {
            margin-top: rem(25px);
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__bottom-description {
        margin-top: rem(32px);

        @include respond-above(lg) {
            margin-top: rem(70px);
        }
    }

    .base-main__item:first-child > & {
        margin-top: -72px;
    }
}