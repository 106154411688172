%no-content-paddings {
    @include respond-above(lg) {
        padding-top: 0;
        padding-bottom: rem(443px);
    }
}

.elem-payment-method {
    
    &__container {
        @include container();
        @include content-container();
    }

    &__row {
        @include make-row();
        padding-bottom: rem(97px);
        justify-content: center;
        padding-top: rem(28px);

        @include respond-above(lg) {
            padding-top: rem(111px);
        }

        &.with-blocks {
            padding-top: rem(32px);
            
            @include respond-above(lg) {
                padding-top: rem(139px);
                padding-bottom: rem(164px);
            }
        }

        &.with-second-image {
            padding-bottom: rem(374px);
            padding-top: rem(28px);
            @include respond-above(lg) {
                padding-bottom: rem(466px);
                padding-top: rem(41px);
            }

            &.no-content {
                @extend %no-content-paddings;
            }
        }

        &.with-blocks.with-second-image {
            padding-top: rem(32px);
            @include respond-above(lg) {
                padding-top: rem(139px);
            }
        }

        &.with-full-image {
            padding-top: 0;
            padding-bottom: rem(97px);

            @include respond-above(lg) {
                padding-bottom: rem(164px);
            }
        }

        &.with-second-image.with-full-image {
            padding-top: 0;
            padding-bottom: rem(337px);

            @include respond-above(lg) {
                padding-bottom: rem(378px);
            }

            &.no-content {
                @extend %no-content-paddings;
            }
        }

        &.no-content {
            @extend %no-content-paddings;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(23px);

        &:nth-child(odd) {
            padding-right: rem(21px);
            border-right: 1px solid;
        }

        &:nth-child(even) {
            padding-left: rem(21px);
        }

        &:last-child {
            border-right: 0;
        }

        @include respond-above(lg) {
            margin-bottom: 0;
            height: 92px;

            &:nth-child(odd) {
                padding: 0 rem(40px);
            }
            &:nth-child(even) {
                padding: 0 rem(40px);
                border-right: 1px solid;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                border-right: 0;
            }
        }

        @include respond-below(lg){
            @include make-col-ready();
            @include make-col(6);
        }
    }

    &__icon {
        margin-top: rem(7px);
        margin-bottom: rem(28px);
        @include respond-above(lg) {
            margin-bottom: 0;
        }
    }

    &__label {
        max-width: 130px;
        text-align: center;
        @include t-label-big();
        @include respond-above(lg) {
            max-width: none;
        }
    }

    &__secondary {
        position: relative;
        margin-bottom: rem(32px);
        @include respond-above(lg) {
            margin-bottom: rem(69px);
        }
    }

    &__text {
        text-align: center;
        padding: 0 rem(6px);
        @include respond-above(lg) {
            @include make-row();
            justify-content: center;
        }
    }

    &__card {
        padding: rem(24px) rem(14px);
        border-radius: rem(8px);
        box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.08);
        max-width: 100%;
        margin-top: rem(-60px);
        background-color: $white;
        width: 100%;
        min-height: 362px;

        @include respond-above(lg) {
            padding: rem(46px) rem(96px);
            margin-top: rem(-80px);
        }

        
        &.with-second-image {
            margin-top: rem(-337px);

            @include respond-above(lg) {
                margin-top: rem(-379px);
            }
        }

        &.with-full-image {
            margin-top: rem(-97px);

            @include respond-above(lg) {
                margin-top: rem(-164px);
            }
        }

        &.with-second-image.with-full-image {
            margin-top: rem(-337px);

            @include respond-above(lg) {
                margin-top: rem(-378px);
            }
        }
    }

    &__image {
        margin-top: rem(40px);

        @include respond-above(lg) {
            margin-top: rem(14px);
            justify-content: center;
        }
    }

    &__second-text {
        text-align: center;
        padding-top: rem(30px);
        padding-bottom: rem(11px);

        @include respond-above(lg) {
            text-align: left;
            padding-top: rem(60px);
            padding-bottom: rem(13px);    
        }

        .elem-label {
            color: $black;
        }

    }

    &__link {
        @include t-text-link();
        color: $blue;
        justify-content: center;
        padding-top: rem(25px);

        & > a {
            position: relative;
        }

        & > a > span {
            padding-right: 24px;

            &:after {
                @include nexiIcon();
                content: $icon-arrow_right;
                position: absolute;
                right: 0px;
                top: 1px;
                font-size: rem(12px);
            }
        }

        @include respond-above(lg) {
            padding-top: rem(46px);
        }

        @include respond-below(lg) {
            padding-bottom: rem(8px);
        }
    }

}
