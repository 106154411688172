$width100: '.card__width--100';
$width50: '.card__width--50';
$width25: '.card__width--25';
$medium: '.card__medium';
$small: '.card__small';
$extrasmall: '.card__extrasmall';
$rowSpacingSmall:   '.row__spacing--small';
$reverse-order: ".reverse-order";
$border: ".border";

.elem-highlight-card {
    @include make-col-ready();
    @include make-col(12);
    width: 100%;
    margin-bottom: rem(31px);

    @include respond-above(md) {
        #{$medium} & {
            @include make-col(6);         
        }

        #{$small} & {
            @include make-col(4);
        }

        #{$extrasmall} & {
            @include make-col(3);
        }

        #{$rowSpacingSmall}#{$medium} &, #{$rowSpacingSmall}#{$small} &, #{$rowSpacingSmall}#{$extrasmall} & {
            margin-bottom: rem(22px);
        }
    }

    @include respond-above(lg) {
        @include make-col(4);
        margin-bottom: 0;

        #{$width100} & {
            @include make-col(12);
        }

        #{$width50} & {
            @include make-col(6);
        }

        #{$width25} & {
            @include make-col(3);
        }

        #{$rowSpacingSmall} & {
            margin-bottom: rem(22px);
        }
    }

    &__container {
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        &#{$reverse-order} {
            flex-direction: column-reverse;
            justify-content: flex-end;
        }

        &#{$border} {
            border: $card-border;
        }

        @include respond-above(lg) {
            border-radius: 18px;
        }
    }

    &__content {
        padding: rem(24px);
        height: 100%;
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            padding-bottom: rem(28px);
        }
    }

    &__title {
        margin-bottom: rem(12px);
        min-height: 64px;

        @include respond-above(lg) {
            margin-bottom: rem(16px);
            min-height: 96px;
        }
    }

    &__description {
        @include t-paragraph-1();

        b {
           @include karbonMedium();
        }
    }

    &__price-link {
        margin-top: auto;
    }

    &__price {
        padding-top: rem(12px);
    }

    &__link {
        @include t-text-link();
        color: $blue;
        margin-top: rem(24px);

        & > a {
            position: relative;
        }

        & > a > span {
            padding-right: 24px;

            &:after {
                @include nexiIcon();
                content: $icon-arrow_right;
                position: absolute;
                right: 0px;
                top: 1px;
                font-size: rem(12px);
            }
        }
    }

    &__image {
        position: relative;
    } 

    &__label {
        position: absolute;
        bottom: 13px;
        left: 13px;
        background-color: $grayWhite;
        border-radius: rem(8px);
        padding: rem(10px) rem(12px) rem(10px) rem(12px);

        @include respond-above(lg) {
            bottom: 23px;
            padding: rem(9px) rem(12px) rem(9px) rem(12px);
        }

        .elem-label {
            padding-bottom: 0;
            color: $black !important;
        }

    }
}
