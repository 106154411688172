.base-header-lite {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: setZ(header);

    &.base-header--login {
        box-shadow: 0 10px 13px 0 rgba(0,0,0,0.1);
        background: rgba(255,255,255,0.07);
    }

    @include respond-below(md) {
        &.login-private {
            background-color: $loginPrivate;
        }

        &.login-business {
            background-color: $loginBusiness;
        }
    }

    .base-header__nav {
        @include respond-above(lg) {
            width: 90%;
            max-width: 1440px;
        }
    }

    .base-header__logo {
        

        a:hover {
            opacity: 1;
        }
    }

    .base-header__top {
        width: auto;
    }

    .base-header__nav-tools {
        margin-left: auto;

        @include respond-below(lg) {
            opacity: 1;
            height: auto;
            overflow: visible;
        }
    }
}