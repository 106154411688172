@each $name, $size in $margin {
    .m-b-#{$name} {
        margin-bottom: #{$size} !important;
    }
}

@include respond-above(lg) {
    @each $name, $size in $margin-desktop {
        .m-b-#{$name} {
            margin-bottom: #{$size} !important;
        }
    }
}