$width100: '.card__width--100';
$width50: '.card__width--50';
$width25: '.card__width--25';
$medium: '.card__medium';
$small: '.card__small';
$extrasmall: '.card__extrasmall';
$rowSpacingSmall:   '.row__spacing--small';
$reverse-order: ".reverse-order";
$border: ".border";
$card-size: 330px;
$content-size: 250px;
$margin-size: 13px;
$side-margin-space: 26px;

.elem-service-card {
    @include make-col-ready();
    @include make-col(12);
    width: 100%;
    margin-bottom: rem(31px);

    @include respond-above(md) {
        #{$medium} & {
            flex: 0 0 50%;
        }

        #{$small} & {
            flex: 0 0 33.333333%;
        }

        #{$extrasmall} & {
            @include make-col(3);
        }
    }

    @include respond-above(lg) {
        flex: 0 0 33.333333%;
        padding: 0 rem(13px);
    }

    @include respond-above(xlm) {
        padding: 0;
        margin-left: rem($margin-size);
        margin-right: rem($margin-size);

        &:first-child,
        &:nth-child(4n) {
            margin-left: calc(((33.333333% - ($card-size + $side-margin-space)) * 1.5) + $margin-size);
        }
    }

    @include respond-above(lg) {
        max-width: $card-size;
        margin-bottom: rem(26px);

        #{$width100} & {
            @include make-col(12);
            padding: 0;
            margin-left: rem($margin-size);
            margin-right: rem($margin-size);
            display: flex;
            justify-content: center;
        }

        #{$width50} &,
        #{$medium} & {
            padding: 0;
            margin-left: rem($margin-size);
            margin-right: rem($margin-size);

            &:nth-child(odd) {
                margin-left: calc(50% - ($card-size + $margin-size));
            }
        }

        #{$width25} &,
        #{$extrasmall} & {
            @include make-col(3);
            padding: 0 rem(13px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__container {
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;

        &#{$border} {
            border: $card-border;
        }

        @include respond-above(xxl) {
            width: rem($card-size);
        }

        @include respond-above(lg) {
            border-radius: 18px;
            align-items: center;

            #{$medium} &,
            #{$width50} &,
            #{$width100} & {
                width: rem($card-size);
            }

            #{$width25} &,
            #{$extrasmall} & {
                width: 100%;
            }
        }
    }

    &__content {
        text-align: center;
        padding: rem(32px) rem(24px);
        height: 100%;
        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            padding: rem(40px);

            #{$width25} &,
            #{$extrasmall} & {
                padding: rem(24px);
            }
        }
    }

    &__title {
        margin-bottom: rem(8px);
        margin-top: rem(16px);

        @include respond-above(lg) {
            margin-top: rem(24px);
            margin-left: auto;
            margin-right: auto;

            #{$width25} &,
            #{$extrasmall} & {
                width: 100%;
            }
        }

        @include respond-above(xl) {
            width: rem($content-size);
        }

        .elem-title {
            font-size: rem(20px);
            line-height: rem(26px);
            letter-spacing: rem(-0.5px);

            @include respond-above(lg) {
                font-size: rem(24px);
                line-height: rem(32px);
                letter-spacing: rem(-1px);
            }
        }
        .elem-title .p-b-def  {
            padding-bottom: 0 !important;
        }
    }

    &__description {
        @include t-paragraph-1();
        font-size: rem(16px);
        line-height: rem(22px);
        b {
           @include karbonMedium();
        }

        @include respond-above(xxl) {
            width: rem($content-size);
        }

        @include respond-above(lg) {
            font-size: rem(18px);
            line-height: rem(24px);
            margin-left: auto;
            margin-right: auto;

            #{$medium} &,
            #{$width50} &,
            #{$width100} & {
                width: rem($content-size)
            }

            #{$width25} &,
            #{$extrasmall} & {
                width: 100%;
            }
        }
    }

    &__icon {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;

        em {
            font-size: rem(32px);

            &:before {
                color: #2d32aa;
            }
        }
    }
}
