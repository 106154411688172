
// MODAL
.widget-modal-loader {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 400ms ease-in-out;

    #{$edit_mode} & {
        top: auto;
        bottom: auto;
        position: relative;
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        visibility: hidden;
        transition: all 400ms ease-in-out;
    }

    &.show {
        z-index: setZ(modal);
        //display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &__backdrop {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        background-color: #000;
        opacity: 0.6;
        animation: fadeOut 1s;
        //transition: opacity 1s ease-in;
        pointer-events: none;

        #{$edit_mode} &,
        &.hidden {
            display: none;
        }
    }

    &__dialog {
        height: 100%;
        position: relative;
        z-index: 10;

        @include respond-above(lg) {
            //margin: 85px auto;
            height: 100%;
            //max-height: calc(100% - 170px);
            display: flex;
            align-items: center;
            pointer-events: none;
            width: 100%;
        }
    }

    &__container {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding-bottom: rem(20px);
        background-color: $white;
        position: relative;
        pointer-events: auto;

        @include respond-above(lg) {
            height: 100vh;
            flex: 1;
            
            #{$edit_mode} & {
                height: 100%;
            }
        }
    }

    &__header {
        $this-nav: &;
        @include percentage-container();

        position: relative;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        height: $header-height;
        justify-content: space-between;
        align-items: center;
        padding: $header-mobile-padding;
    }

    &__header-logo {
        max-height: 29px;
        //padding-top: 2px;

        a {
            display: inline-block;

            @include mouse-only() {
                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }

        img {
            max-width: 100%;
            max-height: 29px;
            height: auto;
        }

        @include respond-above(lg) {
            min-width: 95px;
        }
    }

    &__body {
        position: relative;
        overflow-y: auto;
        height: 100%;
        padding: rem(24px);
        pointer-events: auto;

        @include respond-above(lg) {
            padding: rem(32px);
        }
    }

    &__content {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.active {
            display: flex;
        }

        &-icon {
            padding-bottom: rem(18px);
            text-align: center;
            color: $blue;
    
            em {
                font-size: rem(64px);
            }

            &.icon-black {
                color: $black;
            }

            &.icon-red {
                color: $red;
            }

            &.icon-orange {
                color: orange;
            }

            &.icon-azure {
                color: $azure;
            }

            &.icon-green {
                color: $green;
            }

            &.icon-gray {
                color: $darkGray;
            }
        }

        &-title {
            @include karbonMedium();

            font-size: rem(34px);
            line-height: rem(40px);
            padding: rem(20px) 0;
            text-align: center;
        }

        &-message {
            font-size: rem(20px);
            line-height: rem(22px);
            text-align: center;
            padding-bottom: rem(50px);
        }

        /*&-cta {
            margin-top: (20px);
    
            @include respond-above(lg) {
                margin-top: rem(30px);
            }
        }*/

        #{$edit_mode} & {
            display: flex;
            height: auto;
            padding: rem(60px);
            border: 2px dashed red;
        }
    }

    &__animation {
        width: 100%;
        max-width: 260px;
        height: auto;
        margin-top: rem(-20px);

        @include respond-above(lg) {
            max-width: 360px;
            margin-top: rem(-40px);
            padding-bottom: rem(20px);
        }
    }
}
