.elem-searchbar {
    @include container();
    @include content-container();

    .layout-panel & {
        padding: 0 !important;
        max-width: none;
    }

    &__wrapper {
        margin: 0 auto;
        position: relative;
        margin-bottom: rem(40px);

        @include respond-above(lg) {
            margin: rem(64px) rem(96px);
            margin-top: 0;
        }
    }

    &__search {
        background: $white;
        padding: rem(20px) rem(12px);
        width: 100%;
        display: block;
        border: 1px solid $gray;
        border-radius: $card-border-radius-small;
        appearance: none;
        
        @include t-paragraph-2();

        &::placeholder {
            color: $darkGray;
        }
        &::-ms-input-placeholder {
            color: $darkGray;
        }

        &:focus {
            outline: none;
            border-color: $black;
        }

        @include respond-above(lg) {
            padding: rem(32px) rem(40px);
            font-size: rem(24px);
        }

        .panel-blue & {
            color: $black;
        }
    }

    &__icon {
        position: absolute;
        font-size: rem(24px);
        right: 12px;
        top: 20px;
        pointer-events: none;
        color: $lighterGray !important;

        @include respond-above(lg) {
            right: 40px;
            top: 30px;
            font-size: rem(32px);
        }

        .elem-searchbar.searchbar__active & {
            cursor: pointer;
            color: $blue !important;
            pointer-events: auto;
        }
    }

    &__tip {
        @include t-paragraph-2();
        margin-top: rem(16px);
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }
}