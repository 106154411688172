.elem-icon-anim {
    pointer-events: none;
    
    svg {
        //max-height: 32px;
        //max-width: 32px;
    }

    &--init {

        &::before {
            display: none;
        }
    }

    @include ie-only {
        overflow: hidden;
    }
}