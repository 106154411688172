@mixin selectStyle() {
    padding: 11px 40px 11px 16px ;
    border: 1px solid $white;
    border-radius: 8px;
    font-size: rem(18px);
    line-height: rem(24px);
    background-color: rgba(255,255,255,0.1);

    &:disabled {
        opacity: 0.7;
    }

    @include respond-above(lg) {
        padding: 11px 16px;
    }
}

/*@mixin selectArrow() {

    &::before {
        @include nexiIcon();

        content: $icon-caret_up;
        position: absolute;
        top: 50%;
        right: 20px;
        font-size: 24px;
        transform: translate(0, -45%) rotate(180deg);
        pointer-events: none;
    }
}*/

@mixin btn-tab {
    font-size: rem(16px);
    line-height: rem(18px);
    letter-spacing: -0.5px;
    color: $black;
    flex: 0 1 100%;
    padding: 0 4px;
    text-align: center;
    transition: all .3s cubic-bezier(.09,.72,.76,1.01);
    
    @include respond-above(lg) {
        font-size: rem(24px);
    }
    
    &.active {
        @include karbonMedium();
        background: $white;
        border-radius: rem(40px);
        color: $blue;
        box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
    }

    @include mouse-only() {
        &:hover {
            // font-family: KarbonMedium;
            background: $white;
            border-radius: rem(40px);
            color: $blue;
            box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        }
    }

    &:hover {
        @include karbonMedium();
    }
}

@mixin btn-tab-blue {
    font-size: rem(12px);
    line-height: rem(16px);
    letter-spacing: 1.5px;
    border-top: var(--tabBorder);
    border-bottom: var(--tabBorder);
    border-right: var(--tabBorder);
    background: $white;
    text-align: center;
    text-transform: uppercase;
    transition: all .3s cubic-bezier(.09,.72,.76,1.01);

    &.active {
        @include karbonMedium();

        border-color: var(--tabBorderActive);
        background: $blue;
        color: $white;
        border-radius: 0;
        box-shadow: none;
    }

    @include mouse-only() {
        &:hover {
            // font-family: KarbonMedium;
            border-color: var(--tabBorderActive);
            background: $blue;
            color: $white;
            border-radius: 0;
        }
    }

    &:hover {
        @include karbonMedium();
    }

    @include respond-above(lg) {
        font-size: rem(16px);
        line-height: rem(16px);
        letter-spacing: 2px;
        padding: 0 10px;
    }
}

@mixin btn-tab-blue-border {
    font-size: rem(16px);
    line-height: rem(18px);
    letter-spacing: -0.5px;
    color: $black;
    flex: 0 1 100%;
    padding: 0 4px;
    text-align: center;
    transition: none;

    @include respond-above(lg) {
        font-size: rem(24px);
    }

    &.active {
        @include karbonMedium();
        background: $white;
        border-radius: rem(40px);
        color: $blue;
        border: 2px solid $blue;
        box-shadow: none;
    }

    @include mouse-only() {
        &:hover {
            background: $white;
            border-radius: rem(40px);
            color: $blue;
            box-shadow: 0 4px 8px 2px rgba(0,0,0,0.08);
        }
    }
}


$blue-skin : '.widget-tab--blue';
$blue-border-skin : '.widget-tab--blue-border';
$align-center: '.tab-align--center';
$width100: '.column__width--100';
$width50: '.column__width--50';

.widget-tab {
    $this-tab: &;

    &__tab-group {
        @include container();
        @include content-container();

        @include respond-above(lg) {
            display: flex;

            #{$align-center} & { 
                .swiper-wrapper {
                    justify-content: center;
                }
            }

            .swiper-wrapper {
                flex-wrap: wrap;
            }

        }
    }

    .swiper {
        overflow: visible;
    }

    &__tab-item {
        display: inline-flex;
        background-color: var(--tabGroupBGDefault);
        height: rem(56px);
        flex: 0 1 auto;
        padding: 4px;
        min-width: rem(154px);

        &:first-child {
            border-top-left-radius: rem(40px);
            border-bottom-left-radius: rem(40px);
        }

        &:last-child {
            border-top-right-radius: rem(40px);
            border-bottom-right-radius: rem(40px);
        }

        @include respond-above(lg) {
            &:nth-child(5n + 1) {
                border-top-left-radius: rem(40px);
                border-bottom-left-radius: rem(40px);
            }

            &:nth-child(5n) {
                border-top-right-radius: rem(40px);
                border-bottom-right-radius: rem(40px);
            }
        }

        #{$blue-skin} & {
            background-color: transparent;
            padding: 0;
            height: 40px;

            &:first-child {
                border-radius: 0;
                #{$this-tab}__btn {
                    border-top-left-radius: rem(8px);
                    border-bottom-left-radius: rem(8px);
                    border-left: var(--tabBorder);
                    &.active {
                        border-left: $blue;
                    }
                }
            }

            &:last-child {
                border-radius: 0;

                #{$this-tab}__btn {
                    border-top-right-radius: rem(8px);
                    border-bottom-right-radius: rem(8px);
                }
            }

            @include respond-above(lg) {
                &:nth-child(5n + 1) {
                    border-radius: 0;
                    #{$this-tab}__btn {
                        border-top-left-radius: rem(8px);
                        border-bottom-left-radius: rem(8px);
                        border-left: var(--tabBorder);
                        &.active {
                            border-left: $blue;
                        }
                    }
                }

                &:nth-child(5n) {
                    border-radius: 0;

                    #{$this-tab}__btn {
                        border-top-right-radius: rem(8px);
                        border-bottom-right-radius: rem(8px);
                    }
                }
            }
        }

        #{$blue-border-skin} & {
            background-color: $white;
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-top-color: rgba(0, 0, 0, 0.10);
            border-bottom-color: rgba(0, 0, 0, 0.10);

            &:first-child {
                border-left: 1px solid;
                border-left-color: rgba(0, 0, 0, 0.10);
            }
            &:last-child {
                border-right: 1px solid;
                border-right-color: rgba(0, 0, 0, 0.10);
            }

            @include respond-above(lg) {
                &:nth-child(5n + 1) {
                    border-left: 1px solid;
                    border-left-color: rgba(0, 0, 0, 0.10);
                }

                &:nth-child(5n) {
                    border-right: 1px solid;
                    border-right-color: rgba(0, 0, 0, 0.10);
                }
            }
        }

        .swiper-initialized & {
            flex: 1 0 41.85%;
        }

        @include respond-above(lg) {
            height: rem(64px);
            flex: 0 0 20%;

            &:first-child:nth-last-child(-n + 3),
            &:first-child:nth-last-child(-n + 3) ~ & {
                flex: 0 1 28.597%;

                .widget-modal-generic & {
                    flex: 0 1 33.33%;
                }
            }

            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ & {
                flex: 0 0 25%;
            }
        }

    }

    &__btn {
        @include btn-tab();

        #{$blue-skin} & {
            @include btn-tab-blue();
        }

        #{$blue-border-skin} & {
            @include btn-tab-blue-border();
        }
    }

    &__tab-pane {
        display: none;
        color: $black;
        padding-top: rem(32px);
        padding-bottom: 0;
        
        &.active {
            display: block;
        }

        @include respond-above(lg) {
            padding-top: rem(80px);

            #{$blue-skin} & {
                padding-top: rem(64px);
            }
        }

        #{$wcmmode} & {
            display: block;
        }

        &:has(.widget-products-detail-card) {
            padding-top: rem(40px);
        }
    }

    &__row {
        @include make-row();
    }

    &__column {
        @include make-col-ready();

        display: flex;
        flex-direction: column;

        @include respond-above(lg) {
            #{$width100} & {
                @include make-col(12);
            }

            #{$width50} & {
                @include make-col(6);
            }
        }
    }

    .widget-tab__dropdown {
        display: none; // Hidden by default

        &:not(.widget-modal-generic .widget-tab__dropdown) {
            @include container();
            @include content-container();
        }

        .custom-select {

            position: relative;
            width: 100%;

            select {

                position: absolute;
                visibility: hidden;
                opacity: 0;

            }

            &--disabled {

                .custom-select__opener {
                    cursor: default;
                    opacity: 0.7;

                    @include mouse-only() {
                        &:hover {
                            background-color: rgba(255,255,255,0.1);
                        }
                    }
                }
            }

            &--open {

                .custom-select__opener {
                    color: $gray;
                    background-color: $white;
                    box-shadow: $bg-shadow;
                    transition: background-color .2s ease-out,
                                border-radius 0s ease-out;

                    &::before {
                        color: $blue;
                        transform: translate(0, -45%);
                    }


                    @include mouse-only() {
                        &:hover {
                            background-color: $white;
                        }
                    }
                }

                .custom-select__panel {
                    max-height: calc(35vh + 50px);
                    box-shadow: $bg-alt-shadow;


                }

                .custom-select__options {
                    max-height: 35vh;
                    overflow: auto;

                }
            }

            &__opener {
                @include selectStyle();

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;

                font-size: rem(24px);
                line-height: rem(32px);
                font-weight: 500;
                color: var(--blue);
                width: 100%;
                display: flex;
                height: rem(56px);
                padding: rem(0px) rem(24px);

                align-items: center;
                align-self: stretch;
                border-radius: rem(40px);
                border: 2px solid var(--blue);
                box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);


                transition: background-color .2s ease-out,
                            border-radius .1s ease-out .2s;

                &::before {
                    @include nexiIcon();
                    content: $icon-caret_up;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    font-size: 24px;
                    transform: translate(0, -45%) rotate(180deg);
                    pointer-events: none;
                    color: var(--blue);
                }

                @include mouse-only() {
                    &:hover {
                        background-color: rgba(255,255,255,0.2);
                    }
                }

            }

            &__panel {
                position: absolute;
                z-index: 100;
                left: 0;
                //top: 100%;
                width: 100%;
                overflow: hidden;

                border-radius: 15px;
                background-color: $white;
                max-height: 0;
                transition: max-height .2s ease-out;
            }

            &__options {
                position: relative;
                width: 100%;
                z-index: 1;

                // Perfect Scrollbar style
                .ps__thumb-y {
                    right: 12px;
                    width: 7px;
                }

                .ps__rail-y {
                    width: 40px;
                    margin: 16px 0;

                    &:hover,
                    &:focus,
                    &.ps--clicking {

                        .ps__thumb-y {
                            right: 11px;
                            width: 8px;

                        }
                    }
                }
            }

            &__option {
                position: relative;
                color: $black;
                cursor: pointer;
                padding: rem(16px) rem(32px);
                font-size: rem(20px);
                line-height: rem(24px);

                &:active,
                &:hover,
                &--selected {
                    @include karbonMedium();
                    background-color: $grayWhite;

                }

            }
        }
    }

    .widget-tab__tab-group {
        display: flex; // Tabs are visible by default
    }



    @include respond-below(md) {
        .widget-tab__tab-group.hidden-on-mobile {
            display: none;
        }

        .widget-tab__dropdown.visible-on-mobile {
            display: block !important;
        }
    }

}

.widget-modal-generic {
    .widget-tab__tab-group {
        padding: 0 !important;
    }

    @include respond-below(md) {
        .widget-tab__tab-pane.active {
            min-height: calc(35vh + 50px);
        }
    }
}

.lp-dob {
    .widget-tab {
        &__tab-group {
            @include respond-above(xl) {
                padding-left: 5vw !important;
                padding-right: 5vw !important;
                max-width: calc( #{$content-container-max-width} + 20vw);
            }
        }
    }
}
