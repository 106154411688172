$header-height:         76px;
$title-height:          157px;
$footer-spaces:         105px;

$mob-header-height:     55px;
$mob-title-height:      69px;

.layout-prelogin {
    $this-prelogin: &;

    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left:0;

    @include respond-above(md) {
        height: 100vh;
        position: initial;
    }

    &__main {
        z-index: 1;
        position: relative;
        overflow-y: auto;
        flex-grow: 1;
        height: 100%;
    }

    &__header {
        padding: 20px 0;

        @include respond-above(lg) {
            padding: 30px 0;
        }

        .btn-close {
            @include karbonSemibold();

            color: $blue;
            
            

            span {
                display: none;
            }

            em {
                font-weight: bold;
                font-size: rem(15px);
                line-height: rem(15px);
                display: block;
            }

            @include respond-above(lg) {
                text-transform: uppercase;
                font-size: rem(16px);
                line-height: rem(16px);
                letter-spacing: 2px;

                span {
                    display: block;
                }

                em {
                    display: none;
                }
            }
        }
    }

    &__header-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        margin-bottom: rem(27px);

        @include respond-above(lg) {
            height: 100%;
            max-height: calc(100% - 55px - 73px);
            margin-bottom: 0;
        }
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__row {
        @include make-row();
    }

    &__col {
        @include make-col-ready();
        @include make-col(12);

        margin-bottom: rem(15px);

        @include respond-above(md) {
            margin-bottom: rem(25px);
        }
    
        @include respond-above(lg) {
            @include make-col(4);

            margin-bottom: 0;

            #{$this-prelogin}__content & {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-left: rem(15px);
                padding-right: rem(15px);
            }
        }

        #{$this-prelogin}__footer & {
            margin-bottom: rem(10px);
            
            @include respond-above(md) {
                @include make-col(12);
                
                padding: 0;

                @include ie-only {
                    @include make-col(6);

                    padding-left: rem(5px);
                    padding-right: rem(5px);
                }
            }
        }
    }

    &__title {
        width: 100%;
        padding-top: rem(20px);
        padding-bottom: rem(20px);
        text-align: center;

        b {
            @include karbonSemibold();
        }

        @include respond-above(md) {
            padding-bottom: rem(40px);
        }
    }

    &__footer {
        position: fixed;
        width: 100%;
        bottom: 0;
        background-color: $preloginFooterBg;
        z-index: 10;

        @include respond-below(md) {
            max-height: calc(100% - #{$mob-header-height} - #{$mob-title-height} - 6px );
            position: relative;
        }
    }

    &__footer-wrapper {
        @include respond-below(md) {
            height: 100%;
            max-height: calc(100vh - #{$mob-header-height} - #{$mob-title-height} - 85px);
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
        }
    }

    &__footer-opener {
        text-align: center;
    }

    &__footer-btn {
        @include nexiCondensedSemibold();
        color: $white;
        text-transform: uppercase;
        font-size: rem(16px);
        line-height: rem(16px);
        letter-spacing: 2px;
        padding: rem(17px) rem(17px) rem(22px) rem(17px);
        
        &:focus {
            outline: none;
        }

        em {
            width: 30px;
            height: 30px;
            position: absolute;
            border-radius: 50%;
            top: -15px;
            left: calc(50% - 15px);
            background-color: $preloginFooterBg;
            padding: 5px 9px 5px 10px;
            font-size: rem(15px);
            pointer-events: all;
        }

        &[aria-expanded="true"] {
            em {
                transform: rotate(180deg);
            }
        }
    }

    &__footer-collapse {
        height: 0;
        overflow: hidden ;
        transition: height 0.5s ease;

        &.show {
            display: block;
            height: auto;
            
            @include respond-below(md) {
                // height: 100%;
                overflow-y: auto;
                background:
                    /* Shadow covers */
                    linear-gradient(rgba(21,25,95, 1) 30%, rgba(21,25,95,0)),

                    /* Shadows */
                    linear-gradient(0deg, rgba(17,19,65,0.02) 59%, rgba(17,19,65,0.8) 100%);

                background-repeat: no-repeat;
                background-color: $preloginFooterBg;
                background-size: 100% 26px, 100% 26px, 100% 14px, 100% 14px;
                    //Opera doesn't support this in the shorthand
                background-attachment: local, scroll;
            }
        }
    }

    &__footer-content {
       padding-bottom: rem(30px);
        
        @include respond-above(md) {
            // min-height: calc(50vh - #{$footer-spaces} - 71px);

            #{$this-prelogin}__row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 0;
                column-gap: rem(10px);
            }
        }
    }
}