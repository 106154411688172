.base-footer-lite {
    width: 100%;
    background-color: $white;

    &__container {
        padding: rem(21px);
        display: flex;
        height: 102px;
        justify-items: center;

        @include respond-above(md) {
            padding: rem(21px) rem(65px);
        }
    }

    &__logo-wrapper {
        padding-right: rem(40px);
        display: flex;
        align-items: center;
    }

    &__logo {
        height: 25px;
        width: auto;
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: right;
        width: 85%;
        justify-content: space-evenly;

        @include respond-above(md) {
            border-left: 1px solid #9596AF;
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    
    &__nav-item {
        @include karbonMedium();

        color: $blue;
        font-size: rem(14px);
        line-height: rem(14.5px);
        margin-left: rem(24px);
        white-space: nowrap;

        &:first-child {
            margin-left: 0;
        }
        
        @include mouse-only() {
            &:hover {
                color: $azure;
            }
        }
        
        @include respond-above(md) {
            font-size: rem(18px);
            line-height: rem(19px);
            white-space: normal;
        }

        @include respond-below(sm) {
            margin-top: 3px;
            margin-bottom: 3px;
        }
    }
    
    &__text-copyright {
        font-size: rem(14px);
        line-height: rem(15px);
        color: $black;
    }
}