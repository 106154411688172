%disableScroll {
    // Prevent iOS and Android page scrolling
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

:root,
html {
    @include default-theme;
    @include cssVariables;
    @include karbonRegular();

    font-size: $text-base-size;
    font-weight: normal;
    font-style: normal;

    -webkit-tap-highlight-color: transparent; //Fix blue overlay when a touchscreen user taps on a clickable element.
}

html,
body {
    overflow-x: hidden;
    overscroll-behavior-y: none;
}

html {
    height: 100%;
    //height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    overflow-y: scroll;
    
    &.block-scroll {
        @extend %disableScroll;
    }
}

#{$edit_mode} {
    touch-action: auto !important;
    -webkit-overflow-scrolling: initial !important;
    overflow: auto !important;
    overscroll-behavior: initial !important;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    @include respond-above(lg) {
        min-height: 100vh;
    }

    &.menu-open {
        &:before {
            content: '';
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            right: 0;
            top:0;
            z-index: setZ(overlay);

            @include respond-above(lg) {
                background: $headerOverlay;
            }
        }
    }
}

.base-page {
    &.no-menu {
        main {
            padding-top: 0;
        }
    
        .base-main__item:first-child .widget-hero {
            margin-top: 0;
        }
    }

    &.base-page-login {
        position: relative;
    }
}