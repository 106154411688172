.widget-form-steps {
    height: 100%;
    
    &__container {
        display: flex;
        flex-direction: column;
        max-width: 940px;
        margin: 0 auto;
        height: 100%;
        overflow: hidden;
    }

    &__header {
        padding: rem(20px) rem(24px);

        @include respond-above(lg) {
            padding: 0;
            padding-bottom: rem(20px);
        }
    }

    &__progressbar {
        display: flex;
        width: 100%;
    
        progress {
            height: 11px;
            width: 100%;
    
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
    
            &::-webkit-progress-bar {
                border: 0;
                border-radius: 11px;
                background-color: adjust-color($color: #2d32aa, $alpha: -0.7);
            }
    
            &::-webkit-progress-value {
                border: 0;
                border-radius: 11px;
                background-color: $blue;
                transition: width 400ms ease-out;
            }

            &::-moz-progress-bar {
                border: 0;
                border-radius: 11px;
                background-color: $blue;
                transition: width 400ms ease-out;
            }
    
            &::-ms-fill {
                border: 0;
                border-radius: 11px;
                color: $blue;
                background-color: $blue;
                transition: width 400ms ease-out;
            }

            &.hidden {
                opacity: 0;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        //flex-grow: 1;
        padding-bottom: rem(30px);
        overflow-y: auto;
        overflow-x: hidden;

        @include respond-above(lg) {
            padding: 0;
        }
    }

    &__steps-container {
        position: relative;
        display: flex;
        width: 100%;
        left: 0;
        transition: left 0.3s ease-out;

        #{$edit_mode} & {
            flex-direction: column;
        }
    }

    &__step {
        //display: none;
        display: flex;
        width: 100%;
        flex-direction: column;
        min-width: 100%;
        max-width: 530px;
        padding: 0 rem(24px);
        overflow-y: auto;
        flex-shrink: 0;
        opacity: 0;
        border: none;
        transition: opacity 0.5s ease-out;
        pointer-events: none;

        &.active,
        #{$edit_mode} & {
            //display: flex;
            opacity: 1;
            pointer-events: all;
        }

        &.large {
            max-width: 620px;
        }

        @include respond-above(lg) {
            margin: 0 auto;
            padding: 0;
        }

        #{$edit_mode} & {
            padding-bottom: 40px;
        }
    }

    &__form-title {
        font-size: rem(32px);
        line-height: rem(40px);
        padding: rem(20px) 0;
        text-align: center;

        .form-result & {
            padding-top: rem(50px);
        }
    }

    &__form-fields {
        padding-bottom: rem(45px);

        @include respond-above(md) {
            padding-bottom: rem(64px);
        }
    }

    &__form-fields-group {
        display: none;

        &.active,
        #{$edit_mode} & {
            display: block;
        }
    }

    &__input {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding-top: rem(36px);

        input[type=checkbox] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            & ~ label {
                display: inline-block;
                position: relative;
                font-size: rem(18px);
                line-height: rem(19px);
                padding-left: rem(44px);

                h1, h2, h3, h4, h5, h6, p {
                    margin: 0;
                }

                &:before {
                    content: " ";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    border-radius: 2px;
                    border: 2px solid $blue;
                }

                a {
                    color: $blue;
                    text-decoration: underline;
                    pointer-events: all;
                }
            }

            &:checked ~ label {
                &:before {
                    content: $icon-check;
                    font-family: "nexinew" !important;
                    color: $white;
                    background-color: $blue;
                    font-size: rem(16px);
                    text-align: center;
                    border-top-width: 4px;
                    border-left-width: 3px;
                }
            }

            & ~ .error-text {
                bottom: auto;
            }
        }

        input[type=date] {
            -webkit-appearance: none;
            -moz-appearance: none;
            -webkit-min-logical-width: 100%;
            background-color: white;
            color: #999;

            &::-webkit-date-and-time-value {
                text-align: left !important;
            }

            &:focus,
            &:valid {
                color: $black;
            }

            @include respond-below(lg) {
                &:before {
                    display: block;
                    width: 100%;
                    content: 'Seleziona una data';
                    content: attr(data-mobile-placeholder);
                }

                &:focus,
                &:valid,
                &.valid,
                &.error {
                    &:before {
                        display: none;
                        content: '';
                    }
                }
            }

            @include respond-above(lg) {
                text-transform: uppercase;
            }
        }

        &.valid,
        &.error {
            input[type=date] {
                color: $black;

                &:before {
                    display: none;
                    content: '';
                }
            }
        }

        &.large {
            max-width: 620px;
        }

        &.padding-reduced {
            padding-top: rem(26px);
        }

        &.text {
            padding-top: rem(24px);

            h1, h2, h3, h4, h5, h6, p {
                font-size: rem(14px);
                line-height: rem(16px);
                color: #999;
                margin: 0;
            }

            a {
                color: $blue;
                text-decoration: underline;
            }

            @include respond-above(md) {
                //padding-top: rem(20px);
            }
        }

        .error-text {
            display: none;
            position: absolute;
            bottom: rem(-24px);
            left: 0;
            padding-left: rem(17px);
            font-size: rem(14px);
            line-height: rem(18px);
            color: $inputTextError;
            
            &:before {
                position: absolute;
                content: $icon-alert;
                font-family: "nexinew" !important;
                font-size: rem(13px);
                top: 0;
                left: 0;
            }
        }

        &.error {

            label {
                &:before {
                    border-color: $inputBorderError !important;
                }
            }

            input[type=date]:before {
                content: '';
            }

            .error-text {
                display: block;
            }
        }

        &.recaptcha-container {
            position: absolute;
            padding: 0;
        }

        @include respond-above(md) {
            padding-top: rem(43px);
            max-width: 300px;
        }
    }

    &__input-wrapper {
        display: flex;
        border: 1px solid #000000;
        border-radius: 8px;
        padding: rem(8px) rem(16px);
        font-size: rem(18px);
        line-height: rem(24px);

        input,
        textarea {
            width: 100%;
            height: 25px;
            margin: 0;
            border: none;
            letter-spacing: -0.5px;

            &::-ms-clear {  // IE X button removal
                display: none;
                width : 0;
                height: 0;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #747676;
                opacity: .8;
            }
              
            &:-ms-input-placeholder {
                color: #747676;
                opacity: .8;
            }
              
            &::-ms-input-placeholder {
                color: #747676;
                opacity: .8;
            }
        }

        textarea {
            height: 94px;

            @include respond-above(md) {
                height: 168px;
            }
        }

        span {
            opacity: .5;
            margin-right: 5px;
            line-height: rem(25px);
        }

        .error & {
            border-color: $inputBorderError;
        }
    }

    &__input-label {
        @include karbonMedium();

        display: block;
        font-size: rem(14px);
        line-height: rem(18px);
        text-transform: uppercase;
        letter-spacing: 2px;
        padding-bottom: rem(6px);

        span {
            margin-left: 6px;
        }
    }

    &__form-result {
        display: none;

        &.active,
        #{$edit_mode} & {
            display: block;
        }
    }

    &__form-icon {
        padding-bottom: rem(26px);
        text-align: center;
        color: $blue;

        em {
            font-size: rem(64px);
        }
    }

    &__form-message {
        //padding: rem(20px);
        font-size: rem(18px);
        line-height: rem(20px);
        text-align: center;
    }

    &__form-footer {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-around;

        @include respond-above(md) {
            max-width: 310px;
        }
    }

    &__form-btn {
        display: none;
        width: auto;
        min-width: 150px;
        min-height: 40px;

        &.active {
            display: flex;
        }

        &[disabled] {
            background-color: #C9CAC8;
            border-color: #C9CAC8;
        }

        #{$edit_mode} & {
            &[data-role=next],
            &[data-role=back] {
                display: flex;
            }
        }

        @include respond-above(md) {
            min-width: 140px;
        }
    }
}


date-input-polyfill {
    z-index: 10000 !important;
}