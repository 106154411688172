.layout-masonry {
    @include container();
    @include content-container();

    width: 100%;

    &__container {
        //@include make-row();

        display: flex;
        flex-wrap: wrap;
    }

    .elem-card {
        padding: 0;
    }

    /*.widget-cards-list & {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: none;
    }*/
}