.elem-title {

    .base-main__item > & {
        @include container();
        @include content-container();

        margin-top: rem(40px);

        @include respond-above(lg) {
            margin-top: rem(60px);
        }
    }
   
    .base-main__item .base-main__item > & {
        padding: 0 !important;
        max-width: none;
        margin-top: 0;
    }

    width: 100%;
    color: $black;

    &__h1 {
        @include t-h1-title();
    }

    &__h2 {
        @include t-h2-title();
    }
    
    &__h3 {
        @include t-h3-title();
    }

    &__h4 {
        @include t-h4-title();
    }

    &__h5 {
        @include t-h5-title();
    }

    &__h6 {
        @include t-h6-title();
    }


    b, strong {
        @include karbonMedium();
        
        // font-weight: normal;
    }

    .panel-blue & {
        color: $white;
    }

    .panel-blue .elem-card & {
        color: $black;
    }

    .text-black &,
    .text-white &,
    .text-gray &,
    .text-blue &,
    .text-lightBlue &,
    .text-lightGray & {
        color: inherit;
    }

    &__align-left {
        text-align: left;
     }
    &__align-center {
        text-align: center;
    }

    & + .widget-tile {
        margin-top: 16px;

        @include respond-above(lg) {
            margin-top: 20px;
        }
    }
}