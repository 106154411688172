//
//  COMMONS
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-top: 1px solid $lightGray;
}

a,
button,
label {
    // Avoid contained elements to become a mouse target
    > * {
        pointer-events: none;
    }
}

#chatbotApp a,
#chatbotApp button,
#chatbotApp label {
    // Chatbot avoid pointer-events: none;
    > * {
        pointer-events: auto;
    }
}


input,
textarea,
button,
select,
a {
    // Disable blue highlight color on mobile when tapping
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

p, span {
    b {
        @include karbonMedium();
        // font-weight: normal;
    }
}

%category-bullet {
    display: inline-block;
    width: rem(8px);
    height: rem(8px);
    margin-right: 12px;
    border-radius: 100%;
    background-color: $gray;
}


//
//  CTA
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Button style reset
button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    border-radius: 0;
    text-align: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Blue outline removal (not very accessible) */
    &:focus {
        outline: none;
    }

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

%btn {
    @include karbonMedium();
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //max-width: rem(270px);
    min-height: rem(40px);
    padding: rem(11px) rem(12px);
    border-radius: rem(20px);
    font-size: rem(16px);
    line-height: rem(16px);
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: padding .3s cubic-bezier(.09,.72,.76,1.01), background .3s cubic-bezier(.09,.72,.76,1.01);
    position: relative;

    &.disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
    }
 
    @include respond-above(lg) {
        width: auto;
        min-width: rem(108px);
        max-width: none;
    }
}

%btnArrow {

    span + [class*='icon-'] {
        margin-left: 10px;

        @include respond-above(lg) {
            margin-left: 5px;
        }
    }

    [class*='icon-'] + span {
        margin-left: 10px;
    }
}

.btn-link {
    padding: rem(11px) rem(23px);

    [class*='icon-'] {
        transition: all .2s cubic-bezier(.09,.72,.76,1.01);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 24px;
        opacity: 0;

        + span {
            margin-left: 0;
        }
    }

    @include mouse-only() {
        &:hover {
            padding: rem(11px) rem(35px) rem(11px) rem(12px);

            [class*='icon-'] {
                opacity: 1;
                transform: translate(10px,-50%);
            }
        }
    }

    &.disabled {
        @include mouse-only() {
            &:hover {
                padding: rem(11px) rem(24px);

                [class*='icon-'] {
                    display: none;
                }
            }
        }
    }
}

// background blue, text white
.btn-primary {
    @extend %btn;
    @extend %btnArrow;

    background-color: $buttonPrimaryBg;
    color: $buttonPrimaryTxt;
    border: $buttonPrimaryBorder;

    &:active {
        background-color: $buttonPrimaryBgHover;
        border-color: $buttonPrimaryBgHover;
    }

    @include mouse-only() {
        &:hover {
            background-color: $buttonPrimaryBgHover;
            border-color: $buttonPrimaryBgHover;
        }
    }
    
    &.disabled {
        background-color: $buttonPrimaryBgDisabled;
        border-color: $buttonPrimaryBgDisabled;
    }
}

// background white, text blue
.btn-primary-white {
    @extend %btn;
    @extend %btnArrow;

    background-color: $buttonPrimaryBgAlt;
    color: $buttonPrimaryTxtAlt;
    border: $buttonPrimaryBorderAlt;

    &:active {
        background-color: $buttonPrimaryBgAltHover;
        border-color: $buttonPrimaryBgAltHover;
    }

    @include mouse-only() {
        &:hover {
            background-color: $buttonPrimaryBgAltHover;
            border-color: $buttonPrimaryBgAltHover;
        }
    }

    em {
        font-size: rem(14px);
    }

    .panel-blue & {
        [class*='icon-'] {
            color: $blue;
        }
    }
}

// border white, text white
.btn-secondary {
    @extend %btn;
    @extend %btnArrow;

    background-color: $buttonSecondaryBg;
    border: $buttonSecondaryBorder;
    color: $buttonSecondaryTxt;

    @include mouse-only() {
        &:not(.btn-link) {
            &:hover {
                background-color: $buttonSecondaryBgHover;
            }
        }
    }
    
}

// border blue, text blue
.btn-secondary-blue {
    @extend %btn;
    @extend %btnArrow;

    //padding: 10px 12px;
    //background-color: $buttonSecondaryBgAlt;
    border: $buttonSecondaryBorderAlt;
    color: $buttonSecondaryTxtAlt;

    @include mouse-only() {
        &:not(.btn-link) {
            &:hover {
                background-color: $buttonSecondaryBgAltHover;
            }
        }
    }
}

// simple text link with arrow
.btn-text-link {
    @include  t-text-link();
    
    pointer-events: auto;
    cursor: pointer;

    [class*='icon-'] {
        margin-left: 10px;
    }

    .icon-arrow_right {
        font-size: rem(12px);
    }

    .icon-email {
        font-size: rem(11px);
        margin-right: 10px;
    }

    /*.icon-external-link {
        transform: translateY(-2px);
    }*/
}

.btn-big {
    @extend %btn;
    @include karbonMedium();

    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 58px;
    //padding: 20px 16px;
    padding: 0 rem(16px);
    border-radius: 10px;
    border: 1px solid $blue;
    color: $blue;
    background-color: transparent;
    font-size: rem(16px);
    line-height: rem(18px);
    letter-spacing: 0;
    text-transform: none;
    text-align: left;
    transition: box-shadow .3s ease-out,
                border .3s ease-out,
                background .3s cubic-bezier(.09,.72,.76,1.01);

    @include respond-above(md) {
        width: 270px;
        font-size: rem(22px);
        line-height: rem(24px);
    }

    &.active {
        background-color: $blue;
        color: $white;
    }

    @include mouse-only() {
        &:hover:not(.active) {
            border-color: transparent;
            box-shadow: $buttonBigShadow;
        }
    }
}


//
//  COMPONENT COLORS
//––––––––––––––––––––––––––––––––––––––––––––––––––
.panel-blue {
    background-color: $blue;
    color: $white;

    [class*='icon-'] {
        color: $white;
    }
    
    hr {
        border-color: $white;
        opacity: 0.2;
    }
}

.panel-white {
    background-color: $white;
    color: $black;
}

.panel-grey {
    background-color: $grayWhite;
    color: $black;
}

.panel-light-grey {
    background-color: $lightColdGray;
    color: $black;
}

.panel-nexi-black {
    background-color: $nexiBlack;
    color: $white;
}
.panel-gold-prestige {
    background-color: $goldPrestige;
    color: $black;
}
.panel-dark-silver {
    background-color: $darkSilver;
    color: $black;
}

.text-black {
    color: $black !important;

    [class*='icon-']:before {
        color: $black;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

.text-gray {
    color: $darkGray !important;

    [class*='icon-']:before {
        color: $darkGray !important;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

.text-white {
    color: $white !important;

    [class*='icon-']:before {
        color: $white !important;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

.text-blue {
    color: $blue !important;

    [class*='icon-']:before {
        color: $blue !important;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

.text-lightBlue {
    color: $azure !important;

    [class*='icon-']:before {
        color: $azure !important;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

.text-lightGray {
    color: $gray !important;

    [class*='icon-']:before {
        color: $gray !important;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

.text-red {
    color: $red !important;

    [class*='icon-']:before {
        color: $red !important;
    }

    [class*='btn-primary'] .icon-arrow_right:before,
    [class*='btn-secondary'] .icon-arrow_right:before {
        color: inherit !important;
    }
}

//
//  HORIZONTAL ALIGNMENT MOBILE
//––––––––––––––––––––––––––––––––––––––––––––––––––
@include respond-below(lg) {
    .align-h-left-mob {
        text-align: left;
    }

    .align-h-center-mob {
        text-align: center;
    }

    .align-h-right-mob {
        text-align: right;
    }
}

//
//  HORIZONTAL ALIGNMENT DESKTOP
//––––––––––––––––––––––––––––––––––––––––––––––––––
@include respond-above(lg) {
    .align-h-left {
        text-align: left;
    }

    .align-h-center {
        text-align: center;
    }

    .align-h-right {
        text-align: right;
    }
}

//
//  PERFECT SCROLLBAR
//––––––––––––––––––––––––––––––––––––––––––––––––––
.ps {

    .ps__thumb-y {
        transition: background-color 0.2s linear,
                    width 0.2s ease-in-out,
                    right 0.2s ease-in-out;
    }

    .ps__rail-y {
        left: auto !important;
        right: 0;
        opacity: 0.5;

        &:hover,
        &:focus,
        &.ps--clicking {
            background: none !important;
        }
    }
}

//
//  GOOGLE RECAPTCHA
//––––––––––––––––––––––––––––––––––––––––––––––––––
.g-recaptcha,
.grecaptcha-badge {
    visibility: hidden;
}
//––––––––––––––––––––––––––––––––––––––––––––––––––

//
//  AEM AUTHORING
//––––––––––––––––––––––––––––––––––––––––––––––––––
$edit_mode: 'html[class*="aem-AuthorLayer-"]:not(.aem-Author--hidePlaceholder)';
$wcmmode: 'html[class*="aem-AuthorLayer-"]';
$edit-fragment: ".aem-AuthorLayer-Experience-Fragment";
//––––––––––––––––––––––––––––––––––––––––––––––––––

//
// ACCESSIWAY
//––––––––––––––––––––––––––––––––––––––––––––––––––
.acsb-trigger,
::part(acsb-trigger) {
    box-shadow: 0 0 15px 0 rgb(255 255 255 / 45%);

    @include respond-below(lg) {
        left: 25px !important;
        //bottom: 33px !important;
        right: auto !important;
        z-index: 4899 !important;

        .sticky-menu--mobile-enabled & {
            //bottom: 107px !important;
            bottom: 154px !important;
        }

        .sticky-bar-dob-enabled & {
            bottom: 150px !important;
        }

    }
}

.acsb-widget.acsb-active .acsb-main .acsb-search form.acsb-form input[name=acsb_search] {
    background-color: rgba(255,255,255,.3) !important;
}

.acsb-widget.acsb-active .acsb-main .acsb-search form.acsb-form input[name=acsb_search]:focus {
    background-color: white !important;
}
//––––––––––––––––––––––––––––––––––––––––––––––––––