.base-main {
    flex: 0 0 auto;

    .base-header ~ &,
    .base-header-lite ~ & {
        padding-top: 72px;
    }

    .no-sticky.base-header ~ &,
    .no-sticky.base-header-lite ~ &
    {
        padding-top: 184px;
        transition: padding-top .5s ease;
    }

    .no-sticky.base-header.scrolled-hidden ~ &, 
    .no-sticky.base-header-lite.scrolled-hidden ~ & {
        padding-top: 110px;
    }

    .with-stickybar.base-header ~ &, 
    .with-stickybar.base-header-lite ~ & {
        padding-top: 184px;
        transition: padding-top .5s ease;
    }

    .with-stickybar.base-header.scrolled-hidden ~ &, 
    .with-stickybar.base-header-lite.scrolled-hidden ~ & {
        padding-top: 112px;
    }

    .base-header-lp-dob ~ & {
        padding-top: 148px;
    }

    @include respond-above(lg) {

        .with-stickybar.base-header ~ &, 
        .with-stickybar.base-header-lite ~ & {
            padding-top: 144px; } 

        .no-sticky.base-header ~ &,
        .no-sticky.base-header-lite ~ &
        {
            padding-top: 144px;
        }

        .base-header-lp-dob ~ & {
            padding-top: 72px;
        }

    }

    @include respond-below(lg) {
        .sticky-bar-dob-enabled .lp-dob & {
            padding-bottom: $sticky-dob-bottom-height-mobile;
        }
    }

}