.widget-round-images {

    &__container {
        @include container();
        @include content-container();

        &.swiper-initialized {
            @include respond-below(lg) {
                padding-right: 15% !important;
            }
    
            @include respond-below(md) {
                padding-right: 22% !important;
            }
        }
    }

    &__slides {
        justify-content: center;

        .swiper-initialized & {
            justify-content: flex-start;
        }
    }

    &__slide {
        text-align: center;
        overflow: visible;
        padding-bottom: 10px;
        height: auto;
        width: auto;
        flex: 1 0 auto;
        max-width: 50%;

        @include respond-above(md) {
            max-width: 33.33333333%;
        }

        @include respond-above(lg) {
            display: inline-flex;
            max-width: 240px;

            &:last-child {
               flex-grow: 0;

                @include ie-only() {
                    max-width: 179px;
                }
            }

            @include ie-only() {
                max-width: 210px;
                padding-right: 30px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    &__slide-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    &__overlay-link {

        @include mouse-only() {
            &.hover-behaviour:hover {
                .widget-round-images__image-container > img {
                    transform: scale(1.1);
                }

                .widget-round-images__arrow {
                    transform: translateY(10px);
                }
            }
        }
    }

    &__image-container {
        width: 127px;
        height: 127px;
        margin-bottom: rem(16px);
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        will-change: transform; // hack for safari
        
        @include respond-above(lg) {
            width: 179px;
            height: 179px;
            margin-bottom: rem(20px);
        }

        img {
            transition: transform 1.5s;
            height: 100%;
            object-fit: cover;
        }
    }

    &__label {
        @include karbonMedium();
        @include t-paragraph-1();
        margin-bottom: rem(20px);
        max-width: 127px;

        @include respond-above(lg) {
            max-width: 168px;
            display: inline-flex;
        }
    }

    &__arrow-container {
        display: flex;
        justify-content: center;
        margin-top: auto;
    }

    &__arrow {
        text-align: center;
        background-color: rgba(45, 50, 170, 0.08);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue;
        font-size: rem(23px);
        transition: transform 1.5s;
    }
    
    @include respond-below(lg) {
        .swiper-initialized {
            .swiper-slide:not(.swiper-slide-visible) {
                opacity: 0.5;
            }
        }
    }
}
