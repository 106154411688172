/* PADDINGS */

@each $name, $size in $padding {
    /* padding-top */
    .p-t-#{$name} {
        padding-top: #{$size} !important;
    }

    /* padding-bottom */
    .p-b-#{$name} {
        padding-bottom: #{$size} !important;
    }

    /* vertical paddings */
    .p-#{$v}-#{$name} {
        padding-top: #{$size} !important;
        padding-bottom: #{$size} !important;
    }
}

@include respond-above(lg) {

    @each $name, $size in $padding-desktop {
        /* padding-top */
        .p-t-#{$name} {
            padding-top: #{$size} !important;
        }
    
        /* padding-bottom */
        .p-b-#{$name} {
            padding-bottom: #{$size} !important;
        }
    
        /* vertical paddings */
        .p-#{$v}-#{$name} {
            padding-top: #{$size} !important;
            padding-bottom: #{$size} !important;
        }
    }

}


