.widget-video {
    padding-top: rem(32px);

    @include respond-above(lg) {
        padding-top: rem(81px);
        padding-bottom: rem(70px);
    }

    &__container {
        @include container();
        @include content-container();
    }

    &__content {
        @include make-row();
    }

    &__column {
        @include make-col-ready();
        @include make-col(12);

        @include respond-above(lg) {
            @include make-col(5);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__offset {
        @include make-col(1);

        @include respond-below(lg) {
            display: none;
        }
    }

    &__title {
        padding-bottom: rem(18px);

        @include respond-above(lg) {
            padding-bottom: rem(39px);
        }
    }

    &__video-container {
        padding-bottom: rem(32px);

        @include respond-above(lg) {
            padding-bottom: rem(56px);
        }
    }
}