.elem-attachment {
    $this-attachment: &;

    //padding-top: rem(32px);
    //padding-bottom: rem(32px);

    //@include respond-above(lg) {
        //padding-top: rem(64px);
        //padding-bottom: rem(64px);
    //}

        // @include respond-above(lg) {
    //     padding-bottom: rem(120px);

    //     .layout-panel & {
    //         padding-bottom: rem(32px);
    //     }
    // }

    &__container {
        @include content-container();

        .layout-panel & {
            padding: 0 !important;
            max-width: none;
        }
    }

    &__heading {
        margin-bottom: rem(20px);
        width: 100%;
        display: flex;

        @include respond-above(lg) {
            margin-bottom: rem(24px);
            justify-content: space-between;
            align-items: center;
        }

        + #{$this-attachment}__content {
            margin-top: rem(32px);

            @include respond-above(lg) {
                margin-top: rem(48px);
            }
        }
    }

    &__description {
        margin-bottom: rem(30px);

        @include respond-above(lg) {
            margin-bottom: rem(48px);
        }
    }

    &__footer {
        padding-top: 24px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        align-items: center;

        @include respond-below(lg) {
            display: none;
        }
    }

    &__cta {
        @include respond-below(lg) {
            display: none;
        }
    }

    &__content {
        padding: rem(24px) 0;
        border-top: 1px solid $lightGray;
        
        @include respond-above(lg) {
            padding: rem(20px) 0;
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // height: 100%;
        align-items: center;

        em {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: rem(32px);
            // color: $darkGray;
        }

        .elem-icon-anim {
            color: $darkGray;
        }

        // .icon-download {
        //     color: $black;
        // }

        @include mouse-only() {
            &:focus,
            &:hover {
                p {
                    color: $blue;

                    .panel-blue & {
                        color: $white;
                    }
                }

                .elem-attachment__file-title {

                    .panel-blue & {
                        text-decoration: underline;
                    }
                }

                .elem-attachment__file-date {
                   color: $darkGray;
                }

                .icon-download {
                    background-color: rgba(0,0,0,0.05);
                    border-radius: 50%;

                    .panel-blue & {
                        background-color: $black;
                        opacity: 0.5;
                        border-radius: 50%;
                    }
                }
            }
        }
    }


    &__content-text {
        @include cut-words;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: rem(16px);
        margin-right: auto;
        padding-right: rem(16px);
        justify-content: center;
    }
    
    &__download {
        width: 24px;
        height: 24px;
        color: $black;

        @include respond-above(lg) {
            width: 48px;
            height: 48px;
        }
    }

    &__file-date {
        @include karbonRegular();
        font-size: rem(14px);
        color: $darkGray;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 18px;
        text-decoration: none;

        .panel-blue & {
            color: $white;
        }
    } 

    &__file-title {
        @include karbonRegular();

        font-size: rem(16px);
        line-height: 22px;
        color: $black;
        display: flex;

        @include respond-above(lg) {
            font-size: rem(18px);
            letter-spacing: -0.5px;
            line-height: 24px;
        }

        .panel-blue &{
            color: $white;
        }
    }
}

.elem-attachment_ctaBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 80px;

    @include respond-above(lg) {
        display: none;
    }
}