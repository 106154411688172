%visible {
    display: block;
    opacity: 1;
    max-height: 100000px;
    transition: max-height 600ms ease-out, opacity 500ms ease-out;
}

%caretUp {
    &::before {
        content: $icon-caret_up;
        transform: rotate(180deg);
    }
}

.elem-accordion {
    $this: &;

    &__container {
        @include container();
        @include content-container();
    }

    .layout-panel &__container {
        padding: 0 0 !important;
        max-width: none;
    }

    &__row {
        @include make-row();
    }

    &__item {
        @include make-col-ready();
        @include make-col(12);
    }

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #C0C0C0;
        padding-top: rem(20px);
        padding-bottom: rem(24px);
        padding-right: rem(20px);
        cursor: pointer;

        @include respond-above(lg) {
            padding-bottom: rem(32px);
        }
    }

    &__header-checkbox {

        &:checked ~ #{$this}__body {
            @extend %visible;
        }

        &:checked + #{$this}__header #{$this}__icon {
            @extend %caretUp;
        }
    }

    &__title {
        @include karbonMedium();
        font-size: rem(18px);
        line-height: rem(24px);
        letter-spacing: rem(-0.5px);

        @include respond-above(lg) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__icon {
        position: absolute;
        right: 0;
        color: $blue;
        font-size: rem(20px);

        #{$edit_mode} & {
            @extend %caretUp;
        }
    }

    &__body {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        transition: max-height 300ms ease-out, opacity 400ms ease-out;

        .elem-text {
            width: 100%;
            max-width: 88%;
            padding-bottom: rem(10px);
            padding-top: rem(8px);

            ul {
                margin-bottom: rem(24px);
            }

            @include respond-above(lg) {
                padding-top: 0;
                max-width: 91%;
                //margin-top: rem(-8px);
                padding-bottom: rem(7px);
            }
        }

        .elem-image {
            padding-bottom: rem(42px);
        }

        #{$edit_mode} & {
            @extend %visible;
        }
    }

    input[type=checkbox] {
        display: none;
        position: absolute;
    }
}